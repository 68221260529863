// MyComponent.js
// import './FilmsForm.css'

import React, { useState, useEffect } from 'react';
import { images,captures } from '../../constants';
import { SubHeading, FormDisplayItem, Searchbar, Eventbox } from '../../components';
import {updateLibrary,fetchLibraryById} from '../../services/library'; // Import the specific API function
import axios from 'axios';

import {useNavigate} from 'react-router-dom';

// const navigate = useNavigate();


function LibrarysForm({onClose, id }) {
    const navigate = useNavigate();
    const [picture, setPicture] = useState([]);
    

    const [pictureImages, setPictureImages] = useState([]);

    
  const [url, setUrl] = useState(null);
  const [heading, setHeading] = useState(picture.heading || "");
  const [subheading, setSubheading] =  useState(picture.subheading || "");
  const [type, setType] = useState(picture.type || "");

  const [image, setImage] = useState("")
  const [imageTitle, setImageTitle] = useState("")
  


    console.log(id)


   // Handle file input change
   const handleFileChange = (e) => {
    setUrl(e.target.files[0]);
  };

     // Handle sub images
     const handleImageFileChange = (e) => {
      setImage(e.target.files[0]);
    };


 // Handle form submission
 const updateData = async (e) => {
  e.preventDefault();

  // Create a FormData object to send the form data
  const formData = new FormData();
//   console.log(url)
if (url && url instanceof File) {
  // Generate a unique filename using a timestamp and the original file's extension
  const fileExtension = url.name.split('.').pop();
  const uniqueFilename = `${Date.now()}.${fileExtension}`;

  // Create a new File object with the unique filename
  const renamedFile = new File([url], uniqueFilename, {
    type: url.type,
  });

  // Append the renamed file to the formData
  formData.append('url', renamedFile);
}

  formData.append('heading', heading);
  formData.append('subheading', subheading);
  formData.append('type', type);

  console.log(formData);

  try {
    const responseData = await updateLibrary(formData,id); // Call the submitFormData function
    console.log('Response:', responseData);
    // Handle success, e.g., display a success message or redirect
    alert('Success!');
  } catch (error) {
    console.error('Error:', error);
    // Handle error, e.g., display an error message
  }
};


const addImages = async (e) => {
  e.preventDefault();

  // Create a FormData object to send the form data
  const imageData = new FormData();
      imageData.append('picture_id', id);
      imageData.append('image', image);
      imageData.append('title', imageTitle );

  console.log(imageData);

  try {
    const responseData = await addImages(imageData); // Call the submitFormData function
    console.log('Response:', responseData);
    // Handle success, e.g., display a success message or redirect
  } catch (error) {
    console.error('Error:', error);
    // Handle error, e.g., display an error message
  }
};
      

    


  useEffect(() => {
    async function fetchData() {
      try {
        const data = await fetchLibraryById(id);
        setPicture(data);
        setUrl(data.url);
        setHeading(data.heading || "");
        setSubheading(data.subheading || "");

        console.log(data)
      } catch (error) {
        // Handle error
      }


    }

    fetchData();
  }, []);



  return (

    <div className='captures-form'>
      
      <div className='captures-form__add'>

      <p class="headtext__cormorant2">Update {picture.name}</p>
      <br/>

      
      {/* <h2 class="mb-4 text-2xl font-bold leading-none tracking-tight text-gray-900 md:text-4xl">Update</h2> */}

      <form class="w-full max-w-sm">
      <div class="md:flex md:items-center mb-6">
        <div class="md:w-1/3">
          <label class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4" for="inline-full-name">
            Upload Image
          </label>
        </div>
          <div class="md:w-2/3">
            <img width="300px" height="300px" src={picture.url}/>
            <input
            class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" id="inline-full-name"
            type="file"
            name="display_img"
            accept="image/*"
            onChange={handleFileChange}
            />
          </div>
        </div>


        <div class="md:flex md:items-center mb-6">
          <div class="md:w-1/3">
            <label class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4" for="inline-full-name">
            Heading
            </label>
          </div>
            <div class="md:w-2/3">
              <input
              class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" id="inline-full-name"
              type="text"
              name="photographer_name"
              value={heading}
              onChange={(e)=>setHeading(e.target.value)}
              />
            </div>
        </div>


        <div class="md:flex md:items-center mb-6">
        <div class="md:w-1/3">
          <label class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4" for="inline-full-name">
          Subheading
          </label>
        </div>
          <div class="md:w-2/3">
            <textarea
            class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" id="inline-full-name"
            type="text"
            name="subheading"
            value={subheading}
            onChange={(e)=>setSubheading(e.target.value)}
            />
          </div>
        </div>

        <div class="md:flex md:items-center mb-6">
    <div class="md:w-1/3">
      <label class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4" for="media-type">
        Media Type
      </label>
    </div>
    <div class="md:w-2/3">
      <select
        class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
        id="media-type"
        name="media_type"
        value={type}
        onChange={(e) => setType(e.target.value)}
      >
        <option value="image" defaultValue>Image</option>
        <option value="video">Video</option>
      </select>
    </div>
  </div>


        <div class="md:flex md:items-center">
          <div class="md:w-1/3"></div>
          <div class="md:w-2/3">
            <button class="shadow bg-orange-400 hover:bg-orange-500 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded" 
            type="submit"
            // onClick={() => { onClose(); updateData(); }}
            onClick={updateData}
            >
            Submit
            </button>
          </div>
        </div>
        
      </form>

      </div>

      
    </div>
  );
}

export default LibrarysForm;
