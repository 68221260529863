import React, { useState, useEffect } from 'react';

import { useLocation } from 'react-router-dom';
import { SubpageHeaderImage,Eventbox,Searchbar } from '../../components';
import { FaInstagram, RxInstagramLogo } from 'react-icons/fa';

import { images, happenings } from '../../constants';
import './HappeningsDetails.css';
import { Link } from 'react-router-dom';
import { fetchHappeningsById} from '../../services/happenings';

 const HappeningsDetails = () => {

  const location = useLocation();
  const { contentType, id } = location.state;

  const [radios, setRadios] = useState([]);
  const [videos, setVideos] = useState([]);




  useEffect(() => {

    async function fetchData() {
      try {
        const data = await fetchHappeningsById(id);
        // setArchives(data);
        console.log(data)
        setRadios(data)
      } catch (error) {
        // Handle error
      }
    }



    fetchData();
    // fetchVideoData();
  }, []);


  
  
  
  return (
  <div className="">
     {/* <SubpageHeaderImage/> */}
    <div className='app__happeningsdetails'>
   
    
       
        <Eventbox
          key={radios.id}
          display_img={radios.display_img}
          name={radios.name}
          description={radios.description}
          // header_img_url={v.header_img_url}
          website_url={radios.booking_url}
          tagline = {radios.subheading}
          details_page_link={radios.details_page_link}
        />
    

    </div>
   

  </div>
);
  }

export default HappeningsDetails;