import axios from 'axios';

const API_BASE_URL = 'https://api.thecockneysikh.com/happenings';


// GET all portrait photographers
export const fetchHappenings = async () => {
  try {
    const response = await axios.get('https://api.thecockneysikh.com/happenings/');
    return response.data;
  } catch (error) {
    throw error;
  }
};


// GET one portrait photographer by id
export const deleteHappeningsById = async (id) => {
  try {
      const response = await axios.delete(`https://api.thecockneysikh.com/happenings/${id}/`);
      return response.data;
  } catch (error) {
      throw error;
  }
};

// GET one portrait photographer by id
export const fetchHappeningsById = async (id) => {
  try {
      const response = await axios.get(`https://api.thecockneysikh.com/happenings/${id}/`);
      return response.data;
  } catch (error) {
      throw error;
  }
};

// POST create new radio
export const saveHappenings  = async (formData) => {
  try {
    const response = await axios.post(
      'https://api.thecockneysikh.com/happenings/',
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

  // PUT update existing radio
  export const updateHappenings  = async (formData,id ) => {
    console.log(id)
      try {
        const response = await axios.put(
          `https://api.thecockneysikh.com/happenings/${id}/`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    };
