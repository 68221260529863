import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { SubpageHeader, Listbox, Videobox, Searchbar } from '../../components';
import { images,captures } from '../../constants';
import './FilmsDetails.css';
import { Link } from 'react-router-dom';
import { fetchFilmById,fetchFilmVideos} from '../../services/films';



const FilmsDetails = () => {

  const location = useLocation();
  const { contentType, id } = location.state;

  const [radios, setRadios] = useState([]);
  const [videos, setVideos] = useState([]);




  useEffect(() => {

    async function fetchData() {
      try {
        const data = await fetchFilmById(id);
        // setArchives(data);
        console.log(data)
        setRadios(data)
      } catch (error) {
        // Handle error
      }
    }

    async function fetchVideoData() {
      try {
        const data = await fetchFilmVideos(id);
        setVideos(data);
        console.log(data)
      } catch (error) {
        // Handle error
      }


    }
    

    fetchData();
    fetchVideoData();
  }, []);

  // Use the useLocation() hook inside the component body

  // const { photographer_name } = location.state;
  // console.log(contentType);
  // console.log(name);
  // console.log(contentType.name)


  
  // Define content based on the linkName
  let content = null;

   {
    
    // const filteredVideos = radios.filter((video) =>
    // video.name.toLowerCase().includes(id.toLowerCase())
    // )
    // console.log(filteredVideos)
    content = <div>
    
      <SubpageHeader
        key={radios.id}
        name={radios.name}
        description={radios.description}
        insta_url={radios.instagram_url}
        website_url={radios.website_url}
        
       
      />
      
  
    
      <div className='app__capturesdetails'>
      {/* <Searchbar/> */}
      {videos && videos.length > 0 && videos.reverse().map((video, index) => (
        // console.log(video);
          <div>
       
          <Videobox
          key={video.id}
          display_video={video.url}
          name={video.name}
          description={video.description}   
          contentType={'video'}                     
        />
           
      </div>  
          
      
          ))}
    
    
    </div>
  </div>



  }


  return (
    <div className="">
      {content}
    </div>
  );
};



export default FilmsDetails;