// MyComponent.js
import './HappeningsForm.css'

import React, { useState, useEffect } from 'react';
import { images,captures } from '../../constants';
import { SubHeading, FormDisplayItem, Searchbar, Eventbox } from '../../components';
import {updateHappenings, fetchHappeningsById} from '../../services/happenings'; // Import the specific API function
import axios from 'axios';

import {useNavigate} from 'react-router-dom';

// const navigate = useNavigate();


function HappeningsForm({onClose, id }) {
    const navigate = useNavigate();
    const [picture, setPicture] = useState([]);
    

    const [pictureImages, setPictureImages] = useState([]);

    
//   const [display_img, setDisplayImg] = useState(null);
//   const [name, setName] = useState(picture.name || "");
//   const [description, setDescription] =  useState(picture.description || "");
//   const [instagram_url, setInstagramUrl] = useState(picture.instagram_url || "");
//   const [website_url, setWebsiteUrl] = useState(picture.website_url || "");

  const [display_img, setDisplayImg] = useState(null);
  const [name, setName] = useState(picture.name || "")
  const [subheading, setSubheading] = useState(picture.subheading || "")
  const [description, setDescription] = useState(picture.description || "")
  const [booking_url, setBookingUrl] = useState(picture.booking_url || "")
  

  const [image, setImage] = useState("")
  const [imageTitle, setImageTitle] = useState("")


    console.log(id)


   // Handle file input change
   const handleFileChange = (e) => {
    setDisplayImg(e.target.files[0]);
  };

     // Handle sub images
     const handleImageFileChange = (e) => {
      setImage(e.target.files[0]);
    };


 // Handle form submission
 const updateData = async (e) => {
  e.preventDefault();

  // Create a FormData object to send the form data
  const formData = new FormData();
  console.log(display_img)
  if (display_img && display_img instanceof File) {
    // Generate a unique filename using a timestamp and the original file's extension
    const fileExtension = display_img.name.split('.').pop();
    const uniqueFilename = `${Date.now()}.${fileExtension}`;

    // Create a new File object with the unique filename
    const renamedFile = new File([display_img], uniqueFilename, {
      type: display_img.type,
    });

    // Append the renamed file to the formData
    formData.append('display_img', renamedFile);
  }


  formData.append('name', name);
  formData.append('description', description);
  formData.append('subheading', subheading);
  formData.append('booking_url', booking_url);

  console.log(formData);

  try {
    const responseData = await updateHappenings(formData,id); // Call the submitFormData function
    console.log('Response:', responseData);
    // Handle success, e.g., display a success message or redirect
    alert('Success!');
  } catch (error) {
    console.error('Error:', error);
    // Handle error, e.g., display an error message
  }
};


  useEffect(() => {
    async function fetchData() {
      try {
        const data = await fetchHappeningsById(id);
        setPicture(data);
        setDisplayImg(data.display_img);
        setName(data.name || "");
        setDescription(data.description || "");
        setSubheading(data.subheading || "");
        setBookingUrl(data.booking_url || "");
        console.log(data)
      } catch (error) {
        // Handle error
      }


    }

    fetchData();
  }, []);



  return (

    <div className='captures-form'>
      
      <div className='captures-form__add'>

      <p class="headtext__cormorant2">Update {picture.name}</p>
      <br/>

      
      {/* <h2 class="mb-4 text-2xl font-bold leading-none tracking-tight text-gray-900 md:text-4xl">Update</h2> */}

      <form class="w-full max-w-sm">
      <div class="md:flex md:items-center mb-6">
        <div class="md:w-1/3">
          <label class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4" for="inline-full-name">
            Display Image
          </label>
        </div>
          <div class="md:w-2/3">
            <img width="300px" height="300px" src={picture.display_img}/>
            <input
            class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" id="inline-full-name"
            type="file"
            name="display_img"
            accept="image/*"
            onChange={handleFileChange}
            />
          </div>
        </div>


        <div class="md:flex md:items-center mb-6">
          <div class="md:w-1/3">
            <label class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4" for="inline-full-name">
            Name:
            </label>
          </div>
            <div class="md:w-2/3">
              <input
              class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" id="inline-full-name"
              type="text"
              name="name"
              value={name}
              onChange={(e)=>setName(e.target.value)}
              />
            </div>
        </div>


        <div class="md:flex md:items-center mb-6">
        <div class="md:w-1/3">
          <label class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4" for="inline-full-name">
          Subheading:
          </label>
        </div>
          <div class="md:w-2/3">
            <input
            class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" 
            type="text"
            name="instagram_url"
            value={subheading}
            onChange={(e)=>setSubheading(e.target.value)}
            />
          </div>
        </div>


        <div class="md:flex md:items-center mb-6">
        <div class="md:w-1/3">
          <label class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4" for="inline-full-name">
          Description:
          </label>
        </div>
          <div class="md:w-2/3">
            <textarea
            class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" id="inline-full-name"
            type="text"
            name="description"
            value={description}
            onChange={(e)=>setDescription(e.target.value)}
            />
          </div>
        </div>

        
 

        <div class="md:flex md:items-center mb-6">
        <div class="md:w-1/3">
          <label class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4" for="inline-full-name">
          Booking Url:
          </label>
        </div>
          <div class="md:w-2/3">
            <input
            class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" 
            type="text"
            name="booking_url"
            value={booking_url}
            onChange={(e)=>setBookingUrl(e.target.value)}
            />
          </div>
        </div>

        <div class="md:flex md:items-center">
          <div class="md:w-1/3"></div>
          <div class="md:w-2/3">
            <button class="shadow bg-orange-400 hover:bg-orange-500 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded" 
            type="submit"
            // onClick={() => { onClose(); updateData(); }}
            onClick={updateData}
            >
            Submit
            </button>
          </div>
        </div>
        
      </form>

      </div>

      
    </div>
  );
}

export default HappeningsForm;
