import axios from 'axios';

const API_BASE_URL = 'https://api.thecockneysikh.com/attire';


// GET all portrait photographers
export const fetchAttire = async () => {
  try {
    const response = await axios.get('https://api.thecockneysikh.com/attire/');
    return response.data;
  } catch (error) {
    throw error;
  }
};


// GET one portrait photographer by id
export const deleteAttireById = async (id) => {
  try {
      const response = await axios.delete(`https://api.thecockneysikh.com/attire/${id}/`);
      return response.data;
  } catch (error) {
      throw error;
  }
};

// GET one portrait photographer by id
export const fetchAttireById = async (id) => {
  try {
      const response = await axios.get(`https://api.thecockneysikh.com/attire/1/`);
      return response.data;
  } catch (error) {
      throw error;
  }
};

// POST create new radio
export const saveAttire  = async (formData) => {
  try {
    const response = await axios.post(
      'https://api.thecockneysikh.com/attire/',
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

  // PUT update existing radio
  export const updateAttire  = async (formData,id ) => {
    console.log(id)
      try {
        const response = await axios.put(
          `https://api.thecockneysikh.com/attire/1/`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    };




///////////////////// media
// GET all portrait photographers
export const fetchAttireMedia = async () => {
    try {
      const response = await axios.get('https://api.thecockneysikh.com/attire/media');
      return response.data;
    } catch (error) {
      throw error;
    }
  };
  
  
  // GET one portrait photographer by id
  export const deleteAttireMediaById = async (id) => {
    try {
        const response = await axios.delete(`https://api.thecockneysikh.com/attire/media/${id}/`);
        return response.data;
    } catch (error) {
        throw error;
    }
  };
  
  // GET one portrait photographer by id
  export const fetchAttireMediaById = async (id) => {
    try {
        const response = await axios.get(`https://api.thecockneysikh.com/attire/media/${id}/`);
        return response.data;
    } catch (error) {
        throw error;
    }
  };
  
  // POST create new radio
  export const saveAttireMedia  = async (formData) => {
    try {
      const response = await axios.post(
        'https://api.thecockneysikh.com/attire/media',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };
  
    // PUT update existing radio
    export const updateAttireMedia  = async (formData,id ) => {
      console.log(id)
        try {
          const response = await axios.put(
            `https://api.thecockneysikh.com/attire/media/${id}/`,
            formData,
            {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            }
          );
          return response.data;
        } catch (error) {
          throw error;
        }
      };
  
