// MyComponent.js
import './RadioForm.css'

import React, { useState, useEffect } from 'react';
import { images,captures } from '../../constants';
import { SubHeading, RadioAudiosFormItem, Searchbar, Eventbox } from '../../components';
import {updateRadio, saveRadioAudios,fetchRadioAudios,fetchRadioById} from '../../services/radios'; // Import the specific API function
import axios from 'axios';

import {useNavigate} from 'react-router-dom';

// const navigate = useNavigate();


function RadiosForm({onClose, id }) {
    const navigate = useNavigate();
    const [picture, setPicture] = useState([]);
    

    const [pictureImages, setPictureImages] = useState([]);

    
  const [display_img, setDisplayImg] = useState(null);
  const [name, setName] = useState(picture.name || "");
  const [description, setDescription] =  useState(picture.description || "");
  const [instagram_url, setInstagramUrl] = useState(picture.instagram_url || "");
  const [website_url, setWebsiteUrl] = useState(picture.website_url || "");

  
  const [imageTitle, setImageTitle] = useState("")


  // subform

  const [subtable, setSubtable] = useState([]);
  
  const [image, setImage] = useState("")
  const [url, setUrl] = useState(null);
  const [subname, setSubname] = useState("");
  const [subdescription, setSubdescription] =  useState("");
  const [subheading, setSubheading] = useState("");
    


   // Handle file input change
   const handleFileChange = (e) => {
    setDisplayImg(e.target.files[0]);
  };

     // Handle sub images
     const handleAudioFileChange = (e) => {
        setUrl(e.target.files[0]);
      };
  
       // Handle sub images
       const handleImageFileChange = (e) => {
        setImage(e.target.files[0]);
      };
  

 // Handle form submission
 const updateData = async (e) => {
  e.preventDefault();

  // Create a FormData object to send the form data
  const formData = new FormData();
  // console.log(display_img)
  if (display_img && display_img instanceof File) {
    // Generate a unique filename using a timestamp and the original file's extension
    const fileExtension = display_img.name.split('.').pop();
    const uniqueFilename = `${Date.now()}.${fileExtension}`;
  
    // Create a new File object with the unique filename
    const renamedFile = new File([display_img], uniqueFilename, {
      type: display_img.type,
    });
  
    // Append the renamed file to the formData
    formData.append('display_img', renamedFile);
  }



  formData.append('name', name);
  formData.append('description', description);
  formData.append('instagram_url', instagram_url);
  formData.append('website_url', website_url);

  console.log(formData);

  try {
    const responseData = await updateRadio(formData,id); // Call the submitFormData function
    console.log('Response:', responseData);
    // Handle success, e.g., display a success message or redirect
    alert('Success!');
    
  } catch (error) {
    console.error('Error:', error);
    // Handle error, e.g., display an error message
  }
};

const addAudios = async (e) => {
    e.preventDefault();
  
    // Create a FormData object to send the form data
    const formData = new FormData();
    // console.log("this is url",url)
    // console.log(image)
    if (url && url instanceof File) {
      // Generate a unique filename using a timestamp and the original file's extension
      const fileExtension = url.name.split('.').pop();
      const uniqueFilename = `${Date.now()}.${fileExtension}`;
    
      // Create a new File object with the unique filename
      const renamedFile = new File([url], uniqueFilename, {
        type: url.type,
      });
    
      // Append the renamed file to the formData
      formData.append('url', renamedFile);
    }

    if (image && image instanceof File) {
      // Generate a unique filename using a timestamp and the original file's extension
      const fileExtension = image.name.split('.').pop();
      const uniqueFilename = `${Date.now()}.${fileExtension}`;
    
      // Create a new File object with the unique filename
      const renamedFile = new File([image], uniqueFilename, {
        type: image.type,
      });
    
      // Append the renamed file to the formData
      formData.append('image', renamedFile);
    }
  
    formData.append('name', subname);
    formData.append('subheading', subheading);
    formData.append('description', subdescription);
    
  
    console.log(formData);
  
    try {
      const responseData = await saveRadioAudios(formData,id); // Call the submitFormData function
      console.log('Response:', responseData);
      // Handle success, e.g., display a success message or redirect
      alert('Success!');

    } catch (error) {
      console.error('Error:', error);
      // Handle error, e.g., display an error message
    }
  };   
      

    


  useEffect(() => {
    async function fetchData() {
      try {
        const data = await fetchRadioById(id);
        setPicture(data);
        setDisplayImg(data.display_img);
        setName(data.name || "");
        setDescription(data.description || "");
        setInstagramUrl(data.instagram_url || "");
        setWebsiteUrl(data.website_url || "");
        console.log(data)
      } catch (error) {
        // Handle error
      }

    }

    
    async function fetchAudioData() {
        try {
          const data = await fetchRadioAudios(id);
          setSubtable(data);
          setImage(data.image);
          setUrl(data.url);
          setSubname(data.subname || "");
          setSubdescription(data.subdescription || "");
          setSubheading(data.subheading || "");
          console.log("details below")
          console.log(data)
        } catch (error) {
          // Handle error
        }
  
      }

    fetchData();
    fetchAudioData();
    
  }, []);



  return (

    <div className='captures-form'>
      
      <div className='captures-form__add'>

      
      {/* <h2 class="mb-4 text-2xl font-bold leading-none tracking-tight text-gray-900 md:text-4xl">Update</h2> */}
      <p class="headtext__cormorant2">Update {picture.name}</p>
      <br/>
      <form class="w-full max-w-sm">
      <div class="md:flex md:items-center mb-6">
        <div class="md:w-1/3">
          <label class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4" for="inline-full-name">
            Display Image
          </label>
        </div>
          <div class="md:w-2/3">
            <img width="300px" height="300px" src={picture.display_img}/>
            <input
            class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" id="inline-full-name"
            type="file"
            name="display_img"
            accept="image/*"
            onChange={handleFileChange}
            />
          </div>
        </div>


        <div class="md:flex md:items-center mb-6">
          <div class="md:w-1/3">
            <label class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4" for="inline-full-name">
            Name:
            </label>
          </div>
            <div class="md:w-2/3">
              <input
              class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" id="inline-full-name"
              type="text"
              name="photographer_name"
              value={name}
              onChange={(e)=>setName(e.target.value)}
              />
            </div>
        </div>


        <div class="md:flex md:items-center mb-6">
        <div class="md:w-1/3">
          <label class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4" for="inline-full-name">
          Description:
          </label>
        </div>
          <div class="md:w-2/3">
            <textarea
            class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" id="inline-full-name"
            type="text"
            name="description"
            value={description}
            onChange={(e)=>setDescription(e.target.value)}
            />
          </div>
        </div>

        
        <div class="md:flex md:items-center mb-6">
        <div class="md:w-1/3">
          <label class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4" for="inline-full-name">
          Instagram Url:
          </label>
        </div>
          <div class="md:w-2/3">
            <input
            class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" 
            type="text"
            name="instagram_url"
            value={instagram_url}
            onChange={(e)=>setInstagramUrl(e.target.value)}
            />
          </div>
        </div>

        <div class="md:flex md:items-center mb-6">
        <div class="md:w-1/3">
          <label class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4" for="inline-full-name">
          Website Url:
          </label>
        </div>
          <div class="md:w-2/3">
            <input
            class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" 
            type="text"
            name="website_url"
            value={website_url}
            onChange={(e)=>setWebsiteUrl(e.target.value)}
            />
          </div>
        </div>

        <div class="md:flex md:items-center">
          <div class="md:w-1/3"></div>
          <div class="md:w-2/3">
            <button class="shadow bg-orange-400 hover:bg-orange-500 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded" 
            type="submit"
            // onClick={() => { onClose(); updateData(); }}
            onClick={updateData}
            >
            Submit
            </button>
          </div>
        </div>
        
      </form>

      </div>


      <div className='captures-form__add-images'>
      <p class="headtext__cormorant2">Add Audio under {picture.name}</p>
      <br/>
      <form class="w-full max-w-sm">

        <div class="md:flex md:items-center mb-6">
            <div class="md:w-1/3">
            <label class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4" for="inline-full-name">
                Display Image
            </label>
            </div>
            <div class="md:w-2/3">
                {/* <img width="300px" height="300px" src={picture.display_img}/> */}
                <input
                class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" id="inline-full-name"
                type="file"
                name="image"
                accept="image/*"
                onChange={handleImageFileChange}
                />
            </div>
        </div>


        <div class="md:flex md:items-center mb-6">
          <div class="md:w-1/3">
            <label class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4" for="inline-full-name">
            Name
            </label>
          </div>
            <div class="md:w-2/3">
              <input
              class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" id="inline-full-name"
              type="text"
              name="subname"
              value={subname}
              onChange={(e)=>setSubname(e.target.value)}
              />
            </div>
        </div>

        <div class="md:flex md:items-center mb-6">
          <div class="md:w-1/3">
            <label class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4" for="inline-full-name">
            Subheading
            </label>
          </div>
            <div class="md:w-2/3">
              <input
              class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" id="inline-full-name"
              type="text"
              name="subHeading"
              value={subheading}
              onChange={(e)=>setSubheading(e.target.value)}
              />
            </div>
        </div>

        <div class="md:flex md:items-center mb-6">
          <div class="md:w-1/3">
            <label class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4" for="inline-full-name">
            Description
            </label>
          </div>
            <div class="md:w-2/3">
              <input
              class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" id="inline-full-name"
              type="text"
              name="imageTitle"
              value={subdescription}
              onChange={(e)=>setSubdescription(e.target.value)}
              />
            </div>
        </div>


        <div class="md:flex md:items-center mb-6">
            <div class="md:w-1/3">
            <label class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4" for="inline-full-name">
                Upload Audio
            </label>
            </div>
            <div class="md:w-2/3">
                {/* <img width="300px" height="300px" src={picture.display_img}/> */}
                <input
                class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" id="inline-full-name"
                type="file"
                name="url"
                accept="audio/*"
                onChange={handleAudioFileChange}
                />
            </div>
        </div>


        <div class="md:flex md:items-center">
          <div class="md:w-1/3"></div>
          <div class="md:w-2/3">
            <button class="shadow bg-orange-400 hover:bg-orange-500 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded" 
            type="submit"
            // onClick={() => { onClose(); updateData(); }}
            onClick={addAudios}
            >
            Add
            </button>
          </div>
        </div>
        
      </form>

      
      </div>

      <p class="headtext__cormorant2">All Audios in {picture.name}</p>
    <div className='radios-form__show'>
        {/* <div className='app__capturelist'> */}
        {/* <Searchbar/> */}
        {subtable.map((photographer, index) => (
          
          <RadioAudiosFormItem
            id={photographer.id}
            image={photographer.image}
            name={photographer.name}
            // description={photographer.description}
            subheading={photographer.subheading}
            description={photographer.description}
            url={photographer.url}
            
            // img_urls={photographer.img_urls}

          />
        ))}
      {/* </div> */}

      </div>


      
    </div>
  );
}

export default RadiosForm;
