import React from 'react';

const SpotifyEmbed = () => {
  // Paste the Spotify embed code you copied here.
  const embedCode = '<iframe style="border-radius:12px" src="https://open.spotify.com/embed/episode/0pLcjYLeV8zmvgV9AUFwei?utm_source=generator&theme=0" width="100%" height="152" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>';

  return (
    <div dangerouslySetInnerHTML={{ __html: embedCode }} />
  );
};

export default SpotifyEmbed;
