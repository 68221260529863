import React, { useState, useEffect } from 'react';
import { FaInstagram, RxInstagramLogo } from 'react-icons/fa';
import { images } from '../../constants';
import './Gallery.css';
import { Link } from 'react-router-dom';
import { fetchGallerys} from '../../services/gallery'

const Gallery = ({  }) => {
    

    const [radios, setRadios] = useState([]);

    useEffect(() => {
      async function fetchData() {
        try {
          const data = await fetchGallerys();
          // setArchives(data);
          console.log(data)
          setRadios(data)
        } catch (error) {
          // Handle error
        }
      }
  
      fetchData();
    }, []);    
    
    
    
return (

    

    



  // console.log(contentType)
  <div className="app__gallery">

    {radios && radios.length > 0 && radios.reverse().map((v, index) => (
            
        <div className='app__gallery-box'>
        <div className='app__gallery-img' key={index}>
            {v.type === 'image' ? (
            <img src={v.url} height="300px" width="300px" alt="" />
            ) : v.type === 'video' ? (
            <video src={v.url} height="300px" width="300px" controls></video>
            ) : null}
        </div>
        
        <p className='p__cormorant'>{v.heading}</p>
        <p className='p__cormorant-small'>{v.subheading}</p>

        </div>
        
        ))} 



    {/* <div className='app__gallery-box'>
        <div className='app__gallery-img'>
        <video  height="300" controls autoplay>
            <source src="https://storage.googleapis.com/thecockneysikh-storage/Gallery/IMG_0898.MOV" type="video/mp4"/>
            </video>
       
        </div>
        <p className='p__cormorant'>Bricklane Jazz Festival</p>
        <p className='p__cormorant-small'>Bricklane Jazz Festival, Sunday 16/04/2023 with Sarathy Korwar and guests</p>
    </div>
    

   

    <div className='app__gallery-box'>
        <div className='app__gallery-img'>
        <video width="400" controls autoplay>
        <source src="https://storage.googleapis.com/thecockneysikh-storage/Gallery/IMG_0899.MOV" type="video/mp4"/>
        </video>
       
        </div>
        <p className='p__cormorant'>Bricklane Jazz Festival</p>
        <p className='p__cormorant-small'>Bricklane Jazz Festival, Sunday 16/04/2023 with Sarathy Korwar and guests</p>
    </div>

    <div className='app__gallery-box'>
        <div className='app__gallery-img'>
        <img src='https://storage.googleapis.com/thecockneysikh-storage/Gallery/homepage.png' height="300px" width="300px"  alt=""/>
       
        </div>
        <p className='p__cormorant'>DISHOOM</p>
        
    </div>

    <div className='app__gallery-box'>
        <div className='app__gallery-img'>
        <img src='https://storage.googleapis.com/thecockneysikh-storage/Gallery/dishoom1.png' height="300px" width="300px"  alt=""/>
       
        </div>
        <p className='p__cormorant'>DISHOOM Canary Wharf</p>
        <p className='p__cormorant-small'>Here hangs in the middle a picture of my dad and his brother</p>
    </div>

    
    <div className='app__gallery-box'>
        <div className='app__gallery-img'>
        <img src="https://storage.googleapis.com/thecockneysikh-storage/Gallery/IMG_6184.jpeg" height="300px" width="300px" alt=""/>
       
        </div>
        <p className='p__cormorant'>Part of Mural Rich Mix Center </p>
        <p className='p__cormorant-small'>Bethnal Green E1</p>
    </div>

    
    
    <div className='app__gallery-box'>
        <div className='app__gallery-img'>
        <img src='https://storage.googleapis.com/thecockneysikh-storage/Gallery/2b6fada7-9a43-472a-8284-7751e2cddcfa.JPG' height="300px" width="300px"  alt=""/>
       
        </div>
        <p className='p__cormorant'>Article by Devinder Bains</p>
        <p className='p__cormorant-small'>DECENT Magzine for Men by Women</p>
    </div>
    
    <div className='app__gallery-box'>
        <div className='app__gallery-img'>
        <iframe width="300" height="300"  src='https://storage.googleapis.com/thecockneysikh-storage/Gallery/v1.mp4' frameborder="0" ></iframe>
       
        </div>
        <p className='p__cormorant'>Clare O'Connell Cellist</p>
        <p className='p__cormorant-small'>Friday 27/09/2021, In the Beautiful House Ben Adler, Fournier Street Spitalfields  </p>
    </div>

    <div className='app__gallery-box'>
        <div className='app__gallery-img'>
        <img src='https://storage.googleapis.com/thecockneysikh-storage/Gallery/IMG_6183.jpeg' height="300px" width="300px"  alt=""/>
       
        </div>
        <p className='p__cormorant'>Part of Mural Rich Mix Center </p>
        <p className='p__cormorant-small'>Bethnal Green E1</p>
    </div>


    <div className='app__gallery-box'>
        <div className='app__gallery-img'>
        <img src='https://storage.googleapis.com/thecockneysikh-storage/Gallery/Screenshot%202023-09-04%20212648.png' height="300px" width="300px"  alt=""/>
       
        </div>
        <p className='p__cormorant'>Article by Devinder Bains</p>
        <p className='p__cormorant-small'>DECENT Magzine for Men by Women</p>
    </div>

    <div className='app__gallery-box'>
        <div className='app__gallery-img'>
        <img src='https://storage.googleapis.com/thecockneysikh-storage/Gallery/1.jpeg' height="300px" width="300px"  alt=""/>
       
        </div>
        <p className='p__cormorant'>For my Friend Ben Kelley</p>
        
    </div>

    <div className='app__gallery-box'>
        <div className='app__gallery-img'>
        <img src='https://storage.googleapis.com/thecockneysikh-storage/Gallery/34645514-8490-4970-9C53-8AE698353CDB.jpg' height="300px" width="300px"  alt=""/>
       
        </div>
        <p className='p__cormorant'>Dad's Picture of Guru Nanak ji</p>
        
    </div>


    <div className='app__gallery-box'>
        <div className='app__gallery-img'>
        <img src='https://storage.googleapis.com/thecockneysikh-storage/Gallery/A5B65709-B3B6-43CC-A157-2FCE29BAEA3A.jpg' height="300px" width="300px"  alt=""/>
       
        </div>
        <p className='p__cormorant'>Chirst Church Spitalfields</p>
        
    </div>

    <div className='app__gallery-box'>
        <div className='app__gallery-img'>
        <img src='https://storage.googleapis.com/thecockneysikh-storage/Gallery/IMG_4337.jpeg' height="300px" width="300px"  alt=""/>
       
        </div>
        <p className='p__cormorant'>My Primary School Chirst Church Brick Lane</p>
        
    </div>


    
    <div className='app__gallery-box'>
        <div className='app__gallery-img'>
        <img src='https://storage.googleapis.com/thecockneysikh-storage/Gallery/IMG_4922.jpeg' height="300px" width="300px"  alt=""/>
       
        </div>
        <p className='p__cormorant'>Planet Organic Spitalfields</p>
       
    </div>


    <div className='app__gallery-box'>
        <div className='app__gallery-img'>
        <iframe width="300" height="300"  src='https://storage.googleapis.com/thecockneysikh-storage/Gallery/281747402_150428190829624_4052089344549546343_n%20(1).mp4' frameborder="0" ></iframe>
       
        </div>
        <p className='p__cormorant'>Produced by Wiktoria Wisniewska & Anmy Pazos</p>
       
    </div>

    <div className='app__gallery-box'>
        <div className='app__gallery-img'>
        <iframe width="300" height="300"  src='https://storage.googleapis.com/thecockneysikh-storage/Gallery/281532350_121159410583232_4540689494805339123_n.mp4' frameborder="0" ></iframe>
       
        </div>
        <p className='p__cormorant'>Produced by Wiktoria Wisniewska & Anmy Pazos</p>
       
    </div>

    <div className='app__gallery-box'>
        <div className='app__gallery-img'>
        <iframe width="300" height="300"  src='https://storage.googleapis.com/thecockneysikh-storage/Gallery/281327375_3252282114991289_7907957868934804321_n.mp4' frameborder="0" ></iframe>
       
        </div>
        <p className='p__cormorant'>Produced by Wiktoria Wisniewska & Anmy Pazos</p>
       
    </div> */}

    

  
</div>
    
    



   
)};

export default Gallery;