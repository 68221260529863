import React from 'react';
import { FaInstagram, RxInstagramLogo } from 'react-icons/fa';
import { images } from '../../constants';
import './Archivebox.css';
import { Link } from 'react-router-dom';

const Archivebox = ({ id,archive_name, display_img_url, header_img_url, description, img_urls}) => (
  <div className="app__archivebox">

    
    <div className='app__archivebox-img'>
    <img src = {display_img_url} alt={archive_name} />
    </div>

    <div className='app__archivebox-content'>
    
        <div className="app__archivebox-head-img">
        
        <Link to="/archivesdetails" state={{ id: id }}><img src={header_img_url} alt={archive_name} /></Link>
        </div>   

        {/* <div className="app__archivebox-tagline">
            <p className="font_style_italic">Oct 8, 2023 @ The Hanbury Hall - Spitafields </p>
        </div> */}

        <div className="app__archivebox-paragraph">
            <p className="subheading__cormorant ">{description}</p>
        </div>

    </div>


  </div>
);

export default Archivebox;