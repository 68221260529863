import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { Footer,  Header, Archives,Happenings,Speaks,Captures, CapturesList, DadsRecords, Library, GrandmothersPans, CapturesDetails,Attire,Book,Map, HappeningsDetails, ArchivesDetails, SpeaksDetails, Gallery,Garden, PortraitsList,PortraitsDetails, FilmsList,FilmsDetails, MusicList, MusicDetails,RadioList,RadioDetails, InPrint} from './container';

import { FormsHomepage, PortraitsForm, RadiosForm,MusicsForm,SpeaksForm,FilmsForm, InPrintsForm,DadsRecordsForm,GallerysForm,GardensForm,GrandMothersPansForm,HappeningsForm,LibrarysForm,HomepageImgForm,MapForm,AttireForm} from './forms';

import { Navbar } from './components';
import './App.css';

const App = () => (


<Router>
<div>
  <Navbar />
  {/* {NoNavbar() && <Navbar />} */}
  <Routes>
    <Route path="/" element={<Header/>} />
    <Route path="/archives" element={<Archives/>} />
    <Route path="/happenings" element={<Happenings/>} />
    <Route path="/captures" element={<Captures/>} />
    <Route path="/speaks" element={<Speaks/>} />
    <Route path="/Gallery" element={<Gallery/>} />
    <Route path="/Garden" element={<Garden/>} />

    


    <Route path="/captureslist" element={<CapturesList/>} />
    <Route path="/portraitslist" element={<PortraitsList/>} />
    <Route path="/filmslist" element={<FilmsList/>} />
    <Route path="/musiclist" element={<MusicList/>} />
    <Route path="/radiolist" element={<RadioList/>} />

    <Route path="/capturesdetails" element={<CapturesDetails/>} />
    <Route path="/PortraitsDetails" element={<PortraitsDetails/>} />
    <Route path="/filmsdetails" element={<FilmsDetails/>} />
    <Route path="/musicsdetails" element={<MusicDetails/>} />
    <Route path="/radiosdetails" element={<RadioDetails/>} />

    <Route path="/inprint" element={<InPrint/>} />


    <Route path="/attire" element={<Attire/>} />
    <Route path="/book" element={<Book/>} />
    <Route path="/map" element={<Map/>} />
    <Route path="/dadsrecords" element={<DadsRecords/>} />
    <Route path="/library" element={<Library/>} />
    <Route path="/grandmotherspans" element={<GrandmothersPans/>} />
    <Route path="/happeningsdetails" element={<HappeningsDetails/>} />
    {/* <Route path="/happeningsdetails" element={<HappeningsDetails/>} /> */}
    <Route path="/archivesDetails" element={<ArchivesDetails/>} />
    <Route path="/speaksdetails" element={<SpeaksDetails/>} />
    
    {/* <Route path="/forms/archives" element={<ArchivesForm/>} />
    <Route path="/forms/captures" element={<CapturesForm/>} /> */}
    <Route path="/forms/templates" element={<FormsHomepage/>} />

    <Route path="/forms/portraits" element={<PortraitsForm/>} />
    <Route path="/forms/radios" element={<RadiosForm/>} />

    <Route path="/forms/musics" element={<MusicsForm/>} />
    <Route path="/forms/films" element={<FilmsForm/>} />
    <Route path="/forms/inprints" element={<InPrintsForm/>} />

    <Route path="/forms/dadsrecords" element={<DadsRecordsForm/>} />
    <Route path="/forms/gallery" element={<GallerysForm/>} />
    <Route path="/forms/garden" element={<GardensForm/>} />
    <Route path="/forms/grandmotherspans" element={<GrandMothersPansForm/>} />
    <Route path="/forms/happenings" element={<HappeningsForm/>} />
    <Route path="/forms/library" element={<LibrarysForm/>} />
    <Route path="/forms/homepageimg" element={<HomepageImgForm/>} />

    <Route path="/forms/map" element={<MapForm/>} />
    <Route path="/forms/attire" element={<AttireForm/>} />
     

    
  </Routes>
  <Footer/>
</div>


  {/* <Routes>
    <Route path="/forms/archives" element={<Header/>} />

  </Routes> */}
</Router>




);




export default App;
