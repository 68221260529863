// MyComponent.js
import './PicturesForm.css'

import React, { useState, useEffect } from 'react';
import { images,captures } from '../../constants';
import { SubHeading, FormDisplayItem, Searchbar, Eventbox,  PortraitImagesFormItem } from '../../components';
import {updatePortrait, savePortraitImages,fetchPortraitImages,fetchPortraitById} from '../../services/portraits'; // Import the specific API function
import axios from 'axios';

import {useNavigate} from 'react-router-dom';

// const navigate = useNavigate();


function PicturesForm({onClose, id }) {
    const navigate = useNavigate();
    const [picture, setPicture] = useState([]);
    

    const [pictureImages, setPictureImages] = useState([]);

    
  const [display_img, setDisplayImg] = useState(null);
  const [photographer_name, setPhotographerName] = useState(picture.name || "");
  const [description, setDescription] =  useState(picture.description || "");
  const [instagram_url, setInstagramUrl] = useState(picture.instagram_url || "");
  const [website_url, setWebsiteUrl] = useState(picture.website_url || "");

  
  const [imageTitle, setImageTitle] = useState("")


  
  // subform

  const [subtable, setSubtable] = useState([]);
  const [image, setImage] = useState(null);
  const [title, setTitle] = useState("");




    console.log(id)


   // Handle file input change
   const handleFileChange = (e) => {
    setDisplayImg(e.target.files[0]);
  };

     // Handle sub images
     const handleImageFileChange = (e) => {
      setImage(e.target.files[0]);
    };


 // Handle form submission
 const updateData = async (e) => {
  e.preventDefault();

  // Create a FormData object to send the form data
  const formData = new FormData();
  // console.log(display_img)
  if (display_img && display_img instanceof File) {
    // Generate a unique filename using a timestamp and the original file's extension
    const fileExtension = display_img.name.split('.').pop();
    const uniqueFilename = `${Date.now()}.${fileExtension}`;
  
    // Create a new File object with the unique filename
    const renamedFile = new File([display_img], uniqueFilename, {
      type: display_img.type,
    });
  
    // Append the renamed file to the formData
    formData.append('display_img', renamedFile);
  }


  formData.append('name', photographer_name);
  formData.append('description', description);
  formData.append('instagram_url', instagram_url);
  formData.append('website_url', website_url);

  console.log(formData);

  try {
    const responseData = await updatePortrait(formData,id); // Call the submitFormData function
    console.log('Response:', responseData);
    // Handle success, e.g., display a success message or redirect
    alert('Success!');
  } catch (error) {
    console.error('Error:', error);
    // Handle error, e.g., display an error message
  }
};


const addImages = async (e) => {
  e.preventDefault();

  // Create a FormData object to send the form data
  const formData = new FormData();
  console.log(image)
  if (image && image instanceof File) {
    // Generate a unique filename using a timestamp and the original file's extension
    const fileExtension = image.name.split('.').pop();
    const uniqueFilename = `${Date.now()}.${fileExtension}`;
  
    // Create a new File object with the unique filename
    const renamedFile = new File([image], uniqueFilename, {
      type: image.type,
    });
  
    // Append the renamed file to the formData
    formData.append('image', renamedFile);
  }

  formData.append('title', title);
 

  // console.log(formData);

  try {
    const responseData = await savePortraitImages(formData,id); // Call the submitFormData function
    console.log('Response:', responseData);
    // Handle success, e.g., display a success message or redirect
    alert('Success!');
  } catch (error) {
    console.error('Error:', error);
    // Handle error, e.g., display an error message
  }
}; 

    


  useEffect(() => {
    async function fetchData() {
      try {
        const data = await fetchPortraitById(id);
        setPicture(data);
        setDisplayImg(data.display_img);
        setPhotographerName(data.name || "");
        setDescription(data.description || "");
        setInstagramUrl(data.instagram_url || "");
        setWebsiteUrl(data.website_url || "");
        console.log(data)
      } catch (error) {
        // Handle error
      }

      try {
        const data = await fetchPortraitImages(id);
        setPictureImages(data);
        console.log(data)
      } catch (error) {
        // Handle error
      }

    }

    async function fetchImageData() {
      try {
        const data = await fetchPortraitImages(id);
        setSubtable(data);
        setImage(data.image);
        setTitle(data.title || "");
        
        console.log("details below")
        console.log(data)
      } catch (error) {
        // Handle error
      }

    }

    fetchData();
    fetchImageData();
  }, []);



  return (

    <div className='captures-form'>
      
      <div className='captures-form__add'>

      <p class="headtext__cormorant2">Update {picture.name}</p>
      <br/>

      
      {/* <h2 class="mb-4 text-2xl font-bold leading-none tracking-tight text-gray-900 md:text-4xl">Update</h2> */}

      <form class="w-full max-w-sm">
      <div class="md:flex md:items-center mb-6">
        <div class="md:w-1/3">
          <label class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4" for="inline-full-name">
            Display Image
          </label>
        </div>
          <div class="md:w-2/3">
            <img width="300px" height="300px" src={picture.display_img}/>
            <input
            class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" id="inline-full-name"
            type="file"
            name="display_img"
            accept="image/*"
            onChange={handleFileChange}
            />
          </div>
        </div>


        <div class="md:flex md:items-center mb-6">
          <div class="md:w-1/3">
            <label class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4" for="inline-full-name">
            Photographer Name:
            </label>
          </div>
            <div class="md:w-2/3">
              <input
              class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" id="inline-full-name"
              type="text"
              name="photographer_name"
              value={photographer_name}
              onChange={(e)=>setPhotographerName(e.target.value)}
              />
            </div>
        </div>


        <div class="md:flex md:items-center mb-6">
        <div class="md:w-1/3">
          <label class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4" for="inline-full-name">
          Description:
          </label>
        </div>
          <div class="md:w-2/3">
            <textarea
            class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" id="inline-full-name"
            type="text"
            name="description"
            value={description}
            onChange={(e)=>setDescription(e.target.value)}
            />
          </div>
        </div>

        
        <div class="md:flex md:items-center mb-6">
        <div class="md:w-1/3">
          <label class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4" for="inline-full-name">
          Instagram Url:
          </label>
        </div>
          <div class="md:w-2/3">
            <input
            class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" 
            type="text"
            name="instagram_url"
            value={instagram_url}
            onChange={(e)=>setInstagramUrl(e.target.value)}
            />
          </div>
        </div>

        <div class="md:flex md:items-center mb-6">
        <div class="md:w-1/3">
          <label class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4" for="inline-full-name">
          Website Url:
          </label>
        </div>
          <div class="md:w-2/3">
            <input
            class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" 
            type="text"
            name="website_url"
            value={website_url}
            onChange={(e)=>setWebsiteUrl(e.target.value)}
            />
          </div>
        </div>

        <div class="md:flex md:items-center">
          <div class="md:w-1/3"></div>
          <div class="md:w-2/3">
            <button class="shadow bg-orange-400 hover:bg-orange-500 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded" 
            type="submit"
            // onClick={() => { onClose(); updateData(); }}
            onClick={updateData}
            >
            Submit
            </button>
          </div>
        </div>
        
      </form>

      </div>


      <div className='captures-form__add-images'>
      <p class="headtext__cormorant2">Add Image under {picture.name}</p>
      <form class="w-full max-w-sm">
      <div class="md:flex md:items-center mb-6">
        <div class="md:w-1/3">
          <label class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4" for="inline-full-name">
            Add Image
          </label>
        </div>
          <div class="md:w-2/3">
            {/* <img width="300px" height="300px" src={picture.display_img}/> */}
            <input
            class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" id="inline-full-name"
            type="file"
            name="image"
            accept="image/*"
            onChange={handleImageFileChange}
            />
          </div>
        </div>


        <div class="md:flex md:items-center mb-6">
          <div class="md:w-1/3">
            <label class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4" for="inline-full-name">
            Image Title
            </label>
          </div>
            <div class="md:w-2/3">
              <input
              class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" id="inline-full-name"
              type="text"
              name="imageTitle"
              value={imageTitle}
              onChange={(e)=>setImageTitle(e.target.value)}
              />
            </div>
        </div>

        <div class="md:flex md:items-center">
          <div class="md:w-1/3"></div>
          <div class="md:w-2/3">
            <button class="shadow bg-orange-400 hover:bg-orange-500 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded" 
            type="submit"
            // onClick={() => { onClose(); updateData(); }}
            onClick={addImages}
            >
            Add
            </button>
          </div>
        </div>
        
      </form>

      </div>

      <p class="headtext__cormorant2">All Images in {picture.name}</p>
    <div className='radios-form__show'>
        {/* <div className='app__capturelist'> */}
        {/* <Searchbar/> */}
        {subtable.map((photographer, index) => (
          
          <PortraitImagesFormItem
            id={photographer.id}
            url={photographer.image}
            name={photographer.name}
            // description={photographer.description}
            subheading={photographer.subheading}
            description={photographer.description}
            
            // img_urls={photographer.img_urls}

          />
        ))}
      {/* </div> */}

      </div>

      
    </div>
  );
}

export default PicturesForm;
