import React from 'react';
import { FaInstagram, RxInstagramLogo } from 'react-icons/fa';
import { images } from '../../constants';
import './Happeningsbox.css';
import { Link } from 'react-router-dom';

const Happeningsbox = ({ id, display_img, name, tagline, description, website_url, details_page_link,contentType, header_img_url}) => (
  // console.log(contentType)
  <div className="app__happeningsbox">

    
    <div className='app__happeningsbox-img'>
    {/* <img src={display_img} alt={name} /> */}
    {/* <p>Image Credits: Beverley Onyangunga</p> */}
    <figure>
        <img src={display_img} alt="Image Credits: Beverley Onyangunga"/>
        {/* <figcaption class="caption"><i>Image Credits: Beverley Onyangunga</i></figcaption> */}
        
    </figure>

    </div>

    <div className='app__happeningsbox-content'>
    
        <div className="app__happeningsbox-head">
        <p className="headtext__cormorant font_style_italic"><Link to={details_page_link} state={{ id:id }}>{name}</Link></p>
        </div>   

        <div className="app__happeningsbox-tagline">
            <p className="font_style_italic">{tagline}</p>
        </div>

        <div className="app__happeningsbox-paragraph">
            <p className="font_style_italic p__cormorant ">{description}</p>
        </div>

        <div className='app__happeningsbox-action'>
            {/* <div className='app__happeningsbox__button'>
            <button className='custom__button'><a href={website_url}>Website</a></button>
            </div> */}


        </div>
    </div>


  </div>
);

export default Happeningsbox;