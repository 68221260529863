import React, { useState, useEffect } from 'react';
import { FaInstagram, RxInstagramLogo } from 'react-icons/fa';
import { images } from '../../constants';
import './Library.css';
import { Link } from 'react-router-dom';
import { fetchLibrary} from '../../services/library'

const Library = ({  }) => {
  
  
  const [radios, setRadios] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        const data = await fetchLibrary();
        // setArchives(data);
        console.log(data)
        setRadios(data)
      } catch (error) {
        // Handle error
      }
    }

    fetchData();
  }, []); 


  return(
  
  <div className="app__gallery">

{radios && radios.length > 0 && radios.reverse().map((v, index) => (
            
            <div className='app__gallery-box'>
            <div className='app__gallery-img' key={index}>
                {v.type === 'image' ? (
                <img src={v.url} height="300px" width="300px" alt="" />
                ) : v.type === 'video' ? (
                <video src={v.url} height="300px" width="300px" controls></video>
                ) : null}
            </div>
            <p className='p__cormorant'>{v.heading}</p>
            <p className='p__cormorant-small'>{v.subheading}</p>
            </div>
            
            ))} 
    


    




{/* <div className='app__gallery-img'>
    <img src="https://storage.googleapis.com/thecockneysikh-storage/library/14.png" height="300px" width="300px" alt=""/>
    </div>

    <div className='app__gallery-img'>
    <img src="https://storage.googleapis.com/thecockneysikh-storage/library/11.png" height="300px" width="300px" alt=""/>
    </div>
    
    <div className='app__gallery-img'>
    <img src="https://storage.googleapis.com/thecockneysikh-storage/library/1.png" height="300px" width="300px" alt=""/>
    </div>

    <div className='app__gallery-img'>
    <img src="https://storage.googleapis.com/thecockneysikh-storage/library/2.png" height="300px" width="300px" alt=""/>
    </div>

    <div className='app__gallery-img'>
    <img src="https://storage.googleapis.com/thecockneysikh-storage/library/3.png" height="300px" width="300px" alt=""/>
    </div>

    <div className='app__gallery-img'>
    <img src="https://storage.googleapis.com/thecockneysikh-storage/library/4.png" height="300px" width="300px" alt=""/>
    </div>

    <div className='app__gallery-img'>
    <img src="https://storage.googleapis.com/thecockneysikh-storage/library/5.png" height="300px" width="300px" alt=""/>
    </div>

    <div className='app__gallery-img'>
    <img src="https://storage.googleapis.com/thecockneysikh-storage/library/6.png" height="300px" width="300px" alt=""/>
    </div>

    <div className='app__gallery-img'>
    <img src="https://storage.googleapis.com/thecockneysikh-storage/library/7.png" height="300px" width="300px" alt=""/>
    </div>

    <div className='app__gallery-img'>
    <img src="https://storage.googleapis.com/thecockneysikh-storage/library/8.png" height="300px" width="300px" alt=""/>
    </div>

    <div className='app__gallery-img'>
    <img src="https://storage.googleapis.com/thecockneysikh-storage/library/9.png" height="300px" width="300px" alt=""/>
    </div>

    <div className='app__gallery-img'>
    <img src="https://storage.googleapis.com/thecockneysikh-storage/library/10.png" height="300px" width="300px" alt=""/>
    </div>

    

    <div className='app__gallery-img'>
    <img src="https://storage.googleapis.com/thecockneysikh-storage/library/12.png" height="300px" width="300px" alt=""/>
    </div>

    
    <div className='app__gallery-img'>
    <img src="https://storage.googleapis.com/thecockneysikh-storage/library/13.png" height="300px" width="300px" alt=""/>
    </div> */}


  
</div>
    
    



   
)};

export default Library;