import React, { useState, useEffect } from 'react';

import { SubHeading,Archivebox,Searchbar, SubpageHeaderImage, SubpageHeader } from '../../components';
import { FaInstagram, RxInstagramLogo } from 'react-icons/fa';

import { images, archives } from '../../constants';
import './Archives.css';
import { Link } from 'react-router-dom';
import { fetchArchives} from '../../services/archives';

 const Archives = () => {
  
  const [radios, setRadios] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        const data = await fetchArchives();
        // setArchives(data);
        console.log(data)
        setRadios(data)
      } catch (error) {
        // Handle error
      }
    }

    fetchData();
  }, []);


  
 return (

  <div className="">
  {/* <SubpageHeaderImage/> */}
  <div className='app__archives-banner'>
  <img
        src="https://storage.googleapis.com/thecockneysikhstorage/archives/banner.png"
        alt="Archives Banner"
        className="moving-image"
      />
      <img
        src="https://storage.googleapis.com/thecockneysikhstorage/archives/banner.png"
        alt="Archives Banner"
        className="moving-image"
      />

  </div>
    
    <div className='app__archives'>
      <div className='app_archives-header'>
      <div className='app_archives-text'>
          <h1 className="headtext__cormorant"><a href='https://www.bishopsgate.org.uk/collections/suresh-singh-archive' target="_blank">Archived at The Bishopsgate Institute</a></h1>
          <h1 className="subheadtext__cormorant">Since 2018</h1>
          {/* <div className="app__archives-tagline"> */}
              {/* <p className="font_style_italic">Since 2018</p> */}
          {/* </div> */}
      </div>
      <div className='app_archives-img'>
      <a href='https://www.bishopsgate.org.uk/collections/suresh-singh-archive' target="_blank"><img src={images.bishopsgate}/></a>
      </div>

      </div> 

    <div className='app__archives-box-holder'>
    {radios && radios.length > 0 && radios.reverse().map((a, index) => (
        <Archivebox
          key={a.id}
          id = {a.id}
          display_img_url={a.display_img}
          archive_name={a.name}
          header_img_url={a.header_img}
          description={a.description}
          // img_urls={a.img_urls}

        />
      ))}
    </div>
    

    </div>
   

  </div>
)};

export default Archives;