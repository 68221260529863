import React from 'react';
import { FaInstagram, RxInstagramLogo } from 'react-icons/fa';
import { images } from '../../constants';
import './SubpageHeader.css';
import { Link } from 'react-router-dom';

 const SubpageHeader = ({name, description, insta_url, website_url}) => (
  
 
    <div className="app__subpageheader">
    
    <div className="app__subpageheader-head">
    <h1 className="headtext__cormorant">{name}</h1>
    </div>   

    <div className="app__subpageheader-paragraph">
        {/* <p className="font_style_italic p__cormorant ">{description}</p> */}
        <p className='font_style_italic p__cormorant' dangerouslySetInnerHTML={{ __html: description }}/>
    </div>

    <div className='app__subpageheader-action'>
        {/* <div className='app__subpageheader-button'>
        <button className='custom__button'><a href={website_url}>Website</a></button>
        </div> */}
        <div className='app__subpageheader-icon'>
            <a href={insta_url}><img src={images.instagramlogo} alt='instagram'/>
            </a>
        </div>

        </div>
      
    </div>


);

export default SubpageHeader;