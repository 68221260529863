import axios from 'axios';

const API_BASE_URL = 'https://api.thecockneysikh.com/archives';


// GET all Archive photographers
export const fetchArchives = async () => {
  try {
    const response = await axios.get('https://api.thecockneysikh.com/archives/');
    return response.data;
  } catch (error) {
    throw error;
  }
};


// GET one Archive photographer by id
export const fetchArchiveById = async (id) => {
  try {
      const response = await axios.get(`https://api.thecockneysikh.com/archives/${id}/`);
      return response.data;
  } catch (error) {
      throw error;
  }
};

// GET one Archive photographer by id
export const deleteArchiveById = async (id) => {
  try {
      const response = await axios.delete(`https://api.thecockneysikh.com/archives/${id}/`);
      return response.data;
  } catch (error) {
      throw error;
  }
};




// POST create new Archive
export const saveArchive  = async (formData) => {
    try {
      const response = await axios.post(
        'https://api.thecockneysikh.com/archives/',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };


  // PUT update existing Archive
export const updateArchive  = async (formData,id ) => {
  console.log(id)
    try {
      const response = await axios.put(
        `https://api.thecockneysikh.com/archives/${id}/`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

/////////////////////

// GET all pictures of Archive photographers
export const fetchArchiveImages = async (id) => {
  try {
      const response = await axios.get(`https://api.thecockneysikh.com/archives/${id}/images`);
      return response.data;
  } catch (error) {
      throw error;
  }
};


/// add images




// GET one Archive photographer by id
export const fetchArchiveImagesById = async (id) => {
  try {
      const response = await axios.get(`https://api.thecockneysikh.com/archives/images/${id}`);
      return response.data;
  } catch (error) {
      throw error;
  }
};


export const deleteArchiveImagesById = async (id) => {
  try {
      const response = await axios.delete(`https://api.thecockneysikh.com/archives/images/${id}`);
      return response.data;
  } catch (error) {
      throw error;
  }
};


// PUT update existing radio
export const updateArchiveImages  = async (formData,id ) => {
  console.log(id)
    try {
      const response = await axios.put(
        `https://api.thecockneysikh.com/archives/images/${id}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };


// POST create new radio
export const saveArchiveImages  = async (formData,id) => {
  try {
    const response = await axios.post(
      `https://api.thecockneysikh.com/archives/${id}/images`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
