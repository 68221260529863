import React, {useState}from 'react';
import { GiHamburgerMenu } from 'react-icons/gi';
// import { AiOutlineClose } from 'react-icons/md';
import { FiX } from 'react-icons/fi';
import images from '../../constants/images';
import './Navbar.css';
import { Link } from 'react-router-dom';
import { DropdownMenu, Searchbar, SearchResults, SearchDropDownMenu } from '../../components';

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = React.useState(false);
  // const [results, setResults] = useState([]);
  return (
    <nav className="app__navbar">
      
      <ul className="app__navbar-links">
      <DropdownMenu />
        
        <li className="p__opensans"><Link to="/archives">Archives</Link></li>
        <li className="p__opensans"><Link to="/book">Book</Link></li>
        {/* <li className="p__opensans"><Link to="/captures">Captures</Link></li> */}
        
        
        <li className="p__opensans"><Link to="/musiclist">Music</Link></li>
        <li className="p__opensans"><Link to="/radiolist">Radio</Link></li>
        <li className="p__opensans"><Link to="/filmslist">Films</Link></li>
        <li className="p__opensans"><Link to="/inprint">In&nbsp;Print</Link></li>
        <li className="p__opensans"><Link to="/portraitslist">Portraits</Link></li>
        
        
        <li className="p__opensans"><Link to="/gallery">Gallery</Link></li>
        <li className="p__opensans"><Link to="/happenings">Happenings</Link></li>
        <li className="p__opensans"><Link to="/speaks">Speaks</Link></li>
        {/* <li className="p__opensans"> */}
        {/* <div className='nav-search-icon'>
          <img src={images.navsearchicon}/>
        </div> */}
        
        <SearchDropDownMenu />
        {/* <DropdownMenu /> */}
      </ul>

      <div className="app__navbar-smallscreen ">
        <GiHamburgerMenu color="#000" fontSize={27} onClick={() => setToggleMenu(true)} />
        {toggleMenu && (
          <div className="app__navbar-smallscreen_overlay flex__center slide-bottom">
            <FiX fontSize={27} className="overlay__close" onClick={() => setToggleMenu(false)} />
            <ul className="app__navbar-smallscreen_links ">
            <li><Link onClick={() => setToggleMenu(false)} to="/">Home</Link></li>
              <li><Link onClick={() => setToggleMenu(false)} to="/archives">Archives</Link></li>
              <li><Link onClick={() => setToggleMenu(false)} to="/book">Book</Link></li>
              {/* <li><Link onClick={() => setToggleMenu(false)} to="/captures">Captures</Link></li> */}

              <li><Link onClick={() => setToggleMenu(false)} to="/musiclist">Music</Link></li>
              <li><Link onClick={() => setToggleMenu(false)} to="/radiolist">Radio</Link></li>
              <li><Link onClick={() => setToggleMenu(false)} to="/filmslist">Films</Link></li>
              <li><Link onClick={() => setToggleMenu(false)} to="/inprint">In&nbsp;Print</Link></li>
              <li><Link onClick={() => setToggleMenu(false)} to="/portraitslist">Portraits</Link></li>

              {/* <li><a href="#menu" onClick={() => setToggleMenu(false)}>Menu</a></li> */}
              <li><Link onClick={() => setToggleMenu(false)} to="/gallery">Gallery</Link></li>
              <li><Link onClick={() => setToggleMenu(false)} to="/happenings">Happenings</Link></li>
              <li><Link onClick={() => setToggleMenu(false)} to="/speaks">Speaks</Link></li>
              
              <li><Link onClick={() => setToggleMenu(false)} to="/map">Map</Link></li>
              <li><Link onClick={() => setToggleMenu(false)} to="/garden">Jagir Kaur ji's Organic Garden</Link></li>
              <li><Link onClick={() => setToggleMenu(false)} to="/attire">Attire</Link></li>
              <li><Link onClick={() => setToggleMenu(false)} to="/dadsrecords">Dads Records</Link></li>
              <li><Link onClick={() => setToggleMenu(false)} to="/library">Library</Link></li>
              <li><Link onClick={() => setToggleMenu(false)} to="/grandmotherspans">Grandmother's Pans</Link></li>
              {/* <li><Link onClick={() => setToggleMenu(false)} to="/contact">Contact</Link></li> */}
              
            </ul>
          </div>
        )}
        <div className="app__navbar-smallscreen-logo">
        <Link to="/"><img src={images.logo} alt="app__logo" /></Link>
        </div>
        <div className="app__navbar-smallscreen-search">
        <SearchDropDownMenu />
        </div>
        
        

      </div>
    </nav>
  );
};

export default Navbar;