import { captures, happenings,archives,speaks } from '../constants';


export function createSearchDataList() {
    const searchNamesList = [];

    //captures

    captures.pictures.forEach((e) => {
  
      const name = e.photographer_name;
      const detailsPageLink = e.details_page_link;
      const contentType = "picture"; 
      
      searchNamesList.push({ name, detailsPageLink, contentType });
    });

    captures.videos.forEach((e) => {
  
        const name = e.video_name;
        const detailsPageLink = e.details_page_link;
        const contentType = "video";
        
        searchNamesList.push({ name, detailsPageLink, contentType });
      });

      captures.sounds.forEach((e) => {
  
        const name = e.sound_name;
        const detailsPageLink = e.details_page_link;
        const contentType = "sound";
        
        searchNamesList.push({ name, detailsPageLink , contentType });
      });

      //archives
      archives.archives.forEach((e) => {
  
        const name = e.archive_name;
        const detailsPageLink = e.details_page_link;
        
        searchNamesList.push({ name, detailsPageLink });
      });

      //happenings
      happenings.happenings.forEach((e) => {
  
        const name = e.event_name;
        const detailsPageLink = e.details_page_link;
        
        searchNamesList.push({ name, detailsPageLink });
      });

      //speaks
      speaks.speaks.forEach((e) => {
  
        const name = e.speaks_name;
        const detailsPageLink = e.details_page_link;
        
        searchNamesList.push({ name, detailsPageLink });
      });

      searchNamesList.push({ name: 'Map', detailsPageLink:'/map' },{ name: 'Book', detailsPageLink:'/book' },{ name: 'Attire', detailsPageLink:'/attire' },{ name: 'Archives', detailsPageLink:'/archives' },{ name: 'Captures', detailsPageLink:'/captures' },{ name: 'Happenings', detailsPageLink:'/happenings' },{ name: 'Speaks', detailsPageLink:'/speaks' },{ name: 'The Bishopsgate Institute', detailsPageLink:'/archives' });
      
  
    // console.log(searchNamesList);

    return searchNamesList;
  }