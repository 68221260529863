import axios from 'axios';

const API_BASE_URL = 'https://api.thecockneysikh.com/portraits';


// GET all portrait photographers
export const fetchPortraits = async () => {
  try {
    const response = await axios.get('https://api.thecockneysikh.com/portraits/');
    return response.data;
  } catch (error) {
    throw error;
  }
};


// GET one portrait photographer by id
export const fetchPortraitById = async (id) => {
  try {
      const response = await axios.get(`https://api.thecockneysikh.com/portraits/${id}/`);
      return response.data;
  } catch (error) {
      throw error;
  }
};

// GET one portrait photographer by id
export const deletePortraitById = async (id) => {
  try {
      const response = await axios.delete(`https://api.thecockneysikh.com/portraits/${id}/`);
      return response.data;
  } catch (error) {
      throw error;
  }
};




// POST create new portrait
export const savePortrait  = async (formData) => {
    try {
      const response = await axios.post(
        'https://api.thecockneysikh.com/portraits/',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };


  // PUT update existing portrait
export const updatePortrait  = async (formData,id ) => {
  console.log(id)
    try {
      const response = await axios.put(
        `https://api.thecockneysikh.com/portraits/${id}/`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

/////////////////////

// GET all pictures of portrait photographers
export const fetchPortraitImages = async (id) => {
  try {
      const response = await axios.get(`https://api.thecockneysikh.com/portraits/${id}/images`);
      return response.data;
  } catch (error) {
      throw error;
  }
};


/// add images




// GET one portrait photographer by id
export const fetchPortraitImagesById = async (id) => {
  try {
      const response = await axios.get(`https://api.thecockneysikh.com/portraits/images/${id}`);
      return response.data;
  } catch (error) {
      throw error;
  }
};


export const deletePortraitImagesById = async (id) => {
  try {
      const response = await axios.delete(`https://api.thecockneysikh.com/portraits/images/${id}`);
      return response.data;
  } catch (error) {
      throw error;
  }
};


// PUT update existing radio
export const updatePortraitImages  = async (formData,id ) => {
  console.log(id)
    try {
      const response = await axios.put(
        `https://api.thecockneysikh.com/portraits/images/${id}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };


// POST create new radio
export const savePortraitImages  = async (formData,id) => {
  try {
    const response = await axios.post(
      `https://api.thecockneysikh.com/portraits/${id}/images`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
