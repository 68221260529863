import React, { useState } from 'react';
import './SearchDropDownMenu.css';
import { Link } from 'react-router-dom';
import images from '../../constants/images';
import { Searchbar, SearchResults } from '../../components';



const SearchDropDownMenu = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleMouseEnter = () => {
    setIsOpen(true);
  };

  const handleMouseLeave = () => {
    setIsOpen(false);
  };

  const handleItemClick = (item) => {
    // console.log(`You selected: ${item}`);
    // Implement the logic for what to do when an item is selected
    // For example, you can close the searchdropdown, update a state, etc.
  };

  const [results, setResults] = useState([]);

  return (
    <div className="searchdropdown" onMouseLeave={handleMouseLeave}>
      {/* <button
        className="searchdropdown-toggle"
        onMouseEnter={handleMouseEnter}
        onClick={() => setIsOpen(!isOpen)}
      >
        Open searchdropdown
      </button> */}

      <div className="search-icon searchdropdown-toggle" onMouseEnter={handleMouseEnter} onClick={() => setIsOpen(!isOpen)}>
        <img src={images.navsearchicon} alt="search-icon" />
        </div>
      {isOpen && (
        <ul className="searchdropdown-menu p__cormorant" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
          {/* <li onClick={() => handleItemClick('Book')}> */}
          <Searchbar setResults={setResults}/>
             <SearchResults results={results}/>

          {/* </li> */}
         
        </ul>
      )}
    </div>
  );
};

export default SearchDropDownMenu;
