import React, { useState, useEffect } from 'react';

import { SubHeading,Eventbox,Searchbar, Videobox,Happeningsbox } from '../../components';
import { FaInstagram, RxInstagramLogo } from 'react-icons/fa';

import { images, speaks } from '../../constants';
import './Speaks.css';
import { Link } from 'react-router-dom';
import { fetchSpeaks} from '../../services/speaks'

 const Speaks = () =>{ 


  const [radios, setRadios] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        const data = await fetchSpeaks();
        // setArchives(data);
        console.log(data)
        setRadios(data)
      } catch (error) {
        // Handle error
      }
    }

    fetchData();
  }, []); 

  
  const content = <div>
 {radios && radios.length > 0 && radios.reverse().map((v, index) => (
        // console.log(v)
        <Happeningsbox
          key={v.id}
          id={v.id}
          display_img={v.display_img}
          name={v.heading}
          description={v.description}
          website_url={v.booking_url}
          details_page_link={v.details_page_link}
          tagline={v.subheading}
          contentType={'img'} 
        />
      ))}
</div>

  return (

    <div className='app__speaks'>
      <div className='app__speaks-header'>
          <div className='app__speaks-header-img'>
          <img src="https://storage.googleapis.com/thecockneysikh-storage/speaks/speakshead.jpg" alt="archives" />
          </div>

          <div className='app__speaks-header-content'>
   
          <div className="app__speaks-header-paragraph">
              <p className="font_style_italic p__cormorant ">Suresh Singh speaks with his special guests on a number of topics including Love, East London, Architecture, Music and others. </p>
          </div>

          <div className="app__speaks-header-tagline">
              <p className="font_style_italic">Podcast hosted by Suresh Singh</p>
          </div>

          </div>

      </div>
    {/* <Searchbar/> */}

    
    
   <div className="app__speaks-content">
   {content}
    
   </div>


    </div>
);
  }

export default Speaks;