import React, { useState, useEffect } from 'react';
import { FaInstagram, RxInstagramLogo } from 'react-icons/fa';
import { images } from '../../constants';
import './GrandmothersPans.css';
import { Link } from 'react-router-dom';
import { fetchGrandMothersPans} from '../../services/grandmotherspans'

const GrandmothersPans = ({  }) => {
  
  const [radios, setRadios] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        const data = await fetchGrandMothersPans();
        // setArchives(data);
        console.log(data)
        setRadios(data)
      } catch (error) {
        // Handle error
      }
    }

    fetchData();
  }, []); 
  
  
  
  return (
  // console.log(contentType)
  <div className="app__gallery">

{radios && radios.length > 0 && radios.reverse().map((v, index) => (
            
            <div className='app__gallery-box'>
            <div className='app__gallery-img' key={index}>
                {v.type === 'image' ? (
                <img src={v.url} height="300px" width="300px" alt="" />
                ) : v.type === 'video' ? (
                <video src={v.url} height="300px" width="300px" controls></video>
                ) : null}
            </div>
            <p className='p__cormorant'>{v.heading}</p>
            <p className='p__cormorant-small'>{v.subheading}</p>
                  
            </div>
            
            ))} 
    


{/* <div className='app__gallery-img'>
    <iframe width="300" height="300"  src='https://storage.googleapis.com/thecockneysikh-storage/grandmotherspans/video.mp4' frameborder="0" ></iframe>
    </div>
    

        
<div className='app__gallery-img'>   
    <img src='https://storage.googleapis.com/thecockneysikh-storage/grandmotherspans/12.jpg' height="300px" width="300px"  alt=""/>
    </div>
    
   
    <div className='app__gallery-img'>
    <img src="https://storage.googleapis.com/thecockneysikh-storage/grandmotherspans/1.jpg" height="300px" width="300px" alt=""/>
    </div>

    
    <div className='app__gallery-img'>   
    <img src='https://storage.googleapis.com/thecockneysikh-storage/grandmotherspans/2.jpg' height="300px" width="300px"  alt=""/>
    </div>

    <div className='app__gallery-img'>
    <img src="https://storage.googleapis.com/thecockneysikh-storage/grandmotherspans/3.jpg" height="300px" width="300px" alt=""/>
    </div>

    
    <div className='app__gallery-img'>   
    <img src='https://storage.googleapis.com/thecockneysikh-storage/grandmotherspans/3.jpg' height="300px" width="300px"  alt=""/>
    </div>


    <div className='app__gallery-img'>
    <img src="https://storage.googleapis.com/thecockneysikh-storage/grandmotherspans/4.jpg" height="300px" width="300px" alt=""/>
    </div>

    
    <div className='app__gallery-img'>   
    <img src='https://storage.googleapis.com/thecockneysikh-storage/grandmotherspans/5.jpg' height="300px" width="300px"  alt=""/>
    </div>

    <div className='app__gallery-img'>
    <img src="https://storage.googleapis.com/thecockneysikh-storage/grandmotherspans/6.jpg" height="300px" width="300px" alt=""/>
    </div>

    
    <div className='app__gallery-img'>   
    <img src='https://storage.googleapis.com/thecockneysikh-storage/grandmotherspans/7.jpg' height="300px" width="300px"  alt=""/>
    </div>

    <div className='app__gallery-img'>
    <img src="https://storage.googleapis.com/thecockneysikh-storage/grandmotherspans/8.jpg" height="300px" width="300px" alt=""/>
    </div>

    
    <div className='app__gallery-img'>   
    <img src='https://storage.googleapis.com/thecockneysikh-storage/grandmotherspans/9.jpg' height="300px" width="300px"  alt=""/>
    </div>

    <div className='app__gallery-img'>   
    <img src='https://storage.googleapis.com/thecockneysikh-storage/grandmotherspans/10.jpg' height="300px" width="300px"  alt=""/>
    </div>

    <div className='app__gallery-img'>
    <img src="https://storage.googleapis.com/thecockneysikh-storage/grandmotherspans/11.jpg" height="300px" width="300px" alt=""/>
    </div> */}



    
  
</div>
    
    



   
)};

export default GrandmothersPans;