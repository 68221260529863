import axios from 'axios';

const API_BASE_URL = 'https://api.thecockneysikh.com/radios';


// GET all portrait photographers
export const fetchRadios = async () => {
  try {
    const response = await axios.get('https://api.thecockneysikh.com/radios/');
    return response.data;
  } catch (error) {
    throw error;
  }
};

// GET one portrait photographer by id
export const deleteRadioById = async (id) => {
  try {
      const response = await axios.delete(`https://api.thecockneysikh.com/radios/${id}/`);
      return response.data;
  } catch (error) {
      throw error;
  }
};


// POST create new radio
export const saveRadio  = async (formData) => {
  try {
    const response = await axios.post(
      'https://api.thecockneysikh.com/radios/',
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

  // PUT update existing radio
  export const updateRadio  = async (formData,id ) => {
    console.log(id)
      try {
        const response = await axios.put(
          `https://api.thecockneysikh.com/radios/${id}/`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    };
  


// GET one portrait photographer by id
export const fetchRadioById = async (id) => {
  try {
      const response = await axios.get(`https://api.thecockneysikh.com/radios/${id}/`);
      return response.data;
  } catch (error) {
      throw error;
  }
};



/////////////


// GET all pictures of portrait photographers
export const fetchRadioAudios = async (id) => {
  try {
      const response = await axios.get(`https://api.thecockneysikh.com/radios/${id}/audios`);
      return response.data;
  } catch (error) {
      throw error;
  }
};


/// add audios




// GET one portrait photographer by id
export const fetchRadioAudiosById = async (id) => {
  try {
      const response = await axios.get(`https://api.thecockneysikh.com/radios/audios/${id}`);
      return response.data;
  } catch (error) {
      throw error;
  }
};


export const deleteRadioAudiosById = async (id) => {
  try {
      const response = await axios.delete(`https://api.thecockneysikh.com/radios/audios/${id}`);
      return response.data;
  } catch (error) {
      throw error;
  }
};


// PUT update existing radio
export const updateRadioAudios  = async (formData,id ) => {
  console.log(id)
    try {
      const response = await axios.put(
        `https://api.thecockneysikh.com/radios/audios/${id}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };


// POST create new radio
export const saveRadioAudios  = async (formData,id) => {
  try {
    const response = await axios.post(
      `https://api.thecockneysikh.com/radios/${id}/audios`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
