import React from 'react';
import { FaInstagram, RxInstagramLogo } from 'react-icons/fa';
import { images } from '../../constants';
import './Listbox.css';
import { Link } from 'react-router-dom';

const Listbox = ({ id,display_img, name, description, insta_url, website_url, details_page_link, img_urls, contentType }) => (
  <div className="app__listbox">

    
    <div className='app__listbox-img'>
    <img src={display_img} alt="nancy" />
    </div>

    <div className='app__listbox-content'>
    
        <div className="app__listbox-head">
        <p className="headtext__cormorant font_style_italic"><Link to="/PortraitsDetails" state={{ id:id }}>{name}</Link></p>
        </div>   

        <div className="app__listbox-paragraph">
            <p className="font_style_italic p__cormorant ">{description}</p>
        </div>

        <div className='app__listbox-action'>
            {/* <div className='app__listbox__button'>
            <button className='custom__button'><a href={website_url}>Website</a></button>
            </div>
            <div className='app__listbox__icon'>
            <a href={insta_url}><FaInstagram/>
            </a>
                
            </div> */}

        </div>
    </div>


  </div>
);

export default Listbox;