import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { SubpageHeader, Listbox, Videobox, Searchbar } from '../../components';
import { images,captures } from '../../constants';
import './MusicDetails.css';
import { Link } from 'react-router-dom';
import { fetchMusicById,fetchMusicAudios} from '../../services/musics';



const MusicDetails = () => {
  const location = useLocation();
  const { contentType, id } = location.state;
  
  const [radios, setRadios] = useState([]);
  const [videos, setVideos] = useState([]);




  useEffect(() => {

    async function fetchData() {
      try {
        const data = await fetchMusicById(id);
        // setArchives(data);
        console.log(data)
        setRadios(data)
      } catch (error) {
        // Handle error
      }
    }

    async function fetchVideoData() {
      try {
        const data = await fetchMusicAudios(id);
        setVideos(data);
        console.log(data)
      } catch (error) {
        // Handle error
      }


    }
    

    fetchData();
    fetchVideoData();
  }, []);



  
  // Define content based on the linkName
  let content = null;

   {
    
  
    content = <div>
         <SubpageHeader
        key={radios.id}
        name={radios.name}
        description={radios.description}
        insta_url={radios.instagram_url}
        website_url={radios.website_url}
        
       
      />
    
      <div className='app__capturesdetails'>
      
      {videos && videos.length > 0 && videos.reverse().map((video, index) => (
        // console.log(video);
          <div>
       
          <Videobox
          key={video.id}
          display_video={video.image}
          name={video.name}
          description={video.description}   
          contentType={'img'}  
          audio_url = {video.url}                    
        />
           
      </div>  
          
      
          ))}
    
    
    
    </div>
  </div>



  }


  return (
    <div className="">
      {content}
    </div>
  );
};



export default MusicDetails;