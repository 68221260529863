import React, { useState, useEffect } from 'react';

import { SubpageHeaderImage } from '../../components';
import { images } from '../../constants';
import './Attire.css';
import { Link } from 'react-router-dom';
import { fetchAttireById,fetchAttireMedia} from '../../services/attire';

 const Attire = () => {

    const [attire, setAttire] = useState([]);
    const [attiremedia, setAttireMedia] = useState([]);
    
    useEffect(() => {
        async function fetchData() {
          try {
            const data = await fetchAttireById(1);
            // setArchives(data);
            console.log(data)
            setAttire(data)
         
    
    
          } catch (error) {
            // Handle error
          }
        }
    
        async function fetchMediaData() {
            try {
              const data = await fetchAttireMedia();
              // setArchives(data);
              console.log(data)
              setAttireMedia(data)
            } catch (error) {
              // Handle error
            }
          }
    
        fetchData();
        fetchMediaData();
      }, []);



    
return (
  <div className="">
    <div className="app__subpageheaderimage">
    <img src={attire.header_img}/>
    </div>

    <div className="app__archivesdetails-videoholder"> 
        
        {/* <video width="100%" controls autoplay>
            <source src={attire.video} type="video/mp4"/>
            </video> */}
        <video src={attire.video} width="100%" controls></video>
            {/* <iframe width="100%" height="auto" src={attire.video}/> */}

            <div className="app__attire-paragraph">
            <p className="font_style_italic p__cormorant ">{attire.video_caption}</p>
        </div>
        
  </div>

    <div className='app__attire'>
       

        <div className="app__attire-img ">
        <img src={attire.display_img} alt="Attire" />
        </div>

        <div className='app__attire-content'>
    
            <div className="app__attire-head">
            <p className="headtext__cormorant font_style_italic">{attire.heading}</p>
            </div>   

            <div className="app__attire-paragraph">
                <p className="font_style_italic p__cormorant ">{attire.subheading}</p>
            </div>

            <div className="app__attire-paragraph">
                <p className="font_style_italic p__cormorant ">{attire.description}</p>
            </div>

            {/* <div className='app__attire-action'>
                <div className='app__attire__button'>
                <button className='custom__button'>Website</button>
                </div>


            </div> */}
        </div>

 



    </div>

    <div className="app__gallery">

        {attiremedia && attiremedia.length > 0 && attiremedia.reverse().map((v, index) => (
                
            <div className='app__gallery-box'>
            <div className='app__gallery-img' key={index}>
                {v.type === 'image' ? (
                <img src={v.image} height="300px" width="300px" alt="" />
                ) : v.type === 'video' ? (
                <video src={v.image} height="300px" width="300px" controls></video>
                ) : null}
            </div>
            
            <p className='p__cormorant'>{v.heading}</p>
            <p className='p__cormorant-small'><a href={v.subheading_url}>{v.subheading}</a></p>

            </div>
            
            ))} 

    </div>

    






  </div>
)};

export default Attire;