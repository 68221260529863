import React from 'react';
import { FaInstagram, RxInstagramLogo } from 'react-icons/fa';
import { images } from '../../constants';
import './Eventbox.css';
import { Link } from 'react-router-dom';

const Eventbox = ({ id,display_img, name, tagline, description, website_url, details_page_link,contentType, header_img_url}) => (
  // console.log(contentType)
  <div className="app__eventbox">

    
    <div className='app__eventbox-img'>
    <img src={display_img} alt={name} />
    {/* <p>Image Credits: Beverley Onyangunga</p> */}
    {/* <figure>
        <img src={display_img} alt="Image Credits: Beverley Onyangunga"/>
        <figcaption class="caption"><i>Image Credits: Beverley Onyangunga</i></figcaption>
        
    </figure> */}

    </div>

    <div className='app__eventbox-content'>
    
        {/* <div className="app__eventbox-head">
        <p className="headtext__cormorant font_style_italic"><Link to={details_page_link} state={{ contentType: {contentType,name} }}>{name}</Link></p>
        </div>    */}
        {name ? (
      <div className="app__eventbox-head">
        <p className="headtext__cormorant font_style_italic">
          <Link to={details_page_link} state={{ contentType: contentType, id:id }}>
            {name}
          </Link>
        </p>
      </div>
    ) : null}

        <div className="app__eventbox-tagline">
            <p className="font_style_italic">{tagline}</p>
        </div>

        <div className="app__eventbox-paragraph">
            {/* <p className="font_style_italic p__cormorant " >{description}</p> */}
            <p className='font_style_italic p__cormorant' dangerouslySetInnerHTML={{ __html: description }}/>
        </div>

        <div className='app__eventbox-action'>
              {website_url !== null ? (
        <div className='app__eventbox__button'>
          <button className='custom__button'>
            <a href={website_url}>Website</a>
          </button>
        </div>
      ) : null}


        </div>
    </div>


  </div>
);

export default Eventbox;