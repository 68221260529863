import React from 'react';

import { SubpageHeader } from '../../components';
import { images } from '../../constants';
import './Book.css';
import { Link } from 'react-router-dom';

 const Book = () => { 

  let imageList= [];

  let images = [
    'https://storage.googleapis.com/thecockneysikhstorage/book/cover.png',
    'https://storage.googleapis.com/thecockneysikhstorage/book/2.png',
    'https://storage.googleapis.com/thecockneysikhstorage/book/3.png',
    'https://storage.googleapis.com/thecockneysikhstorage/book/4.png',
    'https://storage.googleapis.com/thecockneysikhstorage/book/5.png',
    'https://storage.googleapis.com/thecockneysikhstorage/book/6.png',
    'https://storage.googleapis.com/thecockneysikhstorage/book/7.png',
    'https://storage.googleapis.com/thecockneysikhstorage/book/8.png',
    'https://storage.googleapis.com/thecockneysikhstorage/book/9.png',
    'https://storage.googleapis.com/thecockneysikhstorage/book/10.png',
    'https://storage.googleapis.com/thecockneysikhstorage/book/11.png',
    'https://storage.googleapis.com/thecockneysikhstorage/book/12.png',
    

  ];

  images.forEach((image, index) => {
    imageList.push(<img src={image} alt="book" />);
  });
  
  
  return(
  <div className="app__book">
    
    <div className="app__book-header">
        <h1 className="headtext__cormorant"><b>A Modest Living</b></h1>
        
        
    </div>

    <div className='app__book-button'>
        <button className='custom__button'><a href="https://www.waterstones.com/book/a-modest-living-memoirs-of-a-cockney-sikh/suresh-singh/9780995740136">Buy Now</a></button>
    </div>

    <div className="app__book-paragraph">
    <p  className='p__cormorant' class="font-semibold" ><i>'Punk fans may remember Suresh Singh as the drummer from the band Spizzenergi, who toured with Siouxsie and the Banshees. In this charming book about his Sikh immigrant parents and his childhood in Spitalfields, east London, Singh proves himself to be a candid writer and a canny curator of material that crosses family biography with postwar cultural history. His youth is filled first with National Front racism, then punk music and rebellion. Photographs and illustrations reflect the changing faces of his family and the evolution of one corner of the capital. Combined with Singh's words and occasional recipes for Indian dishes, they are a timely reminder of all that modern Britishness encompasses.'</i>
<br/>
The Guardian-The Observer Books-Arifa Akbar</p>
    </div>


 
    <div className="app__book-img">
      {/* <img src={images.book} alt="book" /> */}
      {imageList}
    </div>

    


  </div>
)};

export default Book;