import React, { useState, useEffect } from 'react';

// import { useLocation } from 'react-router-dom';
import { SubHeading,Happeningsbox, Searchbar } from '../../components';
import { FaInstagram, RxInstagramLogo } from 'react-icons/fa';

import { images, happenings } from '../../constants';
import './Happenings.css';
import { Link } from 'react-router-dom';
import { fetchHappenings} from '../../services/happenings'


 const Happenings = () =>{


  const [radios, setRadios] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        const data = await fetchHappenings();
        // setArchives(data);
        console.log(data)
        setRadios(data)
      } catch (error) {
        // Handle error
      }
    }

    fetchData();
  }, []); 

 
 return (
  <div className="">

    <div className='app__happenings'>
    {/* <Searchbar/> */}

    {radios && radios.length > 0 && radios.reverse().map((v, index) => (
        // console.log(v)
        <Happeningsbox
          key={v.id}
          id={v.id}
          display_img={v.display_img}
          name={v.name}
          description={v.description}
          website_url={v.booking_url}
          details_page_link={v.details_page_link}
          tagline={v.subheading}
          
        />
      ))}

    </div>
   

  </div>
);
  }
export default Happenings;