import axios from 'axios';


export const fetchHomepageImgById = async () => {
    try {
        const response = await axios.get(`https://api.thecockneysikh.com/homepageimg/1/`);
        return response.data;
    } catch (error) {
        throw error;
    }
  };
  


  
  // PUT update existing radio
  export const updateHomepageImg  = async (formData) => {
    // console.log(id)
      try {
        const response = await axios.put(
          `https://api.thecockneysikh.com/homepageimg/1/`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    };