import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { SubpageHeader, ImageOrVideoPopup } from '../../components';
import { images, archives } from '../../constants';
import './ArchivesDetails.css';
import { Link } from 'react-router-dom';
import { fetchArchiveById,fetchArchiveImages} from '../../services/archives';




const ArchivesDetails = () => {

  const location = useLocation();
  const {  id } = location.state;

  const [radios, setRadios] = useState([]);
  const [videos, setVideos] = useState([]);

  const [showPopup, setShowPopup] = useState(false);

  const handlePopupToggle = () => {
    setShowPopup(!showPopup);
  };




  useEffect(() => {

    async function fetchData() {
      try {
        const data = await fetchArchiveById(id);
        // setArchives(data);
        console.log(data)
        setRadios(data)
      } catch (error) {
        // Handle error
      }
    }

    async function fetchVideoData() {
      try {
        const data = await fetchArchiveImages(id);
        setVideos(data);
        console.log(data)
      } catch (error) {
        // Handle error
      }


    }
    

    fetchData();
    fetchVideoData();
  }, []);



// console.log(filteredArchives) 


return(


  <div className="">

      
    <div className='app__archivesdetails'>


{/* {videos.map((a, index) => (
  <div className="app__archivesdetails-imgholder" key={index}>
    {a.type !== 'video' ? (
        <div className="app__archivesdetails-img" key={index}>
          <img src={a.image} alt="Captures" />
        </div>
    
    ) : (
      <div className="app__archivesdetails-videoholder"> 
      <iframe
        width="100%"
        height="387"
        src= {a.image}
        title="Clare O'Connell Explores the Archives - Lunchtime Concerts"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
      ></iframe>
      </div>
    )}
  </div>
))} */}

<div className="app__archivesdetails-imgholder">
{videos.reverse().map((a, index) => (
  <div>
    {a.type == 'image' ? (
        <div className="app__archivesdetails-img" key={index}>
          <img src={a.image} alt="Captures" onClick={handlePopupToggle}/>
        </div>
    
    ) : (
      <div className="app__archivesdetails-videoholder"> 
      <iframe
        width="100%"
        height="387"
        src= {a.image}
        title="Clare O'Connell Explores the Archives - Lunchtime Concerts"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
        onClick={handlePopupToggle}
      ></iframe>
      </div>
    )}
    </div>
 
))}
</div>


    



    
{/* {filteredArchives.map((a, index) => (
      <div className="app__archivesdetails-imgholder ">
            {a.img_urls.map((img, index) => (

            <div className="app__archivesdetails-img ">
            <img src={img} alt="Captures" />
            </div>
              
        ))}
        </div>
      ))} */}

        



    </div>




  </div>
);
    }

export default ArchivesDetails;