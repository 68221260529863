// MyComponent.js
import './MusicsForm.css'

import React, { useState, useEffect } from 'react';
import { images,radios } from '../../constants';
import { SubHeading, MusicFormDisplayItem, Searchbar, Eventbox } from '../../components';
import { fetchMusics,saveMusic} from '../../services/musics'; // Import the specific API function
import axios from 'axios';

import {useNavigate} from 'react-router-dom';

// const navigate = useNavigate();


function MusicsForm() {
    const navigate = useNavigate();
    
  const [editingIndex, setEditingIndex] = useState(null);
  const [formData, setFormData] = useState({
    archive_name: '',
    description: '',
    // Add more fields here
  });

  const [display_img, setDisplayImg] = useState(null);
  const [name, setName] = useState("")
  const [description, setDescription] = useState("")
  const [instagram_url, setInstagramUrl] = useState("")
  const [website_url, setWebsiteUrl] = useState("")
  // const [images, setImages] = useState("")
  // const [image_titles, setImageTitles] = useState("")

  const [radios, setRadios] = useState([]);


    // Handle file input change
    const handleFileChange = (e) => {
      setDisplayImg(e.target.files[0]);
    };

  const handleInputChange = event => {
    const { name, value } = event.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value,
    }));
  };
  

  
  // Handle form submission
  const saveData = async (e) => {
    e.preventDefault();
  
    // Create a FormData object to send the form data
    const formData = new FormData();
    if (display_img && display_img instanceof File) {
      // Generate a unique filename using a timestamp and the original file's extension
      const fileExtension = display_img.name.split('.').pop();
      const uniqueFilename = `${Date.now()}.${fileExtension}`;
  
      // Create a new File object with the unique filename
      const renamedFile = new File([display_img], uniqueFilename, {
        type: display_img.type,
      });
  
      // Append the renamed file to the formData
      formData.append('display_img', renamedFile);
    }
    formData.append('name', name);
    formData.append('description', description);
    formData.append('instagram_url', instagram_url);
    formData.append('website_url', website_url);
  
    console.log(formData);
  
    try {
      const responseData = await saveMusic(formData); // Call the submitFormData function
      console.log('Response:', responseData);
      // Handle success, e.g., display a success message or redirect
      alert('Success!');
    } catch (error) {
      console.error('Error:', error);
      // Handle error, e.g., display an error message
    }
  };
  
  


  useEffect(() => {
    async function fetchData() {
      try {
        const data = await fetchMusics();
        // setArchives(data);
        console.log(data)
        setRadios(data)
      } catch (error) {
        // Handle error
      }
    }

    fetchData();
  }, []);
  




  return (

    <div className='radios-form'>
      
      <div className='radios-form__add'>

      
      <h2 class="headtext__cormorant">Musics</h2>
      <p class="headtext__cormorant2">Add New Music</p>
      <br/>

      <form class="w-full max-w-sm">
      <div class="md:flex md:items-center mb-6">
        <div class="md:w-1/3">
          <label class="block text-gray-900 font-bold md:text-right mb-1 md:mb-0 pr-4" for="inline-full-name">
            Display Image
          </label>
        </div>
          <div class="md:w-2/3">
            <input
            class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" id="inline-full-name"
            type="file"
            name="display_img"
            accept="image/*"
            onChange={handleFileChange}
            />
          </div>
        </div>


        <div class="md:flex md:items-center mb-6">
          <div class="md:w-1/3">
            <label class="block text-gray-900 font-bold md:text-right mb-1 md:mb-0 pr-4" for="inline-full-name">
            Name:
            </label>
          </div>
            <div class="md:w-2/3">
              <input
              class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" id="inline-full-name"
              type="text"
              name="photographer_name"
              value={name}
              onChange={(e)=>setName(e.target.value)}
              />
            </div>
        </div>


        <div class="md:flex md:items-center mb-6">
        <div class="md:w-1/3">
          <label class="block text-gray-900 font-bold md:text-right mb-1 md:mb-0 pr-4" for="inline-full-name">
          Description:
          </label>
        </div>
        <div class="md:w-2/3">
            <textarea
            class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" id="inline-full-name"
            type="text"
            name="description"
            value={description}
            onChange={(e)=>setDescription(e.target.value)}
            />
          </div>
        </div>

        
        <div class="md:flex md:items-center mb-6">
        <div class="md:w-1/3">
          <label class="block text-gray-900 font-bold md:text-right mb-1 md:mb-0 pr-4" for="inline-full-name">
          Instagram Url:
          </label>
        </div>
          <div class="md:w-2/3">
            <input
            class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" 
            type="text"
            name="instagram_url"
            value={instagram_url}
            onChange={(e)=>setInstagramUrl(e.target.value)}
            />
          </div>
        </div>

        <div class="md:flex md:items-center mb-6">
        <div class="md:w-1/3">
          <label class="block text-gray-900 font-bold md:text-right mb-1 md:mb-0 pr-4" for="inline-full-name">
          Website Url:
          </label>
        </div>
          <div class="md:w-2/3">
            <input
            class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" 
            type="text"
            name="website_url"
            value={website_url}
            onChange={(e)=>setWebsiteUrl(e.target.value)}
            />
          </div>
        </div>


        <div class="md:flex md:items-center">
          <div class="md:w-1/3"></div>
          <div class="md:w-2/3">
            <button class="shadow bg-orange-400 hover:bg-orange-500 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded" 
            type="submit"
            onClick={saveData}
            >
            Submit
            </button>
          </div>
        </div>
        
      </form>

      </div>

      <p class="headtext__cormorant2" style={{padding: 1+'rem'}}>All Musics</p>
      <div className='radios-form__show'>
        {/* <div className='app__capturelist'> */}
        {/* <Searchbar/> */}
        {radios.map((photographer, index) => (
          
          <MusicFormDisplayItem
            id={photographer.id}
            display_img={photographer.display_img}
            name={photographer.name}
            // description={photographer.description}
            insta_url={photographer.insta_url}
            website_url={photographer.website_url}
            details_page_link={photographer.details_page_link}
            // img_urls={photographer.img_urls}

          />
        ))}
      {/* </div> */}

      </div>
  
       

      
    </div>
  );
}

export default MusicsForm;




