import images from './images';

const speaks = [
    {
      display_img: 'https://storage.googleapis.com/thecockneysikh-storage/speaks/speaks1.jpg',
      speaks_name: "Metropolitan Workshop Podcast - 'Reshaped'",
      description: 'An architectural conversation with the Metropolitan Workshop.',
      subheading: "Suresh Singh's Tale of a Cockney Sikh",
      spotify_url: "https://open.spotify.com/embed/episode/0pLcjYLeV8zmvgV9AUFwei?utm_source=generator",
      social_media_urls:['https://www.instagram.com/met_work/'],
      
      details_page_link:'/speaksdetails',
  
    },
   
  ];

export default { speaks };