import React, { useState } from 'react';
import './DropdownMenu.css';
import { Link } from 'react-router-dom';
import images from '../../constants/images';



const DropdownMenu = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleMouseEnter = () => {
    setIsOpen(true);
  };

  const handleMouseLeave = () => {
    setIsOpen(false);
  };

  const handleItemClick = (item) => {
    // console.log(`You selected: ${item}`);
    // Implement the logic for what to do when an item is selected
    // For example, you can close the dropdown, update a state, etc.
  };

  return (
    <div className="dropdown" onMouseLeave={handleMouseLeave}>
      {/* <button
        className="dropdown-toggle"
        onMouseEnter={handleMouseEnter}
        onClick={() => setIsOpen(!isOpen)}
      >
        Open Dropdown
      </button> */}

      <div className="app__navbar-logo dropdown-toggle" onMouseEnter={handleMouseEnter} onClick={() => setIsOpen(!isOpen)}>
        <Link to="/"><img src={images.logo} alt="app__logo" /></Link>
        </div>
      {isOpen && (
        <ul className="dropdown-menu p__cormorant" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
          {/* <li onClick={() => handleItemClick('Book')}><Link to="/book">Book</Link></li> */}
          <li onClick={() => handleItemClick('Map')}><Link to="/map">Map</Link></li>
          <li onClick={() => handleItemClick("Garden")}><Link to="/garden">Jagir Kaur ji's Organic Garden</Link></li>
          {/* <li onClick={() => handleItemClick('Contact')}><Link to="/book">Contact</Link></li> */}
          <li onClick={() => handleItemClick('Attire')}><Link to="/attire">Attire</Link></li>
          <li onClick={() => handleItemClick('DadsRecords')}><Link to="/dadsrecords">Dad's Records</Link></li>
          <li onClick={() => handleItemClick('Library')}><Link to="/library">Library</Link></li>
          <li onClick={() => handleItemClick('GrandmothersPans')}><Link to="/grandmotherspans">Grandmother's Pans</Link></li>
          
        </ul>
        
      )}
    </div>
  );
};

export default DropdownMenu;
