import React, { useState, useEffect } from 'react';
import { FaInstagram,FaYoutube, RxInstagramLogo } from 'react-icons/fa';
import { images } from '../../constants';
import './SpeaksDetails.css';
import { Link } from 'react-router-dom';
import { SpotifyEmbed } from '../../components';
import { useLocation } from 'react-router-dom';
import { fetchSpeaksById} from '../../services/speaks'




const SpeaksDetails = ({ }) => {
  
  const location = useLocation();
  const { contentType, id } = location.state;

  const [radios, setRadios] = useState([]);
  const [videos, setVideos] = useState([]);


  useEffect(() => {

    async function fetchData() {
      try {
        const data = await fetchSpeaksById(id);
        // setArchives(data);
        console.log(data)
        setRadios(data)
      } catch (error) {
        // Handle error
      }
    }



    fetchData();
    // fetchVideoData();
  }, []);


  
  return (
  
  <div className="app__speaksdetails">

   
          
       
    <div className='app__speaksdetails-img'>
    <img src={radios.display_img} alt="" />
    </div>

    <div className='app__speaksdetails-content'>
    
        <div className="app__speaksdetails-head">
                </div>   

        <div className="app__speaksdetails-tagline">
          </div>
        <br/>
        <div className="app__speaksdetails-spotify">
            <SpotifyEmbed/>
        </div>

        <div className="app__speaksdetails-paragraph">
            <p className="font_style_italic p__cormorant ">Suresh Singh Trained As A Carpenter Before Studying Architecture At The Polytechnic Of Central London And The Bartlett. Around Them Over The Last 60 Years. ‘It’s Not Just About Steel And Glass’ He Argues, ‘It’s About The Interaction Of People’.</p>
        </div>

        <div className='app__speaksdetails-action'>
            

        <div className='app__happeningsbox__button'>
            {/* <button className='custom__button'><a href="https://metwork.co.uk/podcast/a-spitalfields-life-suresh-singhs-tales-of-a-cockney-sikh/">Website</a></button>
            </div> */}
            <button className='custom__button'><a href={radios.speak_url}>Website</a></button>
            </div>

        </div>
    </div>


          
    
    {/* <div className='app__speaksdetails-img'>
    <img src={images.captures} alt="" />
    </div>

    <div className='app__speaksdetails-content'>
    
        <div className="app__speaksdetails-head">
                </div>   

        <div className="app__speaksdetails-tagline">
          </div>
        <br/>
        <div className="app__speaksdetails-spotify">
            <SpotifyEmbed/>
        </div>

        <div className="app__speaksdetails-paragraph">
            <p className="font_style_italic p__cormorant ">Suresh Singh trained as a carpenter before studying architecture at the Polytechnic of Central London and the Bartlett. Around them over the last 60 years. ‘It’s not just about steel and glass’ he argues, ‘it’s about the interaction of people’.</p>
        </div>

        <div className='app__speaksdetails-action'>
            

        <div className='app__happeningsbox__button'>
            <button className='custom__button'><a href="https://metwork.co.uk/podcast/a-spitalfields-life-suresh-singhs-tales-of-a-cockney-sikh/">Website</a></button>
            </div>

        </div>
    </div> */}


  </div>
)};

export default SpeaksDetails;