import React from 'react';
import { useLocation } from 'react-router-dom';
import { SubpageHeader, Listbox, Videobox, Searchbar } from '../../components';
import { images,captures } from '../../constants';
import './CapturesDetails.css';
import { Link } from 'react-router-dom';



const CapturesDetails = () => {
  // Use the useLocation() hook inside the component body
  const location = useLocation();
  const { contentType } = location.state;
  const { name } = location.state;
  // const { photographer_name } = location.state;
  // console.log(contentType);
  // console.log(name);
  // console.log(contentType.name)


  
  // Define content based on the linkName
  let content = null;

  switch (contentType) {
    case 'picture':
      const filteredPictures = captures.pictures.filter((photographer) =>
      photographer.photographer_name.toLowerCase().includes(name.toLowerCase())
      )

      content = <div>
      {filteredPictures.map((photographer, index) => (
        <SubpageHeader
          key={photographer.photographer_name + index}
          
          name={photographer.photographer_name}
          description={photographer.description}
          insta_url={photographer.insta_url}
          website_url={photographer.website_url}
          
          // img_urls={photographer.img_urls}
        />
        
      ))}
        <div className='app__capturesdetails'>
      {filteredPictures[0].img_urls.map((img, index) => (
            <div className="app__capturesdetails-img ">
            <img src={img} alt="Captures" />
            <p className='p__cormorant'>{filteredPictures[0].img_titles[index]} </p>
            </div>
            ))}
      
      </div>
    </div>

      break;


    case 'video':
      const filteredVideos = captures.videos.filter((video) =>
      video.video_name.toLowerCase().includes(name.toLowerCase())
      )
      console.log(filteredVideos)
      content = <div>
      {filteredVideos.map((v, index) => (
        <SubpageHeader
          key={v.video_name + index}
          name={v.video_name}
          description={v.description}
          insta_url={v.insta_url}
          website_url={v.website_url}
          
          // img_urls={photographer.img_urls}
        />
        
      ))}
      
        <div className='app__capturesdetails'>
        {/* <Searchbar/> */}
        {filteredVideos.map((video, index) => (
          // console.log(video);
            <div>
          {video.video_names.map((v, index) => (
            <Videobox
            key={v + index}
            display_video={video.video_urls[index]}
            name={video.video_names[index]}
            description={video.description}   
            contentType={contentType}                     
          />
          // console.log(v)
          
            ))}

        </div>  
            
        
            ))}
      
      
      </div>
    </div>
      break;


      case 'sound':
        
        const filteredSounds = captures.sounds.filter((sound) =>
        sound.sound_name.toLowerCase().includes(name.toLowerCase())
        )
        content = <div>
        {filteredSounds.map((v, index) => (
          <SubpageHeader
            key={v.sound_name + index}
            name={v.sound_name}
            description={v.description}
            insta_url={v.insta_url}
            website_url={v.website_url}
            
            // img_urls={photographer.img_urls}
          />
          
        ))}
        
          <div className='app__capturesdetails'>
          {/* <Searchbar/> */}
          {filteredSounds.map((sound, index) => (
            // console.log(video);
              <div>
            {sound.audio_names.map((v, index) => (
              <Videobox
              key={v + index}
              display_video={sound.img_urls[index]}
              name={sound.audio_names[index]}
              subheading = {sound.audio_subheadings[index]}
              description={sound.audio_descriptions[index]}
              audio_url={sound.audio_urls[index]} 
              contentType={'sound'}                       
            />
            // console.log(v)
            
              ))}
  
          </div>  
              
          
              ))}
        
        
        </div>
      </div>
        break;

    case 'article':
      const filteredArticles = captures.articles.filter((article) =>
      article.article_name.toLowerCase().includes(contentType.name.toLowerCase())
      );
      console.log(contentType.article_name)
      content = <div>
      {filteredArticles.map((v, index) => (
        <SubpageHeader
          key={v.article_name + index}
          name={v.article_name}
          description={v.short_description}
          
          website_url={v.website_url}
          
          // img_urls={photographer.img_urls}
        />
        
      ))};
      
        <div className='app__capturesdetails'>
        <Searchbar/>
        {filteredArticles.map((article, index) => (
          <div key={index} className='app__article'>
            <div className='app_article-head'>
              <div className='app__article-img'>
              <img src={article.display_img}/>
                </div>
              <div className='app__article-content'>
              <p className=" p__cormorant ">{article.description}</p>
              </div>
              </div>
        
          {article.img_urls.map((img, index) => (
           <img src={img} alt="article"/>
          
            ))};

        </div>  
            
        
            ))};
      
      
      </div>
    </div>
      break;
  }


  return (
    <div className="">
      {content}
    </div>
  );
};



export default CapturesDetails;