import React from 'react';
import { useLocation } from 'react-router-dom';
import { SubHeading, Listbox, Searchbar, Eventbox } from '../../components';
import { FaInstagram, RxInstagramLogo } from 'react-icons/fa';
import { images, captures } from '../../constants';
import './CapturesList.css';
import { Link } from 'react-router-dom';

const CapturesList = () => {
  // Use the useLocation() hook inside the component body
  const location = useLocation();
  const { contentType } = location.state;
  // console.log(contentType);
  
  // Define content based on the linkName
  let content = null;

  switch (contentType) {
    case 'picture':
      content = <div className='app__capturelist'>
      {/* <Searchbar/> */}
      {captures.pictures.map((photographer, index) => (
        <Listbox
          key={photographer.photographer_name + index}
          display_img={photographer.display_img}
          name={photographer.photographer_name}
          description={photographer.description}
          insta_url={photographer.insta_url}
          website_url={photographer.website_url}
          details_page_link={photographer.details_page_link}
          img_urls={photographer.img_urls}
          contentType={contentType}
        />
      ))}
    </div>
      break;

    case 'video':
      content = <div className='app__capturelist'>
      {/* <Searchbar/> */}
      {captures.videos.map((v, index) => (
        // console.log(v)
        <Eventbox
          key={v.video_name + index}
          display_img={v.display_img}
          name={v.video_name}
          description={v.description}
          website_url={v.website_url}
          contentType={contentType}
          details_page_link={v.details_page_link}
        />
      ))}
    </div>
      break;

      case 'sound':
        content = <div className='app__capturelist'>
        {/* <Searchbar/> */}
        {captures.sounds.map((v, index) => (
          // console.log(v)
          <Eventbox
            key={v.sound_name + index}
            display_img={v.display_img}
            name={v.sound_name}
            description={v.description}
            website_url={v.website_url}
            contentType={contentType}
            details_page_link={v.details_page_link}
          />
        ))}
      </div>
        break;


    case 'article':
      // console.log(captures.articles)
      content = <div className='app__capturelist'>
        {/* <Searchbar/> */}
        {captures.articles.map((v, index) => (
          // console.log(v)
          <Eventbox
            key={v.article_name + index}
            display_img={v.display_img}
            name={v.article_name}
            description={v.short_description}
            website_url={v.website_url}
            contentType={contentType}
            details_page_link={v.details_page_link}
          />
        ))}
      </div>
      break;

  }


  return (
    <div className="app__wrapper section__padding">
      {content}
    </div>
  );
};

export default CapturesList;




