
import React, { useState } from 'react';
import { FaInstagram, RxInstagramLogo } from 'react-icons/fa';
import { images } from '../../constants';
import './FormDisplayItem.css';
import { Link } from 'react-router-dom';
import {deletePortraitById} from '../../services/portraits';
import {PicturesForm} from '../'

const FormDisplayItem = ({ id, display_img, name, description, insta_url, website_url, details_page_link }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  console.log(id)
  // Function to toggle the expansion
  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  // Function to handle the "Edit" button click
  const handleEditClick = () => {
    // Expand the component when "Edit" is clicked
    setIsExpanded(true);
  };
  
    // Function to handle the "Delete" button click

     // Handle form submission
 const handleDeleteClick = async (e) => {
  e.preventDefault();


  try {
     await deletePortraitById(id); // Call the delete function

    // Handle success, e.g., display a success message or redirect
    alert('Success!');
  } catch (error) {
    console.error('Error:', error);
    // Handle error, e.g., display an error message
  }
};


  // Function to handle the "Close" button click
  const handleCloseClick = () => {
    // Close the expanded content
    setIsExpanded(false);
  };

  return (
    <div className={`app__formdisplayitem ${isExpanded ? 'expanded' : ''}`}>
      <div className='app__formdisplayitem-img'>
        <img src={display_img} alt="" />
      </div>

      <div className='app__formdisplayitem-content'>

        <div className="app__formdisplayitem-head">
          <p className="headtext__cormorant font_style_italic">
            {name}
          </p>
        </div>

        <div className="app__formdisplayitem-paragraph">
          <p className="font_style_italic p__cormorant ">{description}</p>
        </div>

        <div className='app__formdisplayitem-action'>
        {isExpanded ? (
            <button
              className="shadow bg-orange-400 hover:bg-orange-500 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded"
              onClick={handleCloseClick} // Call the handleCloseClick function
            >
              Close
            </button>
          ) : (
            <button
              className="shadow bg-orange-400 hover:bg-orange-500 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded"
              onClick={handleEditClick} // Call the handleEditClick function
            >
              Edit
            </button>
          )}

          <button className="shadow bg-orange-400 hover:bg-orange-500 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded ml-1.5" onClick={handleDeleteClick}>
            Delete
          </button>
        </div>

        {/* Add an expandable div for additional content */}
        {isExpanded && (
          <div className="app__formdisplayitem-details">
            {/* Add your additional details here */}
            {/* <h1>Hi there</h1> */}
            <PicturesForm 
            onClose={handleCloseClick}
            id={id}
            ></PicturesForm>
            {/* <button
              className="shadow bg-red-400 hover:bg-red-500 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded"
              onClick={handleCloseClick} // Call the handleCloseClick function
            >
              Save
            </button> */}
            
          </div>
        )}

      </div>
    </div>
  );
}

export default FormDisplayItem;
