// import React, { useState } from 'react';

// import './FormsHomepage.css';
// import { PortraitsForm,RadiosForm,MusicsForm,InPrintsForm,FilmsForm,DadsRecordsForm,GallerysForm,GardensForm,GrandMothersPansForm,HappeningsForm,LibrarysForm, SpeaksForm} from '../';
// import { AiFillPicture } from 'react-icons/ai';
// import { RiArchiveFill } from 'react-icons/ri';
// import { BiSolidRadio,BiSolidMoviePlay,BiSolidCalendarEvent } from 'react-icons/bi';
// import { SiApplemusic,SiApplepodcasts } from 'react-icons/si';
// import { GrGallery } from 'react-icons/gr';
// import { ImBook } from 'react-icons/im';
// import { GiThreeLeaves } from 'react-icons/gi';
// import { IoLibrary } from 'react-icons/io5';

// // import { SiApplemusic } from 'react-icons/si';
// // import { SiApplemusic } from 'react-icons/si';


// const FormsHomepage = () => {
//   const [activeTab, setActiveTab] = useState(0); // Initialize the active tab to the first tab

//   const handleTabClick = (index) => {
//     setActiveTab(index);
//   };

//   const tabs = [

//     // { label: <div><h1 className='headtext__cormorant flex__tabs back_effect'><span      className='icon-inline'><RiArchiveFill/></span>Archives</h1></div>,
//     //   content: <CapturesForm/>},
//     { label: <div><h1 className='subheadtext__cormorant flex__tabs back_effect'><span      className='icon-inline'><AiFillPicture/></span>Portraits</h1></div>,
//       content: <PortraitsForm/>},

//       { label: <div><h1 className='subheadtext__cormorant flex__tabs back_effect'><span      className='icon-inline'><BiSolidRadio/></span>Radio</h1></div>,
//       content: <RadiosForm/>},

//       { label: <div><h1 className='subheadtext__cormorant flex__tabs back_effect'><span      className='icon-inline'><SiApplemusic/></span>Music</h1></div>,
//       content: <MusicsForm/>},

//       { label: <div><h1 className='subheadtext__cormorant flex__tabs back_effect'><span      className='icon-inline'><BiSolidMoviePlay/></span>Films</h1></div>,
//       content: <FilmsForm/>},

//       { label: <div><h1 className='subheadtext__cormorant flex__tabs back_effect'><span      className='icon-inline'><GrGallery/></span>Gallery</h1></div>,
//       content: <GallerysForm/>},

//       { label: <div><h1 className='subheadtext__cormorant flex__tabs back_effect'><span      className='icon-inline'><GrGallery/></span>InPrint</h1></div>,
//       content: <InPrintsForm/>},

//       { label: <div><h1 className='subheadtext__cormorant flex__tabs back_effect'><span      className='icon-inline'><BiSolidCalendarEvent/></span>Happenings</h1></div>,
//       content: <HappeningsForm/>},

//       { label: <div><h1 className='subheadtext__cormorant flex__tabs back_effect'><span      className='icon-inline'><SiApplepodcasts/></span>Speaks</h1></div>,
//       content: <SpeaksForm/>},

//       { label: <div><h1 className='subheadtext__cormorant flex__tabs back_effect'><span      className='icon-inline'><ImBook/></span>Book</h1></div>,
//       content: <GallerysForm/>},

//       { label: <div><h1 className='subheadtext__cormorant flex__tabs back_effect'><span      className='icon-inline'><GiThreeLeaves/></span>Garden</h1></div>,
//       content: <GardensForm/>},

//       { label: <div><h1 className='subheadtext__cormorant flex__tabs back_effect'><span      className='icon-inline'><IoLibrary/></span>Library</h1></div>,
//       content: <LibrarysForm/>},

//       { label: <div><h1 className='subheadtext__cormorant flex__tabs back_effect'><span      className='icon-inline'><AiFillPicture/></span>Grand Mothers Pans</h1></div>,
//       content: <GrandMothersPansForm/>},

//       { label: <div><h1 className='subheadtext__cormorant flex__tabs back_effect'><span      className='icon-inline'><AiFillPicture/></span>Happenings</h1></div>,
//       content: <HappeningsForm/>},
//   ];

//   return (
//     <div className="app__formshomepage">
//       <div className="app__formshomepage-tablist">
//         {tabs.map((tab, index) => (
//           <div
//             key={index}
//             className={`tab ${index === activeTab ? 'active-tab' : ''}`}
//             onClick={() => handleTabClick(index)}
//           >
//             <div className='app__formshomepage-tab'>{tab.label}</div>
            
//           </div>
//         ))}
//       </div>
//       <div className="app__formshomepage-content">
//         {tabs[activeTab].content}
//       </div>
//     </div>
//   );
// };

// export default FormsHomepage;



import React, { useState, useEffect } from 'react';

import './FormsHomepage.css';
import { PortraitsForm, RadiosForm, MusicsForm, InPrintsForm, FilmsForm, DadsRecordsForm, GallerysForm, GardensForm, GrandMothersPansForm, HappeningsForm, LibrarysForm, SpeaksForm, ArchivesForm,HomepageImgForm, AttireForm,MapForm } from '../';
import { AiFillPicture } from 'react-icons/ai';
import { RiArchiveFill } from 'react-icons/ri';
import { BiSolidRadio, BiSolidMoviePlay, BiSolidCalendarEvent } from 'react-icons/bi';
import { SiApplemusic, SiApplepodcasts } from 'react-icons/si';
import { GrGallery } from 'react-icons/gr';
import { ImBook } from 'react-icons/im';
import { GiThreeLeaves } from 'react-icons/gi';
import { IoLibrary } from 'react-icons/io5';

const FormsHomepage = () => {
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    // Get the activeTab from localStorage if it exists
    const storedActiveTab = localStorage.getItem('activeTab');
    if (storedActiveTab !== null) {
      setActiveTab(parseInt(storedActiveTab, 10));
    }
  }, []);

  const handleTabClick = (index) => {
    setActiveTab(index);
    // Store the activeTab in localStorage
    localStorage.setItem('activeTab', index.toString());
  };

  const tabs = [
    { label: <div><h1 className='subheadtext__cormorant flex__tabs back_effect'><span      className='icon-inline'><IoLibrary/></span>Archives</h1></div>,
    content: <ArchivesForm/>},
       
    { label: <div><h1 className='subheadtext__cormorant flex__tabs back_effect'><span      className='icon-inline'><AiFillPicture/></span>Portraits</h1></div>,
      content: <PortraitsForm/>},

      { label: <div><h1 className='subheadtext__cormorant flex__tabs back_effect'><span      className='icon-inline'><BiSolidRadio/></span>Radio</h1></div>,
      content: <RadiosForm/>},

      { label: <div><h1 className='subheadtext__cormorant flex__tabs back_effect'><span      className='icon-inline'><SiApplemusic/></span>Music</h1></div>,
      content: <MusicsForm/>},

      { label: <div><h1 className='subheadtext__cormorant flex__tabs back_effect'><span      className='icon-inline'><BiSolidMoviePlay/></span>Films</h1></div>,
      content: <FilmsForm/>},

      { label: <div><h1 className='subheadtext__cormorant flex__tabs back_effect'><span      className='icon-inline'><GrGallery/></span>Gallery</h1></div>,
      content: <GallerysForm/>},

      { label: <div><h1 className='subheadtext__cormorant flex__tabs back_effect'><span      className='icon-inline'><GrGallery/></span>InPrint</h1></div>,
      content: <InPrintsForm/>},

      { label: <div><h1 className='subheadtext__cormorant flex__tabs back_effect'><span      className='icon-inline'><BiSolidCalendarEvent/></span>Happenings</h1></div>,
      content: <HappeningsForm/>},

      { label: <div><h1 className='subheadtext__cormorant flex__tabs back_effect'><span      className='icon-inline'><SiApplepodcasts/></span>Speaks</h1></div>,
      content: <SpeaksForm/>},

      // { label: <div><h1 className='subheadtext__cormorant flex__tabs back_effect'><span      className='icon-inline'><ImBook/></span>Book</h1></div>,
      // content: <GallerysForm/>},

      { label: <div><h1 className='subheadtext__cormorant flex__tabs back_effect'><span      className='icon-inline'><GiThreeLeaves/></span>Garden</h1></div>,
      content: <GardensForm/>},

      { label: <div><h1 className='subheadtext__cormorant flex__tabs back_effect'><span      className='icon-inline'><IoLibrary/></span>Library</h1></div>,
      content: <LibrarysForm/>},

      { label: <div><h1 className='subheadtext__cormorant flex__tabs back_effect'><span      className='icon-inline'><AiFillPicture/></span>Grand Mothers Pans</h1></div>,
      content: <GrandMothersPansForm/>},

      { label: <div><h1 className='subheadtext__cormorant flex__tabs back_effect'><span      className='icon-inline'><AiFillPicture/></span>Dad's Records</h1></div>,
      content: <DadsRecordsForm/>},

      { label: <div><h1 className='subheadtext__cormorant flex__tabs back_effect'><span      className='icon-inline'><AiFillPicture/></span>Homepage Image</h1></div>,
      content: <HomepageImgForm/>},

      { label: <div><h1 className='subheadtext__cormorant flex__tabs back_effect'><span      className='icon-inline'><ImBook/></span>Map</h1></div>,
      content: <MapForm/>},

      { label: <div><h1 className='subheadtext__cormorant flex__tabs back_effect'><span      className='icon-inline'><IoLibrary/></span>Attire</h1></div>,
      content: <AttireForm/>},

     
  ];

  return (
    <div className="app__formshomepage">
      <div className="app__formshomepage-tablist">
        {tabs.map((tab, index) => (
          <div
            key={index}
            className={`tab ${index === activeTab ? 'active-tab' : ''}`}
            onClick={() => handleTabClick(index)}
          >
            <div className='app__formshomepage-tab'>{tab.label}</div>
          </div>
        ))}
      </div>
      <div className="app__formshomepage-content">
        {tabs[activeTab].content}
      </div>
    </div>
  );
};

export default FormsHomepage;
