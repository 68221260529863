import React from 'react';
import { FaInstagram, RxInstagramLogo } from 'react-icons/fa';
import { images } from '../../constants';
import './Videobox.css';
import { Link } from 'react-router-dom';

const Videobox = ({ display_video, name ,description, subheading, audio_url, contentType }) => (
  // console.log(contentType)
  <div className="app__videobox">
    
    <div className='app__videobox-img'>
    {/* <img src={display_video} alt="nancy" /> */}
     { contentType=='video'?
    <iframe src={display_video}  allow="autoplay"></iframe>:null}

{ contentType=='sound'?
    <img src={display_video}  alt={name}/>:null}

{ contentType=='img'?
    <img src={display_video} alt={name}/>:null}
    </div>

    <div className='app__videobox-content'>
    
        <div className="app__videobox-head">
        <p className="headtext__cormorant font_style_italic">{name}</p>
        </div>   

        <div className="app__videobox-tagline">
            <p className="font_style_italic">{subheading}</p>
        </div>

        <div className="app__videobox-paragraph">
            {/* <p className="font_style_italic p__cormorant">{description}</p> */}
            <p className='font_style_italic p__cormorant' dangerouslySetInnerHTML={{ __html: description }}/>
            <iframe width="100%" height="100" src={audio_url} frameborder="0" ></iframe>
        </div>

        {/* <div className='app__videobox-action'>
            <div className='app__videobox__button'>
            <button className='custom__button'><a href={website_url}>Book</a></button>
            </div>


        </div> */}
    </div>


  </div>
);

export default Videobox;