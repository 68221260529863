import React, {useState} from 'react';
import { FiSearch } from 'react-icons/fi';
import './SearchResults.css';
import { json } from 'react-router-dom';
import { Link } from 'react-router-dom';

const SearchResults = ({ results }) => {
    // console.log("printing from searchresults")
    // console.log(results)

  return(
    <div className="app__searchresults">
    {results.map((result, id) => (
    //   <Link
    //     to={{
    //       pathname: result.detailsPageLink,
    //       state: { contentType: result.contentType, name: result.name }
    //     }}
    //     key={id}
    //   >
    //     <div className="app__searchresults-name">{result.name}</div>
    //   </Link>
    // console.log(result)
    <Link to={result.detailsPageLink} state={{ contentType: result.contentType, name:result.name }}>
        <div className="app__searchresults-name">{result.name}</div>
    </Link>
    ))}
  </div>
)     
  };

export default SearchResults;