// MyComponent.js
// import './FilmsForm.css'

import React, { useState, useEffect } from 'react';
// import { images,radios } from '../../constants';
import { SubHeading, Searchbar, Eventbox, AttireFormItem } from '../../components';
import { fetchAttireById,fetchAttireMedia,updateAttire,saveAttireMedia} from '../../services/attire'; // Import the specific API function
import axios from 'axios';

import {useNavigate} from 'react-router-dom';
// import { saveAttire } from '../../services/Attire';

// const navigate = useNavigate();


function AttireForm() {
    const navigate = useNavigate();
    
  const [editingIndex, setEditingIndex] = useState(null);
  const [formData, setFormData] = useState({
    archive_name: '',
    description: '',
    // Add more fields here
  });

  const [attire, setAttire] = useState([]);  
//   const [heading, setHeading] = useState("");
const [header_img, setHeaderImg] = useState(null);
const [display_img, setDisplayImg] = useState(null);
const [video, setVideo] = useState(null);
const [video_caption, setVideoCaption] =  useState(attire.video_caption || "");
const [heading, setHeading] =  useState(attire.heading || "");
const [subheading, setSubHeading] =  useState(attire.subheading || "");
const [description, setDescription] =  useState( attire.description || "");

  

  const [media_url, setMediaUrl] = useState(null);
  const [media_heading, setMediaHeading] = useState("");
  const [media_subheading, setMediaSubHeading] = useState("");
  const [media_subheading_url, setMediaSubHeadingUrl] = useState("");
  const [type, setType] = useState("");

  const [attiremedia, setAttireMedia] = useState([]);
  


    // Handle file input change
    const handleBannerFileChange = (e) => {
        setHeaderImg(e.target.files[0]);
    };

    const handleDisplayFileChange = (e) => {
        setDisplayImg(e.target.files[0]);
    };

    const handlevideoFileChange = (e) => {
        setVideo(e.target.files[0]);
    };

    const handleMediaFileChange = (e) => {
        setMediaUrl(e.target.files[0]);
    };



  const handleInputChange = event => {
    const { name, value } = event.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value,
    }));
  };
  

  
  // Handle form submission
  const saveData = async (e) => {
    e.preventDefault();
  
    // Create a FormData object to send the form data
    const formData = new FormData();

    if (header_img && header_img instanceof File) {
        // Generate a unique filename using a timestamp and the original file's extension
        const fileExtension = header_img.name.split('.').pop();
        const uniqueFilename = `${Date.now()}.${fileExtension}`;
    
        // Create a new File object with the unique filename
        const renamedFile1 = new File([header_img], uniqueFilename, {
          type: header_img.type,
        });
    
        // Append the renamed file to the formData
        formData.append('header_img', renamedFile1);
      }


      if (display_img && display_img instanceof File) {
        // Generate a unique filename using a timestamp and the original file's extension
        const fileExtension = display_img.name.split('.').pop();
        const uniqueFilename = `${Date.now()}.${fileExtension}`;
    
        // Create a new File object with the unique filename
        const renamedFile2 = new File([display_img], uniqueFilename, {
          type: display_img.type,
        });
    
        // Append the renamed file to the formData
        formData.append('display_img', renamedFile2);
      }

      if (video && video instanceof File) {
        // Generate a unique filename using a timestamp and the original file's extension
        const fileExtension = video.name.split('.').pop();
        const uniqueFilename = `${Date.now()}.${fileExtension}`;
    
        // Create a new File object with the unique filename
        const renamedFile3 = new File([video], uniqueFilename, {
          type: video.type,
        });
    
        // Append the renamed file to the formData
        formData.append('video', renamedFile3);
      }


    formData.append('video_caption', video_caption);
    formData.append('heading', heading);
    formData.append('subheading', subheading);
    formData.append('description', description);

  
    console.log(formData);
  
    try {
      const responseData = await updateAttire(formData); // Call the submitFormData function
      console.log('Response:', responseData);
      // Handle success, e.g., display a success message or redirect
      alert('Success!');
    } catch (error) {
      console.error('Error:', error);
      // Handle error, e.g., display an error message
    }
  };
  
  

    // Handle form submission
    const saveMediaData = async (e) => {
        e.preventDefault();
      
        // Create a FormData object to send the form data
        const formData = new FormData();
    
        if (media_url && media_url instanceof File) {
          // Generate a unique filename using a timestamp and the original file's extension
          const fileExtension = media_url.name.split('.').pop();
          const uniqueFilename = `${Date.now()}.${fileExtension}`;
      
          // Create a new File object with the unique filename
          const renamedFile = new File([media_url], uniqueFilename, {
            type: media_url.type,
          });
      
          // Append the renamed file to the formData
          formData.append('image', renamedFile);
        }

        formData.append('heading', media_heading);
        formData.append('subheading', media_subheading);
        formData.append('subheading_url', media_subheading_url);
        formData.append('type', type);
      
        console.log(formData);
      
        try {
          const responseData = await saveAttireMedia(formData); // Call the submitFormData function
          console.log('Response:', responseData);
          // Handle success, e.g., display a success message or redirect
          alert('Success!');
        } catch (error) {
          console.error('Error:', error);
          // Handle error, e.g., display an error message
        }
      };
      



  useEffect(() => {
    async function fetchData() {
      try {
        const data = await fetchAttireById(1);
        // setArchives(data);
        console.log(data)
        setAttire(data)
        setVideoCaption(data.video_caption || "");
        setHeading(data.heading || "");
        setSubHeading(data.subheading || "");
        setDescription(data.description || "");


      } catch (error) {
        // Handle error
      }
    }

    async function fetchMediaData() {
        try {
          const data = await fetchAttireMedia();
          // setArchives(data);
          console.log(data)
          setAttireMedia(data)
        } catch (error) {
          // Handle error
        }
      }

    fetchData();
    fetchMediaData();
  }, []);
  




  return (

    <div className='radios-form'>

<div className='radios-form__add'>

      
<h2 class="headtext__cormorant">Attire</h2>
<p class="headtext__cormorant2">Update Attire</p>
<br/>

<form class="w-full max-w-sm">
<div class="md:flex md:items-center mb-6">
  <div class="md:w-1/3">
    <label class="block text-gray-900 font-bold md:text-right mb-1 md:mb-0 pr-4" for="inline-full-name">
    Banner Image 
    </label>
  </div>
    <div class="md:w-2/3">
    <img width="300px" height="300px" src={attire.header_img}/>
      <input
      class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" id="inline-full-name"
      type="file"
      name="header_img"
      accept="image/*"
      onChange={handleBannerFileChange}
      />
    </div>
  </div>

  <div class="md:flex md:items-center mb-6">
  <div class="md:w-1/3">
    <label class="block text-gray-900 font-bold md:text-right mb-1 md:mb-0 pr-4" for="inline-full-name">
      Display Image 
    </label>
  </div>
    <div class="md:w-2/3">
    <img width="300px" height="300px" src={attire.display_img}/>
      <input
      class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" id="inline-full-name"
      type="file"
      name="diplay_img"
      accept="image/*"
      onChange={handleDisplayFileChange}
      />
    </div>
  </div>

  <div class="md:flex md:items-center mb-6">
  <div class="md:w-1/3">
    <label class="block text-gray-900 font-bold md:text-right mb-1 md:mb-0 pr-4" for="inline-full-name">
      Video 
    </label>
  </div>
    <div class="md:w-2/3">
    
    <video src={attire.video} width="300px" height="300px" controls></video>
      <input
      class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" id="inline-full-name"
      type="file"
      name="video"
      accept="image/*"
      onChange={handlevideoFileChange}
      />
    </div>
  </div>

  <div class="md:flex md:items-center mb-6">
    <div class="md:w-1/3">
      <label class="block text-gray-900 font-bold md:text-right mb-1 md:mb-0 pr-4" for="inline-full-name">
      Video Caption
      </label>
    </div>
      <div class="md:w-2/3">
        <input
        class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" id="inline-full-name"
        type="text"
        name="heading"
        value={video_caption}
        onChange={(e)=>setVideoCaption(e.target.value)}
        />
      </div>
  </div>


  <div class="md:flex md:items-center mb-6">
    <div class="md:w-1/3">
      <label class="block text-gray-900 font-bold md:text-right mb-1 md:mb-0 pr-4" for="inline-full-name">
      Heading
      </label>
    </div>
      <div class="md:w-2/3">
        <input
        class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" id="inline-full-name"
        type="text"
        name="heading"
        value={heading}
        onChange={(e)=>setHeading(e.target.value)}
        />
      </div>
  </div>


  <div class="md:flex md:items-center mb-6">
  <div class="md:w-1/3">
    <label class="block text-gray-900 font-bold md:text-right mb-1 md:mb-0 pr-4" for="inline-full-name">
    Subheading
    </label>
  </div>
  <div class="md:w-2/3">
      <textarea
      class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" id="inline-full-name"
      type="text"
      name="subheading"
      value={subheading}
      onChange={(e)=>setSubHeading(e.target.value)}
      />
    </div>
  </div>

  <div class="md:flex md:items-center mb-6">
  <div class="md:w-1/3">
    <label class="block text-gray-900 font-bold md:text-right mb-1 md:mb-0 pr-4" for="inline-full-name">
    Description
    </label>
  </div>
  <div class="md:w-2/3">
      <textarea
      class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" id="inline-full-name"
      type="text"
      name="subheading"
      value={description}
      onChange={(e)=>setDescription(e.target.value)}
      />
    </div>
  </div>


  

  <div class="md:flex md:items-center">
    <div class="md:w-1/3"></div>
    <div class="md:w-2/3">
      <button class="shadow bg-orange-400 hover:bg-orange-500 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded" 
      type="submit"
      onClick={saveData}
      >
      Submit
      </button>
    </div>
  </div>
  
</form>

</div>




      
      <div className='radios-form__add'>

      
      {/* <h2 class="headtext__cormorant">Attire</h2> */}
      <p class="headtext__cormorant2">Add New Image in Attire</p>
      <br/>

      <form class="w-full max-w-sm">
      <div class="md:flex md:items-center mb-6">
        <div class="md:w-1/3">
          <label class="block text-gray-900 font-bold md:text-right mb-1 md:mb-0 pr-4" for="inline-full-name">
            Upload Image
          </label>
        </div>
          <div class="md:w-2/3">
            <input
            class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" id="inline-full-name"
            type="file"
            name="url"
            accept="image/*"
            onChange={handleMediaFileChange}
            />
          </div>
        </div>


        <div class="md:flex md:items-center mb-6">
          <div class="md:w-1/3">
            <label class="block text-gray-900 font-bold md:text-right mb-1 md:mb-0 pr-4" for="inline-full-name">
            Heading
            </label>
          </div>
            <div class="md:w-2/3">
              <input
              class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" id="inline-full-name"
              type="text"
              name="heading"
              value={media_heading}
              onChange={(e)=>setMediaHeading(e.target.value)}
              />
            </div>
        </div>


        <div class="md:flex md:items-center mb-6">
        <div class="md:w-1/3">
          <label class="block text-gray-900 font-bold md:text-right mb-1 md:mb-0 pr-4" for="inline-full-name">
          Subheading
          </label>
        </div>
        <div class="md:w-2/3">
            <textarea
            class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" id="inline-full-name"
            type="text"
            name="subheading"
            value={media_subheading}
            onChange={(e)=>setMediaSubHeading(e.target.value)}
            />
          </div>
        </div>

        <div class="md:flex md:items-center mb-6">
        <div class="md:w-1/3">
          <label class="block text-gray-900 font-bold md:text-right mb-1 md:mb-0 pr-4" for="inline-full-name">
          Subheading Url
          </label>
        </div>
        <div class="md:w-2/3">
            <textarea
            class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" id="inline-full-name"
            type="text"
            name="subheading"
            value={media_subheading_url}
            onChange={(e)=>setMediaSubHeadingUrl(e.target.value)}
            />
          </div>
        </div>


        
        <div class="md:flex md:items-center mb-6">
    <div class="md:w-1/3">
      <label class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4" for="media-type">
        Media Type
      </label>
    </div>
    <div class="md:w-2/3">
      <select
        class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
        id="media-type"
        name="media_type"
        value={type}
        onChange={(e) => setType(e.target.value)}
      >
        <option value="image" defaultValue>Image</option>
        <option value="video">Video</option>
      </select>
    </div>
  </div>

    


        <div class="md:flex md:items-center">
          <div class="md:w-1/3"></div>
          <div class="md:w-2/3">
            <button class="shadow bg-orange-400 hover:bg-orange-500 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded" 
            type="submit"
            onClick={saveMediaData}
            >
            Submit
            </button>
          </div>
        </div>
        
      </form>

      </div>

      <p class="headtext__cormorant2" style={{padding: 1+'rem'}}>All Media in Attire</p>
      <div className='radios-form__show'>
        {/* <div className='app__capturelist'> */}
        {/* <Searchbar/> */}
        {attiremedia.map((photographer, index) => (
          <AttireFormItem
            id={photographer.id}
            url={photographer.image}
            heading={photographer.heading}
            subheading={photographer.subheading}
            subheading_url={photographer.subheading_url}
            type = {photographer.type}
            
            
          />
        ))}
      {/* </div> */}

      </div>
  
       

      
    </div>
  );
}

export default AttireForm;




