import React from 'react';
import { FiFacebook, FiTwitter, FiInstagram } from 'react-icons/fi';

import { FooterOverlay, Newsletter } from '../../components';
import { images } from '../../constants';
import './Footer.css';

const Footer = () => (
  <div className="app__footer" id="login">
    <FooterOverlay />
    

    <div className="app__footer-links">
      {/* <div className="app__footer-links_contact">
        <h1 className="app__footer-headtext">Contact Us</h1>
        <p className="p__opensans">thecockneysikh@outlook.com</p>
        
      </div> */}

      <div className="app__footer-links_logo">
        <img src={images.whitelogo} alt="footer_logo" />
        <p className="p__opensans">Dad always said, 'You cannot say “This is my house, this is my wife, this is my friend” or even “This is my cat” because one day you’re going to leave it all.’</p>
        {/* <img src={images.spoon} className="spoon__img" style={{ marginTop: 15 }} /> */}
        <div className="app__footer-links_icons">
          <a href="https://www.instagram.com/thecockneysikh/">
            <div className='app__footerinstagram'>
            <img src={images.instagramlogo} alt='instagram'/>
            </div>
          </a>
          
        </div>
      </div>

      {/* <div className="app__footer-links_work">
        <h1 className="app__footer-headtext">Working Hours</h1>
        <p className="p__opensans">Monday-Friday:</p>
        <p className="p__opensans">08:00 am - 12:00 am</p>
        <p className="p__opensans">Saturday-Sunday:</p>
        <p className="p__opensans">07:00 am - 11:00 pm</p>
      </div> */}
    </div>

    

    <div className="footer__copyright">
    {/* <h1 className="app__footer-headtext">Contact</h1> */}
      <p className="p__opensans">thecockneysikh@outlook.com</p>
    </div>  
    
    <div className="footer__copyright">
      <p className="p__opensans ">Designed by Suresh Singh & Developed by Kshitija Ghate
      {/* <br/> */}
      {/* Developed by Kshitija Ghate */}
      </p>
    </div>

    <div className="footer__copyright">
      <p className="p__opensans">2023 thecockneysikh.com. All Rights reserved.</p>
    </div>

  </div>
);

export default Footer;