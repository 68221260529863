import images from './images';

const happenings = [
  {
    display_img: 'https://storage.googleapis.com/thecockneysikh-storage/happenings/footprint2.jpg',
    event_name: 'Coming up Footprints of Love, Pt. 2',
    description: "Suresh Singh shares his love letter to his father Joginder Singh, who came to Spitalfields in 1949. The first biography to be written about a London Sikh. A Modest Living, Memoirs of a Cockney Sikh: published by Spitalfields Life . “A timely reminder of all that modern Britishness encompasses” The Observer",
    subheading: 'Sunday 15 Oct, 2023 at 11am @ The Hanbury Hall - Spitafields',
    booking_url:'https://91livingroom.com/whats-on/kaur-at-ninety-one/?_gl=1*ihjor6*_up*MQ..*_ga*MTI5MzUzNzIxMi4xNjkwODUzMDMw*_ga_CZ3FJRP4KH*MTY5MDg1MzAyOS4xLjEuMTY5MDg1MzA0NS4wLjAuMA',
    header_img_url:"https://drive.google.com/uc?export=view&id=1oH61GeEN7DANfiWTj_WgXq9OAIaSvHOr",
    details_page_link:'/happeningsdetails',

  },

  {
    display_img: "https://storage.googleapis.com/thecockneysikh-storage/happenings/display.png",
    event_name: 'London Fashion Week',
    description: "Paricaẏa (meaning identity in Bengali) is an evening curated by Tasnim Chowdhury that explores the relationship between identity and fashion within London's South Asian community.The evening will include a screening of 'Banglatown!' and the premiere of 'TheCockneySikh' hecockneysikh by filmmaker, Farihah Chowdhury @farihah_view followed by a conversation between Suresh Singh, Farihah Chowdhury and Tasnim Chowdhury. There will be music and poetry performances by Geeta Sidhu @living_intransit , Jay Mitra @punkofcolour ,and Dinah Ali @yourstruly.dinah offering personal reflections of identity through spoken word. A presentation of traditional hand dyed fabrics, vintage garments, artefacts and literary references will also be on display. @referencepoint180",

    subheading: 'Monday 11 Sept at 7-11pm 2023 @ 2 Arundel St, London WC2R 3DA ',
    booking_url:'https://91livingroom.com/whats-on/kaur-at-ninety-one/?_gl=1*ihjor6*_up*MQ..*_ga*MTI5MzUzNzIxMi4xNjkwODUzMDMw*_ga_CZ3FJRP4KH*MTY5MDg1MzAyOS4xLjEuMTY5MDg1MzA0NS4wLjAuMA',
    header_img_url:"https://drive.google.com/uc?export=view&id=1oH61GeEN7DANfiWTj_WgXq9OAIaSvHOr",
    details_page_link:'/happeningsdetails',

  },
    {
      display_img: "https://storage.googleapis.com/thecockneysikh-storage/happenings/kaur.png",
      event_name: 'KAUR',
      description: "KAUR, an eclectic and innovative event full of live performances, DJs, interviews and more! Get inspired and awaken your creativity. KAUR event will be kicking off carnival season with an eclectic array of artists, DJs and interviews to get your feet moving and your mind elated. The name ‘Kaur’ in Sikhi is the last name given to women, for them to maintain strength and independence. Kaur phonetically sounds like core, the root of everything, the heart, the substance, the centre of the earth With interactive art sessions, a knitting workshop, and an exhibition, there are many ways to get inspired and express creativity. We’ve also got live music, spoken word and a marketplace if you just wanna chill and take the world in. We’ll be doing an interview with one of East London’s finest, giving us an authentic outlook on the history of Brick Lane. Join The Cockney Sikh on an exclusive walking tour for an in-depth and original insight into this vibrant part of the city. The exhibition will explore themes of identity, community and experiences as a society or from an individual’s perspective. Come and check some marvellous artists, learn about their stories, share one of your own!",
      subheading: 'Sunday 6 Aug, 2023 at 4pm, 91 Brick Lane , @ Ninety One Living Room',
      booking_url:'https://91livingroom.com/whats-on/kaur-at-ninety-one/?_gl=1*ihjor6*_up*MQ..*_ga*MTI5MzUzNzIxMi4xNjkwODUzMDMw*_ga_CZ3FJRP4KH*MTY5MDg1MzAyOS4xLjEuMTY5MDg1MzA0NS4wLjAuMA',
      header_img_url:"https://drive.google.com/uc?export=view&id=1oH61GeEN7DANfiWTj_WgXq9OAIaSvHOr",
      details_page_link:'/happeningsdetails',
  
    },

    {
      display_img: 'https://storage.googleapis.com/thecockneysikh-storage/happenings/footprint1.png',
      event_name: 'Footprints of Love, Pt. 1',
      description: "Suresh Singh shares his love letter to his father Joginder Singh, who came to Spitalfields in 1949. The first biography to be written about a London Sikh. A Modest Living, Memoirs of a Cockney Sikh: published by Spitalfields Life . 'A timely reminder of all that modern Britishness encompasses' The Observer.",
      subheading: '4 April, 2023 @ The Hanbury Hall - Spitafields',
      booking_url:'https://91livingroom.com/whats-on/kaur-at-ninety-one/?_gl=1*ihjor6*_up*MQ..*_ga*MTI5MzUzNzIxMi4xNjkwODUzMDMw*_ga_CZ3FJRP4KH*MTY5MDg1MzAyOS4xLjEuMTY5MDg1MzA0NS4wLjAuMA',
      header_img_url:"https://drive.google.com/uc?export=view&id=1oH61GeEN7DANfiWTj_WgXq9OAIaSvHOr",
      details_page_link:'/happeningsdetails',
  
    },

    {
      display_img: 'https://storage.googleapis.com/thecockneysikh-storage/happenings/cheshepao.png',
      event_name: 'What Can I Do?: Che Si Puó Fare?',
      description: "Two years ago on Friday 27 August 2021 6pm to 7pm - In the beautiful house of our Dear Friend Ben Adler @benadler51 Fournier Street Spitalfields, was performed “Six Readings and Cello- What can I do?: Che si puó fare? (quote/arr. Clare O’Connell Cellist/ Barbara Strozzi born 1619-1677)” by CLARE O’CONNELL Cellist @oconnellcello and I.",
      subheading: '',
      booking_url:'https://91livingroom.com/whats-on/kaur-at-ninety-one/?_gl=1*ihjor6*_up*MQ..*_ga*MTI5MzUzNzIxMi4xNjkwODUzMDMw*_ga_CZ3FJRP4KH*MTY5MDg1MzAyOS4xLjEuMTY5MDg1MzA0NS4wLjAuMA',
      header_img_url:"https://drive.google.com/uc?export=view&id=1oH61GeEN7DANfiWTj_WgXq9OAIaSvHOr",
      details_page_link:'/happeningsdetails',
  
    },
   
  ];

export default { happenings };