import React, { useState, useEffect } from 'react';
import { FaInstagram, RxInstagramLogo } from 'react-icons/fa';
import { images } from '../../constants';
import './InPrint.css';
import { Link } from 'react-router-dom';
import { fetchInPrints} from '../../services/inprint'

const InPrint = ({  }) => {

    const [radios, setRadios] = useState([]);

    useEffect(() => {
      async function fetchData() {
        try {
          const data = await fetchInPrints();
          // setArchives(data);
          console.log(data)
          setRadios(data)
        } catch (error) {
          // Handle error
        }
      }
  
      fetchData();
    }, []); 
    
    
    
    return (
  // console.log(contentType)
  <div className="app__gallery">


{radios && radios.length > 0 && radios.reverse().map((v, index) => (
            
            <div className='app__gallery-box'>
            <div className='app__gallery-img' key={index}>
                {v.type === 'image' ? (
                <img src={v.url} height="300px" width="300px" alt="" />
                ) : v.type === 'video' ? (
                <video src={v.url} height="300px" width="300px" controls></video>
                ) : null}
            </div>
            <p className='p__cormorant'>{v.heading}</p>
            <p className='p__cormorant-small'>{v.subheading}</p>
    
            </div>
            
            ))} 
    

    
    {/* <div className='app__gallery-box'>
        <div className='app__gallery-img'>
        <img src="https://storage.googleapis.com/thecockneysikh-storage/inprint/1.jpeg" height="300px" width="300px" alt=""/>
       
        </div>
        <p className='p__cormorant'>Au Plan K</p>
        <p className='p__cormorant-small'>Book By Philippe Carly</p>
    </div>

    
    
    <div className='app__gallery-box'>
        <div className='app__gallery-img'>
        <img src='https://storage.googleapis.com/thecockneysikh-storage/inprint/2.jpeg' height="300px" width="300px"  alt=""/>
       
        </div>
        <p className='p__cormorant'>Au Plan K</p>
        <p className='p__cormorant-small'>Book By Philippe Carly</p>
    </div>


    
    <div className='app__gallery-box'>
        <div className='app__gallery-img'>
        <img src='https://storage.googleapis.com/thecockneysikh-storage/inprint/3.jpeg' height="300px" width="300px"  alt=""/>
       
        </div>
        <p className='p__cormorant'>Au Plan K</p>
        <p className='p__cormorant-small'>Book By Philippe Carly</p>
    </div>
    


    <div className='app__gallery-box'>
        <div className='app__gallery-img'>
        <img src='https://storage.googleapis.com/thecockneysikh-storage/inprint/4.jpeg' height="300px" width="300px"  alt=""/>
       
        </div>
       
        <p className='p__cormorant'>Au Plan K</p>
        <p className='p__cormorant-small'>Book By Philippe Carly</p>
    </div>


    <div className='app__gallery-box'>
        <div className='app__gallery-img'>
        <img src='https://storage.googleapis.com/thecockneysikh-storage/inprint/5.jpeg' height="300px" width="300px"  alt=""/>
       
        </div>
        <p className='p__cormorant'>Au Plan K</p>
        <p className='p__cormorant-small'>Book By Philippe Carly</p>
    </div>

    <div className='app__gallery-box'>
        <div className='app__gallery-img'>
        <img src='https://storage.googleapis.com/thecockneysikh-storage/inprint/6.jpeg' height="300px" width="300px"  alt=""/>
       
        </div>
        <p className='p__cormorant'>NEWHAM BOOKSHOP</p>

    </div>

    <div className='app__gallery-box'>
        <div className='app__gallery-img'>
        <img src='https://storage.googleapis.com/thecockneysikh-storage/inprint/7.jpeg' height="300px" width="300px"  alt=""/>
       
        </div>
        <p className='p__cormorant'>NEWHAM BOOKSHOP</p>
        
    </div>


    <div className='app__gallery-box'>
        <div className='app__gallery-img'>
        <img src='https://storage.googleapis.com/thecockneysikh-storage/inprint/8.jpeg' height="300px" width="300px"  alt=""/>
       
        </div>
        <p className='p__cormorant'>NEWHAM BOOKSHOP</p>

    </div>

    <div className='app__gallery-box'>
        <div className='app__gallery-img'>
        <img src='https://storage.googleapis.com/thecockneysikh-storage/inprint/9.jpeg' height="300px" width="300px"  alt=""/>
       
        </div>
        <p className='p__cormorant'>Portrait of Britain</p>
        <p className='p__cormorant-small'>Hoxton Mini Press</p>
    </div>


    
    <div className='app__gallery-box'>
        <div className='app__gallery-img'>
        <img src='https://storage.googleapis.com/thecockneysikh-storage/inprint/10.jpeg' height="300px" width="300px"  alt=""/>
       
        </div>
        <p className='p__cormorant'>Portrait of Britain</p>
        <p className='p__cormorant-small'>Hoxton Mini Press</p>
    </div>

        
    <div className='app__gallery-box'>
        <div className='app__gallery-img'>
        <img src='https://storage.googleapis.com/thecockneysikh-storage/inprint/11.jpeg' height="300px" width="300px"  alt=""/>
       
        </div>
        <p className='p__cormorant'>National Geographic Traveller</p>
        <p className='p__cormorant-small'>Sept 23</p>
    </div>


    
    <div className='app__gallery-box'>
        <div className='app__gallery-img'>
        <img src='https://storage.googleapis.com/thecockneysikh-storage/inprint/12.jpeg' height="300px" width="300px"  alt=""/>
       
        </div>
        <p className='p__cormorant'>National Geographic Traveller</p>
        <p className='p__cormorant-small'>Sept 23</p>
    </div>





    
    <div className='app__gallery-box'>
        <div className='app__gallery-img'>
        <img src='https://storage.googleapis.com/thecockneysikh-storage/inprint/14.jpeg' height="300px" width="300px"  alt=""/>
       
        </div>
        <p className='p__cormorant'>Article By Devinder Bains</p>
        <p className='p__cormorant-small'>DECENT Magzine For Men By Women</p>
    </div>


    <div className='app__gallery-box'>
        <div className='app__gallery-img'>
        <img src='https://storage.googleapis.com/thecockneysikh-storage/inprint/15.jpeg' height="300px" width="300px"  alt=""/>
       
        </div>
        <p className='p__cormorant'>Article By Devinder Bains</p>
        <p className='p__cormorant-small'>DECENT Magzine For Men By Women</p>
    </div>


    <div className='app__gallery-box'>
        <div className='app__gallery-img'>
        <img src='https://storage.googleapis.com/thecockneysikh-storage/inprint/16.png' height="300px" width="300px"  alt=""/>
       
        </div>
        <p className='p__cormorant'>MAGCULTURE</p>
        <p className='p__cormorant-small'>270 St. John Street London Ec1V 4PE</p>
    </div>


    <div className='app__gallery-box'>
        <div className='app__gallery-img'>
        <img src='https://storage.googleapis.com/thecockneysikh-storage/inprint/13.png' height="300px" width="300px"  alt=""/>
       
        </div>
        <p className='p__cormorant'>Financial Times 19/02/22 </p>
        
    </div>

    <div className='app__gallery-box'>
        <div className='app__gallery-img'>
        <img src='https://storage.googleapis.com/thecockneysikh-storage/inprint/17.jpeg' height="300px" width="300px"  alt=""/>
       
        </div>
        <p className='p__cormorant'>RIBA Publication</p>
        
    </div>


    <div className='app__gallery-box'>
        <div className='app__gallery-img'>
        <img src='https://storage.googleapis.com/thecockneysikh-storage/inprint/18.jpeg' height="300px" width="300px"  alt=""/>
       
        </div>
        <p className='p__cormorant'>RIBA Book Shop</p>
        <p className='p__cormorant-small'>66 Portlnd Place London W1B 1AD</p>
    </div> */}




  
</div>
    
    
   
)};

export default InPrint;