import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { SubHeading, Listbox, Searchbar, Eventbox } from '../../components';
import { FaInstagram, RxInstagramLogo } from 'react-icons/fa';
import { images, captures } from '../../constants';
import './PortraitsList.css';
import { Link } from 'react-router-dom';
import { fetchPortraits} from '../../services/portraits';

const PortraitsList = () => {

  const [radios, setRadios] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        const data = await fetchPortraits();
        // setArchives(data);
        console.log(data)
        setRadios(data)
      } catch (error) {
        // Handle error
      }
    }

    fetchData();
  }, []);


  // Use the useLocation() hook inside the component body
  const location = useLocation();
//   const { contentType } = location.state;
  // console.log(contentType);
  
  // Define content based on the linkName
  let content = null;

 {
    
      content = <div className='app__capturelist'>
      
      {/* {captures.pictures.map((photographer, index) => (
        <Listbox
          key={photographer.photographer_name + index}
          display_img={photographer.display_img}
          name={photographer.photographer_name}
          description={photographer.description}
          insta_url={photographer.insta_url}
          website_url={photographer.website_url}
          details_page_link={photographer.details_page_link}
          img_urls={photographer.img_urls}
        //   contentType={contentType}
        />
      ))} */}

{radios && radios.length > 0 && radios.map((v, index) => (
      // console.log(v)
      <Listbox
        key={v.id}
        id={v.id}
        display_img={v.display_img}
        name={v.name}
        description={v.description}
        website_url={v.website_url}
        // contentType={contentType}
        details_page_link={v.details_page_link}
      />
    ))}
    </div>
      


  }


  return (
    <div className="app__wrapper section__padding">
      {content}
    </div>
  );
};

export default PortraitsList;




