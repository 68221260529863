// import React from 'react';
import React, { useState, useEffect } from "react";
import { SubHeading } from '../../components';
import { images } from '../../constants';
import './Header.css';
import {fetchHomepageImgById} from '../../services/homepageimg';

// const Header = () => (
//   <div className="app__header" id="home">
//     {/* <img src='https://storage.googleapis.com/thecockneysikh-storage/homepage/header3.jpg' alt="" /> */}
//      <div className='app__header-container'>
//       {/* <div className='app__header-img'> */}
        
//         <img src='https://storage.googleapis.com/thecockneysikh-storage/homepage/homepage3.png' alt="" />
//       {/* </div> */}
//     </div> 
    

//   </div>
// );


// ImageSwitcher.js



function Header() {
  const [picture, setPicture] = useState("");

  
  useEffect(() => {
    async function fetchData() {
      try {
        const data = await fetchHomepageImgById();
        setPicture(data);
        

        console.log(data)
      } catch (error) {
        // Handle error
      }


    }

    fetchData();
  }, []);



  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 1150);
    };

    // Initial check and add event listener
    handleResize();
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    // <div id="imageContainer">
    //   {isSmallScreen ? (
    //     <img src="small-image.jpg" alt="Small Image" />
    //   ) : (
    //     <img src="https://storage.googleapis.com/thecockneysikh-storage/homepage/homepage3.png" alt="Large Image" />
    //   )}
    // </div>

    <div className="app__header" id="home">
    {/* <img src='https://storage.googleapis.com/thecockneysikh-storage/homepage/header3.jpg' alt="" /> */}
     <div className='app__header-container'>
      {/* <div className='app__header-img'> */}
      {/* <img src='https://storage.googleapis.com/thecockneysikh-storage/homepage/header3.jpg' alt="" /> */}
      <img src={picture.display_img} alt="thecockneysikh" />
        
      {/* {isSmallScreen ? (
        <img src="https://storage.googleapis.com/thecockneysikh-storage/homepage/homepage5-mobile.png" alt="thecockneysikh" />
      ) : (
        <img src="https://storage.googleapis.com/thecockneysikh-storage/homepage/homepage5.png" alt="thecockneysikh" />
      )} */}
      {/* </div> */}
    </  div> 
    

  </div>
  );
}




export default Header;