// MyComponent.js
// import './FilmsForm.css'

import React, { useState, useEffect } from 'react';
// import { images,radios } from '../../constants';
import { SubHeading, FilmFormDisplayItem, Searchbar, Eventbox, InPrintFormItem } from '../../components';
import { fetchInPrints,saveInPrint} from '../../services/inprint'; // Import the specific API function
import axios from 'axios';

import {useNavigate} from 'react-router-dom';
// import { saveInPrint } from '../../services/inprint';

// const navigate = useNavigate();


function InPrintsForm() {
    const navigate = useNavigate();
    
  const [editingIndex, setEditingIndex] = useState(null);
  const [formData, setFormData] = useState({
    archive_name: '',
    description: '',
    // Add more fields here
  });

  const [url, setUrl] = useState(null);
  const [heading, setHeading] = useState("");
  const [subheading, setSubheading] =  useState("");
  const [type, setType] = useState("");

  const [inprints, setInPrints] = useState([]);


    // Handle file input change
    const handleFileChange = (e) => {
      setUrl(e.target.files[0]);
    };

  const handleInputChange = event => {
    const { name, value } = event.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value,
    }));
  };
  

  
  // Handle form submission
  const saveData = async (e) => {
    e.preventDefault();
  
    // Create a FormData object to send the form data
    const formData = new FormData();
    if (url && url instanceof File) {
      // Generate a unique filename using a timestamp and the original file's extension
      const fileExtension = url.name.split('.').pop();
      const uniqueFilename = `${Date.now()}.${fileExtension}`;
  
      // Create a new File object with the unique filename
      const renamedFile = new File([url], uniqueFilename, {
        type: url.type,
      });
  
      // Append the renamed file to the formData
      formData.append('url', renamedFile);
    }
    formData.append('heading', heading);
    formData.append('subheading', subheading);
    formData.append('type', type);
  
  
    console.log(formData);
  
    try {
      const responseData = await saveInPrint(formData); // Call the submitFormData function
      console.log('Response:', responseData);
      // Handle success, e.g., display a success message or redirect
      alert('Success!');
    } catch (error) {
      console.error('Error:', error);
      // Handle error, e.g., display an error message
    }
  };
  
  


  useEffect(() => {
    async function fetchData() {
      try {
        const data = await fetchInPrints();
        // setArchives(data);
        console.log(data)
        setInPrints(data)
      } catch (error) {
        // Handle error
      }
    }

    fetchData();
  }, []);
  




  return (

    <div className='radios-form'>
      
      <div className='radios-form__add'>

      
      <h2 class="headtext__cormorant">InPrints</h2>
      <p class="headtext__cormorant2">Add New Image in InPrints</p>
      <br/>

      <form class="w-full max-w-sm">
      <div class="md:flex md:items-center mb-6">
        <div class="md:w-1/3">
          <label class="block text-gray-900 font-bold md:text-right mb-1 md:mb-0 pr-4" for="inline-full-name">
            Upload Image
          </label>
        </div>
          <div class="md:w-2/3">
            <input
            class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" id="inline-full-name"
            type="file"
            name="url"
            accept="image/*"
            onChange={handleFileChange}
            />
          </div>
        </div>


        <div class="md:flex md:items-center mb-6">
          <div class="md:w-1/3">
            <label class="block text-gray-900 font-bold md:text-right mb-1 md:mb-0 pr-4" for="inline-full-name">
            Heading
            </label>
          </div>
            <div class="md:w-2/3">
              <input
              class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" id="inline-full-name"
              type="text"
              name="heading"
              value={heading}
              onChange={(e)=>setHeading(e.target.value)}
              />
            </div>
        </div>


        <div class="md:flex md:items-center mb-6">
        <div class="md:w-1/3">
          <label class="block text-gray-900 font-bold md:text-right mb-1 md:mb-0 pr-4" for="inline-full-name">
          Subheading
          </label>
        </div>
        <div class="md:w-2/3">
            <textarea
            class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" id="inline-full-name"
            type="text"
            name="subheading"
            value={subheading}
            onChange={(e)=>setSubheading(e.target.value)}
            />
          </div>
        </div>

        <div class="md:flex md:items-center mb-6">
    <div class="md:w-1/3">
      <label class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4" for="media-type">
        Media Type
      </label>
    </div>
    <div class="md:w-2/3">
      <select
        class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
        id="media-type"
        name="media_type"
        value={type}
        onChange={(e) => setType(e.target.value)}
      >
        <option value="image" defaultValue>Image</option>
        <option value="video">Video</option>
      </select>
    </div>
  </div>


    


        <div class="md:flex md:items-center">
          <div class="md:w-1/3"></div>
          <div class="md:w-2/3">
            <button class="shadow bg-orange-400 hover:bg-orange-500 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded" 
            type="submit"
            onClick={saveData}
            >
            Submit
            </button>
          </div>
        </div>
        
      </form>

      </div>

      <p class="headtext__cormorant2" style={{padding: 1+'rem'}}>All Photos in InPrints</p>
      <div className='radios-form__show'>
        {/* <div className='app__capturelist'> */}
        {/* <Searchbar/> */}
        {inprints.map((photographer, index) => (
          <InPrintFormItem
            id={photographer.id}
            url={photographer.url}
            heading={photographer.heading}
            subheading={photographer.subheading}
            type = {photographer.type}
            
            

          />
        ))}
      {/* </div> */}

      </div>
  
       

      
    </div>
  );
}

export default InPrintsForm;




