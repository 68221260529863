import images from './images';

const pictures = [
  {
    display_img: 'https://storage.googleapis.com/thecockneysikh-storage/portraits/8A950523-FA1D-4EEB-82AD-9567BC8E1398.jpg',
    
    photographer_name: 'Nancy Oliver',
    description: 'A Talented Artist Based In Glasgow/London',
    insta_url:'https://www.instagram.com/byynancyy/',
    website_url:'https://byynancyy.myshopify.com/',
    details_page_link:'/capturesdetails',
    img_urls:[
      'https://storage.googleapis.com/thecockneysikh-storage/portraits/8A950523-FA1D-4EEB-82AD-9567BC8E1398.jpg',
      'https://storage.googleapis.com/thecockneysikh-storage/portraits/1E918AFE-14A5-4D5A-A63D-9C952F40AEF6.jpg',
      'https://storage.googleapis.com/thecockneysikh-storage/portraits/C4D87BFC-2F75-49B8-839C-83BD4F8A2098.jpg',
      'https://storage.googleapis.com/thecockneysikh-storage/portraits/EB906AAA-91A5-4E87-B05A-FF4987D900EB.jpg',
      'https://storage.googleapis.com/thecockneysikh-storage/portraits/Screenshot%202023-06-24%20at%2005.04.30.png'
    ],
    img_titles:["Jagir Kaur's Organic Gardern",'Dishoom (75 Years of Partition)','Planet Organic, Spitalfields','Rough Trade Records East','Royal Institude of British Architects, Portland Place'],

  },
  {
    display_img: 'https://storage.googleapis.com/thecockneysikh-storage/portraits/2/211029000000010003.png',
    photographer_name: 'Henry Robinson',
    description: 'Portrait Photographer/Filmmaker Jamaican based in East Africa',
    insta_url:'https://www.instagram.com/oh_henry/',
    website_url:'',
    details_page_link:'/capturesdetails',
    img_urls:[
      'https://storage.googleapis.com/thecockneysikh-storage/portraits/2/211029000000010003.png',
      'https://storage.googleapis.com/thecockneysikh-storage/portraits/2/211029000000010001.png',
      'https://storage.googleapis.com/thecockneysikh-storage/portraits/2/211029000000010007.png',
      'https://storage.googleapis.com/thecockneysikh-storage/portraits/2/211029000000020001.png',
      'https://storage.googleapis.com/thecockneysikh-storage/portraits/2/211029000000020003.png',
      'https://storage.googleapis.com/thecockneysikh-storage/portraits/2/211029000000020004.png',
      'https://storage.googleapis.com/thecockneysikh-storage/portraits/2/211029000000020008.jpg'
    ],

    img_titles:['','','','','','','','',''],
  },
  {
    display_img: 'https://storage.googleapis.com/thecockneysikh-storage/portraits/3/Screenshot%202023-07-26%20at%2016.02.53.png',
    photographer_name: 'Harinder Sahota',
    description: 'Figurative artist based in West London',
    insta_url:'https://www.instagram.com/harinder_artist/',
    website_url:'info@harinderartist.com',
    details_page_link:'/capturesdetails',
    img_urls:[
      'https://storage.googleapis.com/thecockneysikh-storage/portraits/3/Screenshot%202023-07-26%20at%2016.02.53.png'
    ],
    img_titles:[''],
  },
  {
    display_img: 'https://storage.googleapis.com/thecockneysikh-storage/portraits/4/FAECBE54-5BDD-48DE-B77F-418467EE52DF.JPG',
    photographer_name: 'Portraits of Britain Vol 5',
    description: 'An alternative view of our shared identity, an eclectic celebration of Britishness, Photographed by @jamestyephotography',
    insta_url:'https://www.instagram.com/jamestyephotography/',
    website_url:'https://www.hoxtonminipress.com/products/portrait-of-britain-vol-5?_pos=2&_sid=45f4a65ba&_ss=r',
    details_page_link:'/capturesdetails',
    img_urls: [
      // 'https://drive.google.com/uc?export=view&id=1uREIUgq19wC1SXP5WiheJQBrkhP7GPVR',
      // 'https://drive.google.com/uc?export=view&id=1d6ut7uPPvwfZzus44peFDmJnMwy7xdAF',
      // 'https://drive.google.com/uc?export=view&id=1GgckuXx2poStRefmpbPPHSmQxJUhavpr',
      // 'https://drive.google.com/uc?export=view&id=13mv75UNf-Pqw0vJf4WjQ3wdUtr1yoYlm',
      // 'https://drive.google.com/uc?export=view&id=1MC47Trf5mEQZR9_S84shjVRIwU-qRSKp',
      // 'https://drive.google.com/uc?export=view&id=1GiBZ2NEAamp4lovrVAENqU8T7tgMLVMX',
      'https://storage.googleapis.com/thecockneysikh-storage/portraits/4/ED579F6A-44E3-4F30-8124-4C2D6E710A30.JPG',
      'https://storage.googleapis.com/thecockneysikh-storage/portraits/4/FAECBE54-5BDD-48DE-B77F-418467EE52DF.JPG'
    ],
    img_titles:['',''],
  },


];


const videos = [
  {
    display_img: 'https://storage.googleapis.com/thecockneysikh-storage/films/london-film-school.png',
    video_name: 'London Film School',
    description: "<p>In memory of Annetta Pedretti 1954-2018 THE HOUSE OF ANNETTA-LONDON FILM SCHOOL- Director Nicholas Seaton.</p>",
    // subheading: 'A Talented Artist Based In Glasgow/London',
    website_url:'https://lfs.org.uk',
    video_urls:["https://storage.googleapis.com/thecockneysikh-storage/films/video.mp4"],
    video_names:['Annetta Pedretti 1954-2018 THE HOUSE OF ANNETTA'],
    video_description:['"Footprints of Love" what time is it? Annetta Pedretti trained at the Architectural Association in London, then went on to complete a PhD in Cybernetics. In 1980 she moved to 25 Princelet Street in Spitalfields, where she lived and worked until her death in 2015. Memory of  - Director Nicholas Seaton.'],
    details_page_link:'/filmsdetails',
    insta_url:'https://www.instagram.com/thelondonfilmschool',

  },

  {
    display_img: 'https://storage.googleapis.com/thecockneysikh-storage/films/underground-logo.png',
    video_name: 'Secrets of the London Underground - Yesterday Channel & UKTVPlay',
    description: '',
    // subheading: 'A Talented Artist Based In Glasgow/London',
    website_url:'https://uktvplay.co.uk/shows/secrets-of-the-london-underground/series-3/episode-2/6330153828112',
    video_urls:['https://storage.googleapis.com/thecockneysikh-storage/films/2.SOTLUS3EP2%20INTRO1-1.MOV'],
    video_names:['Season 3, Episode 2 - South Kensington'],
    video_description:['Tim Dunn and Siddy Holloway begin their journey at South Kensington, home of Victoria and Albert Museum, the Science Museum and the Natural History Museum.'],
    details_page_link:'/filmsdetails',
    insta_url:'https://www.instagram.com/mrtimdunn',

  },

  {
    display_img: 'https://storage.googleapis.com/thecockneysikh-storage/films/sdg13.jpg',
    video_name: 'SDG13: Climate Action - Class of 2030',
    description: '',
    // subheading: 'A Talented Artist Based In Glasgow/London',
    website_url:'https://sdgs.un.org',
    video_urls:['https://storage.googleapis.com/thecockneysikh-storage/films/SDG%2013_1.mp4', 'https://storage.googleapis.com/thecockneysikh-storage/films/SDG%2013_2.mp4', 'https://storage.googleapis.com/thecockneysikh-storage/films/SDG%2013_3.mp4'],
    video_names:['Discovery of SDG 13', 'Suresh reflects on how he found his impact', 'Learn with Suresh Singh'],
    video_description:[
      'We invited Suresh to join the Class of 2030 to focus on SDG 13. Lessons took place online, with a single goal in mind: to give Suresh insight into the SDG to help him understand its relevance in achieving the 2030 goals.',
      'As a founding member of the Class of 2030, Suresh was astounded to learn of the damage that humanity has inflicted on our planet. He believes that if we live unselfishly and without ego, we can be deliberate in finding solutions together. For Suresh, lifelong learning is critical, there is something new to learn every day. By empowering people to reflect within themselves, we can make the changes in our daily actions that are required to protect Mother Earth.',
      'Through three online lessons with his tutor, Suresh was exposed to the science as well as the economic, political, technological and social challenges and progress being made in SDG 13. Suresh learned why temperature increases matter, and the importance of education and raising awareness to take urgent action to combat climate change and its impact.'
    ],
    details_page_link:'/filmsdetails',
    insta_url:'https://www.instagram.com/theclassof2030/',
  },

];

const sounds = [
  {
    display_img: 'https://storage.googleapis.com/thecockneysikh-storage/radio/logo-bbc.JPG',
    sound_name: 'BBC',
    description: 'A collection of my audio captures with the BBC',
    website_url:'https://www.bbc.co.uk',
    img_urls:[ 
      "https://storage.googleapis.com/thecockneysikh-storage/radio/27%20Aug.jpeg",
      "https://storage.googleapis.com/thecockneysikh-storage/radio/20%20Aug%20.jpeg",
      "https://storage.googleapis.com/thecockneysikh-storage/radio/bbc2.jpg",
      "https://storage.googleapis.com/thecockneysikh-storage/radio/rev.jpg.png",
      "https://storage.googleapis.com/thecockneysikh-storage/radio/Screenshot%202023-07-28%20at%2015.01.35.png",
      
      "https://storage.googleapis.com/thecockneysikh-storage/radio/bbc1.jpg",
      
      
    ],
    audio_names:['Sunny and Shay','Jim Davis standing in for Sunny and Shay','Shay Kaur Grewal Show','The Kate Bottley Show','Craig Charles Show','Robert Elms Show'],
    audio_subheadings:["Sunday 27 Aug, 2023 - BBC Radio London","Sunday 20 Aug, 2023 - BBC Radio London","Monday 24 July, 2023 - BBC Radio London",'Sunday 07 Aug, 2022 - BBC2 Good Morning Sunday','Monday 25 July, 2022 - BBC Radio Show 6',"Thursday 25 Oct, 2018 - BBC Radio London"],
    audio_descriptions:['','','A BBC interview with topics surrounding the Punjabi British Experience, the book, archives, London and more','Shared my spirituality for Sikhi, and love for mum & dad.', 'A conversation with Craig Charles, on Spizzenergi, Punk, Mum & Dad, Recipes, stories. Featured in Trunk of Punk.',"A BBC Interview on the launch of my book"],
    audio_urls:[
      'https://drive.google.com/file/d/1T4Guy6vP2ahQKeg9Gqz9c-EXVB_GMJzg/preview',
      'https://drive.google.com/file/d/1sPcNkao9Qie-Xk8v3lkQUrQQRlx8y4Dd/preview',
      'https://drive.google.com/file/d/1YBoj3tKEslrKN2oSFfSD0I6FwPJwlMwg/preview',
      'https://drive.google.com/file/d/1VAaOun6_lijnGPtqmyj1Cotq50tc2-uz/preview',
      'https://drive.google.com/file/d/18EiD2YMsn3eHaNy1ZBsHf0kxkT4MgGAK/preview',
      'https://drive.google.com/file/d/1f60s8ap5K1WSITS_XeGDIzfN7kYKHBFr/preview', 
      ],
    details_page_link:'/radiodetails',
    insta_url:'https://www.instagram.com/bbcradiolondon/',
  },

  {
    display_img: images.islington,
    sound_name: 'Islington Radio',
    description: 'A collection of my current radio shows with Islington Radio',
    website_url:'https://islingtonradio.co.uk',
    img_urls:[
      "https://storage.googleapis.com/thecockneysikh-storage/radio/1.png",
      "https://storage.googleapis.com/thecockneysikh-storage/radio/2.png",
      "https://storage.googleapis.com/thecockneysikh-storage/radio/3.png",
      "https://storage.googleapis.com/thecockneysikh-storage/radio/4.png",
      "https://storage.googleapis.com/thecockneysikh-storage/radio/5.png",
      "https://storage.googleapis.com/thecockneysikh-storage/radio/islin3.jpg",
      // images.islin,
      // "https://drive.google.com/uc?export=view&id=1ltusKtDRWCyhjqhCmX4T2uQ3g0oSN_PZ",
      
    ],
    audio_names:[
      "My first radio show at Islington Radio",
      "Episode 2",
      "Episode 3",
      "Episode 4",
      "Episode 5",
      // "Episode 6, Reruns",
      // "Episode 7, Speaks in Respect to Terry Hall, Jet Black, Keith Levene",
      // "Footprints of Love with The Cockney Sikh",
      "Homage to Sinéad O'Connor, Sounds of The Footprints of Love"
    ],  
    audio_subheadings:[
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      ""
    ],
    audio_descriptions:['', "", ''],
    audio_urls:
    [
      
      "https://www.mixcloud.com/widget/iframe/?hide_cover=1&mini=1&feed=%2FIslingtonRadio%2Fsoundz-of-the-suresh-singh-underground-20042022%2F",
      "https://www.mixcloud.com/widget/iframe/?hide_cover=1&mini=1&feed=%2FIslingtonRadio%2Fsoundz-of-the-suresh-singh-underground-08052022%2F",
      "https://www.mixcloud.com/widget/iframe/?hide_cover=1&mini=1&feed=%2FIslingtonRadio%2Fsoundz-of-the-suresh-singh-underground-15052022%2F",
      "https://www.mixcloud.com/widget/iframe/?hide_cover=1&mini=1&feed=%2FIslingtonRadio%2Fsoundz-of-the-suresh-singh-underground-footprints-of-love-16072022%2F",
      "https://www.mixcloud.com/widget/iframe/?hide_cover=1&mini=1&feed=%2FIslingtonRadio%2Fsoundz-of-the-suresh-singh-underground-29082022%2F",
      "https://www.mixcloud.com/widget/iframe/?hide_cover=1&mini=1&feed=%2FIslingtonRadio%2Fhomage-to-sin%C3%A9ad-oconnor-sounds-of-the-footprints-of-love%2F",

    ],
  
    details_page_link:'/radiodetails',
    insta_url:'https://www.instagram.com/islingtonradio/',
  },

  // {
  //   display_img: images.bishopsgate,
  //   sound_name: 'BBC',
  //   description: 'A collection of my audio captures with the BBC ',
  //   website_url:'https://thecockneysikh.com/',
  //   img_urls:[images.bishopsgate,images.bishopsgate,images.bishopsgate],
  //   audio_names:['BBC','BBC','BBC'],
  //   subheading:['Oct 8, 2023 - BBC Radio Show 6 ','Oct 8, 2023 - BBC Radio Show 6 ','Oct 8, 2023 - BBC Radio Show 6 '],
  //   audio_description:['A BBC Interview with topics surrounding the stories, punk and the archives (tbr)','A BBC Interview with topics surrounding the stories, punk and the archives (tbr)','A BBC Interview with topics surrounding the stories, punk and the archives (tbr)'],
  //   audio_urls:['https://thecockneysikh.com/','https://thecockneysikh.com/','https://thecockneysikh.com/'],
  //   details_page_link:'/capturesdetails',

  // },


];


const music =[

  
  {
    display_img:  "https://storage.googleapis.com/thecockneysikh-storage/music/studio.jpg",
    sound_name: 'Crouch End Studios',
    description: 'A collection of recordings at Crouch End Studios. Thank you Terry and Munish.',
    website_url:'https://www.crouchendstudios.co.uk',
    img_urls:[
      "https://storage.googleapis.com/thecockneysikh-storage/music/distrokid_promocard_My_Mum_made_this_tank_top_for_me_in_1973_29.jpg",
      "https://storage.googleapis.com/thecockneysikh-storage/music/IMG_3566.jpeg",
      "https://storage.googleapis.com/thecockneysikh-storage/music/distrokid_promocard_MASIJI28LIVE%2529.jpg",
      "https://storage.googleapis.com/thecockneysikh-storage/music/4.jpg",
      
    ],

    audio_names:[
      "My Mum made this tank top for me in 1973",
      "Grandmother's pans",
      "MASIJI, Pt. 1 - LIVE",
      "MASIJI, Pt. 2 - LIVE",
      
    ],

    audio_subheadings:[
      "29 July, 2023",
      "18 Feb, 2023",
      "28 Oct, 2022",
      "28 Oct, 2022",
      
  ],
    audio_descriptions:[
      "My Mum made this tank top for me in 1973",
      "Playing my Grandmother’s pans of 1948 today",
      "Ode to Mum and Masiji, 1966/2022",
      "My Masiji adored me as I was the first boy to be born in England",
      
    ],
    audio_urls:[
      'https://open.spotify.com/embed/track/5T9MzT9NQlYIcL0vGSp2st?utm_source=generator&theme=0',
      'https://drive.google.com/file/d/1Qjm_TzPB4t1ysKjXHYoKLyHq2vsfees5/preview',
      "https://open.spotify.com/embed/track/4YRMwNqHHv0VGRfwpY73rt?utm_source=generator&theme=0",
      "https://open.spotify.com/embed/track/79rFvDTlehtrSuByV9CVjw?utm_source=generator&theme=0",
      
    ],

    details_page_link:'/musicdetails',
    insta_url:'https://www.instagram.com/crouchendstudios/',
  },

]



export default { pictures, videos, sounds, music };
