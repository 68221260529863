// MyComponent.js
// import './FilmsForm.css'

import React, { useState, useEffect } from 'react';
import { images,captures } from '../../constants';
import { SubHeading, FormDisplayItem, Searchbar, Eventbox } from '../../components';
import {updatePortraitImages,fetchPortraitImagesById} from '../../services/portraits'; // Import the specific API function
import axios from 'axios';

import {useNavigate} from 'react-router-dom';

// const navigate = useNavigate();


function PortraitImagesForm({onClose, id }) {
    const navigate = useNavigate();
    const [picture, setPicture] = useState([]);
    

    const [pictureImages, setPictureImages] = useState([]);

    
  const [image, setImage] = useState(null);
  const [title, setTitle] = useState(picture.title || "");

//   const [type, setType] = useState(picture.type || "");

  
  const [imageTitle, setImageTitle] = useState("")
  


    console.log(id)


   // Handle file input change
   const handleFileChange = (e) => {
    
    setImage(e.target.files[0]);
  };

     // Handle sub images
     const handleImageFileChange = (e) => {
      setImage(e.target.files[0]);
    };


 // Handle form submission
 const updateData = async (e) => {
  e.preventDefault();

  // Create a FormData object to send the form data
  const formData = new FormData();
//   console.log(image)
if (image && image instanceof File) {
  // Generate a unique filename using a timestamp and the original file's extension
  const fileExtension = image.name.split('.').pop();
  const uniqueFilename = `${Date.now()}.${fileExtension}`;

  // Create a new File object with the unique filename
  const renamedFile = new File([image], uniqueFilename, {
    type: image.type,
  });

  // Append the renamed file to the formData
  formData.append('image', renamedFile);
}

  formData.append('title', title);


  console.log(formData);

  try {
    const responseData = await updatePortraitImages(formData,id); // Call the submitFormData function
    console.log('Response:', responseData);
    // Handle success, e.g., display a success message or redirect
    alert('Success!');
  } catch (error) {
    console.error('Error:', error);
    // Handle error, e.g., display an error message
  }
};


   

    


  useEffect(() => {
    async function fetchData() {
      try {
        const data = await fetchPortraitImagesById(id);
        setPicture(data);
        setImage(data.image);
        setTitle(data.title || "");
        

        console.log(data)
      } catch (error) {
        // Handle error
      }


    }

    fetchData();
  }, []);



  return (

    <div className='captures-form'>
      
      <div className='captures-form__add'>

      
      <p class="headtext__cormorant2">Update {picture.name}</p>
      <br/>

      <form class="w-full max-w-sm">
      <div class="md:flex md:items-center mb-6">
        <div class="md:w-1/3">
          <label class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4" for="inline-full-name">
            Upload Image
          </label>
        </div>
          <div class="md:w-2/3">
            <img width="300px" height="300px" src={picture.image}/>
            
            <input
            class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" id="inline-full-name"
            type="file"
            name="image"
            accept="image/*"
            onChange={handleFileChange}
            required/>
             
          </div>
        </div>


        <div class="md:flex md:items-center mb-6">
          <div class="md:w-1/3">
            <label class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4" for="inline-full-name">
            Title
            </label>
          </div>
            <div class="md:w-2/3">
              <input
              class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" id="inline-full-name"
              type="text"
              name="name"
              value={title}
              onChange={(e)=>setTitle(e.target.value)}
              />
            </div>
        </div>






        <div class="md:flex md:items-center">
          <div class="md:w-1/3"></div>
          <div class="md:w-2/3">
            <button class="shadow bg-orange-400 hover:bg-orange-500 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded" 
            type="submit"
            // onClick={() => { onClose(); updateData(); }}
            onClick={updateData}
            >
            Submit
            </button>
          </div>
        </div>
        
      </form>

      </div>

      
    </div>
  );
}

export default PortraitImagesForm;
