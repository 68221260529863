import React, { useState, useEffect } from 'react';
import { FaInstagram, RxInstagramLogo } from 'react-icons/fa';
import { images } from '../../constants';
import './Garden.css';
import { Link } from 'react-router-dom';
import { fetchGardens} from '../../services/garden'

const Garden = ({  }) => {
  
  
  const [radios, setRadios] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        const data = await fetchGardens();
        // setArchives(data);
        console.log(data)
        setRadios(data)
      } catch (error) {
        // Handle error
      }
    }

    fetchData();
  }, []);    
  
  
  
  return (
  // console.log(contentType)
  <div className="app__gallery">

{radios && radios.length > 0 && radios.reverse().map((v, index) => (
            
            <div className='app__gallery-box'>
            <div className='app__gallery-img' key={index}>
            <img src={v.url} height="300px" width="300px" alt="" />

                {/* {v.type === 'image' ? (
                <img src={v.url} height="300px" width="300px" alt="" />
                ) : v.type === 'video' ? (
                <video src={v.url} height="300px" width="300px" controls></video>
                ) : null} */}
            </div>
            <p className='p__cormorant'>{v.heading}</p>
        <p className='p__cormorant-small'>{v.subheading}</p>
    
            </div>
            
            ))} 
    



{/* 
    <div className='app__gallery-img'>
    <img src="https://storage.googleapis.com/thecockneysikh-storage/Garden/6229758b-079f-4f1b-913f-b37dea4ed52f.jpg" height="300px" width="300px" alt=""/>
    </div>

    
    <div className='app__gallery-img'>   
    <img src='https://storage.googleapis.com/thecockneysikh-storage/Garden/e45858e3-b8c8-43b9-8c7c-df11f092aab1.jpg' height="300px" width="300px"  alt=""/>
    </div>
    
    <div className='app__gallery-img'>
    <img src="https://storage.googleapis.com/thecockneysikh-storage/Garden/30d263a0-916a-4fb2-a8aa-bc6cbc0868cb.jpg" height="300px" width="300px" alt=""/>
    </div>

    
    <div className='app__gallery-img'>   
    <img src='https://storage.googleapis.com/thecockneysikh-storage/Garden/344da1a3-4c88-494e-bc89-69ca4cc30f5f.jpg' height="300px" width="300px"  alt=""/>
    </div>

       
    <div className='app__gallery-img'>   
    <img src='https://storage.googleapis.com/thecockneysikh-storage/Garden/58f7e8ca-f3e1-4630-a828-b2098e451d4c.jpg' height="300px" width="300px"  alt=""/>
    </div>
    

    <div className='app__gallery-img'>
    <img src="https://storage.googleapis.com/thecockneysikh-storage/Garden/IMG_0818.jpg" height="300px" width="300px" alt=""/>
    </div>

    
    <div className='app__gallery-img'>   
    <img src='https://storage.googleapis.com/thecockneysikh-storage/Garden/IMG_0846.jpg' height="300px" width="300px"  alt=""/>
    </div>

       
    <div className='app__gallery-img'>   
    <img src='https://storage.googleapis.com/thecockneysikh-storage/Garden/e3138285-95ae-476c-84fd-260b9c864111.jpg' height="300px" width="300px"  alt=""/>
    </div>


    <div className='app__gallery-img'>
    <img src="https://storage.googleapis.com/thecockneysikh-storage/Garden/7DC20736-AAE2-4BCC-93E1-7875CEA482FB.jpg" height="300px" width="300px" alt=""/>
    </div>

    
    <div className='app__gallery-img'>   
    <img src='https://storage.googleapis.com/thecockneysikh-storage/Garden/IMG_5575.jpeg' height="300px" width="300px"  alt=""/>
    </div>

       
    <div className='app__gallery-img'>   
    <img src='https://storage.googleapis.com/thecockneysikh-storage/Garden/IMG_5594.jpeg' height="300px" width="300px"  alt=""/>
    </div>

    <div className='app__gallery-img'>   
    <img src='https://storage.googleapis.com/thecockneysikh-storage/Garden/IMG_5594 2.jpeg' height="300px" width="300px"  alt=""/>
    </div>

    <div className='app__gallery-img'>   
    <img src='https://storage.googleapis.com/thecockneysikh-storage/Garden/IMG_5595.jpeg' height="300px" width="300px"  alt=""/>
    </div>

    <div className='app__gallery-img'>   
    <img src='https://storage.googleapis.com/thecockneysikh-storage/Garden/IMG_3007.jpeg' height="300px" width="300px"  alt=""/>
    </div>


    <div className='app__gallery-img'>   
    <img src='https://storage.googleapis.com/thecockneysikh-storage/Garden/IMG_5575.jpeg' height="300px" width="300px"  alt=""/>
    </div>
    */}
    
   

</div>
    
    

   
)};

export default Garden;