import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { SubHeading, Listbox, Searchbar, Eventbox } from '../../components';
import { FaInstagram, RxInstagramLogo } from 'react-icons/fa';
import { images, captures } from '../../constants';
import './MusicList.css';
import { Link } from 'react-router-dom';
import { fetchMusics} from '../../services/musics';

const MusicList = () => {

  const [radios, setRadios] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        const data = await fetchMusics();
        // setArchives(data);
        console.log(data)
        setRadios(data)
      } catch (error) {
        // Handle error
      }
    }

    fetchData();
  }, []);



  // Use the useLocation() hook inside the component body
  const location = useLocation();
//   const { contentType } = location.state;
  // console.log(contentType);
  
  // Define content based on the linkName
  let content = null;

 {
    
  content = <div className='app__capturelist'>
            
    {radios && radios.length > 0 && radios.reverse().map((v, index) => (
      // console.log(v)
      <Eventbox
        key={v.id}
        id={v.id}
        display_img={v.display_img}
        name={v.name}
        description={v.description}
        website_url={v.website_url}
        // contentType={contentType}
        details_page_link={v.details_page_link}
      />
    ))}
      </div>


  }


  return (
    <div className="app__wrapper section__padding">
      {content}
    </div>
  );
};

export default MusicList;




