import React from 'react';

import { SubHeading } from '../../components';
import { images } from '../../constants';
import './Captures.css';
import { Link } from 'react-router-dom';

 const Captures = () => (
  <div className="app__captures">
 
    <div className="app__captures_info">
      <h1 className="headtext__cormorant2"><Link to="/Captureslist" state={{ contentType: "picture" }}>Portraits by Artists</Link></h1>
      <h1 className="headtext__cormorant2"><Link to="/Captureslist" state={{ contentType: "video" }}>TV and Films</Link></h1>
      <h1 className="headtext__cormorant2"><Link to="/Captureslist" state={{ contentType: "sound" }}>Music recordings at Crouch End Studios, BBC Radio appearances and Islingtion Radio show </Link></h1>
      {/* <h1 className="headtext__cormorant"><Link to="/Captureslist" state={{ contentType: "article" }}>ARTICLES</Link></h1> */}

    </div>

    <div className="capture__wrapper_img ">
      <img src="https://storage.googleapis.com/thecockneysikh-storage/captures/captures_homepage.png" alt="Captures"/>
    </div>

  </div>
);

export default Captures;