// import images from './images';

// const archives = [
//   {
//     archive_name: 'Archive1',
//     display_img_url: images.archives,
//     header_img_url: images.archiveshead,
//     description:'A Talented Artist Based In Glasgow/London',
//     img_urls:[images.nancy,images.nancy,images.nancy],
//   },
// ]


// export default { archives };


import images from './images';

const archives = [

  {
    archive_name: 'Clare',
    display_img_url: "https://storage.googleapis.com/thecockneysikh-storage/archives/clare/display.png",
    header_img_url: "https://storage.googleapis.com/thecockneysikh-storage/archives/clare/header-new2.png",
    description:"Drawing by Clare O'Connell",
    img_urls:[
      "",
      
    ],
    details_page_link: '/archivesdetails',
    isVideo:'1',
    video_url:"https://www.youtube.com/embed/BDlPy6q3fy4"
  },

  {
    archive_name: 'WHITECHAPEL ART GALLERY',
    display_img_url: "https://storage.googleapis.com/thecockneysikh-storage/archives/slides/display_slides.jpeg",
    header_img_url: "https://storage.googleapis.com/thecockneysikh-storage/archives/slides/slides.png",
    description:"1978 O Level General Studies Project; Shown The WHITECHAPEL ART GALLERY:OPEN EXHIBITION 1980 Reference; WHITECHAPEL ART GALLERY ARCHIVES catalogue",
    img_urls:[
      "https://storage.googleapis.com/thecockneysikh-storage/archives/slides/1.png",
      "https://storage.googleapis.com/thecockneysikh-storage/archives/slides/2.png",
      "https://storage.googleapis.com/thecockneysikh-storage/archives/slides/3.png",
      "https://storage.googleapis.com/thecockneysikh-storage/archives/slides/4.png",
      "https://storage.googleapis.com/thecockneysikh-storage/archives/slides/5.png",
      "https://storage.googleapis.com/thecockneysikh-storage/archives/slides/6.png",
      "https://storage.googleapis.com/thecockneysikh-storage/archives/slides/7.png",
      "https://storage.googleapis.com/thecockneysikh-storage/archives/slides/8.png",
      "https://storage.googleapis.com/thecockneysikh-storage/archives/slides/9.png",
      "https://storage.googleapis.com/thecockneysikh-storage/archives/slides/10.png",
      "https://storage.googleapis.com/thecockneysikh-storage/archives/slides/11.png",
      "https://storage.googleapis.com/thecockneysikh-storage/archives/slides/12.png",
    ],
    details_page_link: '/archivesdetails',
  },


  {
    archive_name: 'In The Yard',
    display_img_url: "https://storage.googleapis.com/thecockneysikh-storage/archives/yard/2.png",
    header_img_url: "https://storage.googleapis.com/thecockneysikh-storage/archives/yard/archives-head.jpg",
    description:"A collection of photos from 'In The Yard' in the Archive",
    img_urls:[
      "https://storage.googleapis.com/thecockneysikh-storage/archives/yard/1.png",
      "https://storage.googleapis.com/thecockneysikh-storage/archives/yard/2.png",
      "https://storage.googleapis.com/thecockneysikh-storage/archives/yard/3.png",
      "https://storage.googleapis.com/thecockneysikh-storage/archives/yard/4.png",
      "https://storage.googleapis.com/thecockneysikh-storage/archives/yard/5.png",
      "https://storage.googleapis.com/thecockneysikh-storage/archives/yard/6.png",
      "https://storage.googleapis.com/thecockneysikh-storage/archives/yard/7.png",
      "https://storage.googleapis.com/thecockneysikh-storage/archives/yard/8.png",
      "https://storage.googleapis.com/thecockneysikh-storage/archives/yard/9.png",
    ],
    details_page_link: '/archivesdetails',
  },

  {
    archive_name: 'Passports',
    display_img_url: "https://storage.googleapis.com/thecockneysikh-storage/archives/Passports/passports/passport.png",
    header_img_url: "https://storage.googleapis.com/thecockneysikh-storage/archives/Passports/passports/header.png",
    description:"A collection of photos from 'Passports' in the Archive",
    img_urls:[
      "https://storage.googleapis.com/thecockneysikh-storage/archives/Passports/passports/4.png",
      "https://storage.googleapis.com/thecockneysikh-storage/archives/Passports/passports/6.png",
      "https://storage.googleapis.com/thecockneysikh-storage/archives/Passports/passports/5.png",
      "https://storage.googleapis.com/thecockneysikh-storage/archives/Passports/passports/1.png",
      "https://storage.googleapis.com/thecockneysikh-storage/archives/Passports/passports/3.png",
      "https://storage.googleapis.com/thecockneysikh-storage/archives/Passports/passports/2.png",
      "https://storage.googleapis.com/thecockneysikh-storage/archives/Passports/passports/10.png",
      "https://storage.googleapis.com/thecockneysikh-storage/archives/Passports/passports/12.png",
      "https://storage.googleapis.com/thecockneysikh-storage/archives/Passports/passports/11.png",
      
      "https://storage.googleapis.com/thecockneysikh-storage/archives/Passports/passports/7.png",
      "https://storage.googleapis.com/thecockneysikh-storage/archives/Passports/passports/9.png",
      "https://storage.googleapis.com/thecockneysikh-storage/archives/Passports/passports/8.png",
      
    
      
 
    ],
    details_page_link: '/archivesdetails',
  },

  {
    archive_name: 'Grifts',
    display_img_url: "https://storage.googleapis.com/thecockneysikh-storage/archives/grifts/3.png",
    header_img_url: "https://storage.googleapis.com/thecockneysikh-storage/archives/grifts/header.png",
    description:"",
    img_urls:[
      "https://storage.googleapis.com/thecockneysikh-storage/archives/grifts/1.png",
      "https://storage.googleapis.com/thecockneysikh-storage/archives/grifts/2.png",
      "https://storage.googleapis.com/thecockneysikh-storage/archives/grifts/3.png",
      "https://storage.googleapis.com/thecockneysikh-storage/archives/grifts/4.png",
      "https://storage.googleapis.com/thecockneysikh-storage/archives/grifts/5.png",
      "https://storage.googleapis.com/thecockneysikh-storage/archives/grifts/6.png",
      "https://storage.googleapis.com/thecockneysikh-storage/archives/grifts/7.png",
      "https://storage.googleapis.com/thecockneysikh-storage/archives/grifts/8.png",
      "https://storage.googleapis.com/thecockneysikh-storage/archives/grifts/9.png",
      "https://storage.googleapis.com/thecockneysikh-storage/archives/grifts/10.png",
     
    ],
    details_page_link: '/archivesdetails',
  },

  {
    archive_name: 'Funeral',
    display_img_url: "https://storage.googleapis.com/thecockneysikh-storage/archives/funeral/display.png",
    header_img_url: "https://storage.googleapis.com/thecockneysikh-storage/archives/funeral/header.png",
    description:"London Circa 1966-67",
    img_urls:[
      "https://storage.googleapis.com/thecockneysikh-storage/archives/funeral/1.png",
      "https://storage.googleapis.com/thecockneysikh-storage/archives/funeral/2.png",
      "https://storage.googleapis.com/thecockneysikh-storage/archives/funeral/3.png",
      "https://storage.googleapis.com/thecockneysikh-storage/archives/funeral/4.png",
      "https://storage.googleapis.com/thecockneysikh-storage/archives/funeral/5.png",
      "https://storage.googleapis.com/thecockneysikh-storage/archives/funeral/6.png",
      "https://storage.googleapis.com/thecockneysikh-storage/archives/funeral/7.png",
      "https://storage.googleapis.com/thecockneysikh-storage/archives/funeral/8.png",
      "https://storage.googleapis.com/thecockneysikh-storage/archives/funeral/9.png",
      "https://storage.googleapis.com/thecockneysikh-storage/archives/funeral/10.png",
     
      "https://storage.googleapis.com/thecockneysikh-storage/archives/funeral/11.png",
      "https://storage.googleapis.com/thecockneysikh-storage/archives/funeral/12.png",
      "https://storage.googleapis.com/thecockneysikh-storage/archives/funeral/13.png",
      "https://storage.googleapis.com/thecockneysikh-storage/archives/funeral/14.png",
      "https://storage.googleapis.com/thecockneysikh-storage/archives/funeral/15.png",
     
      "https://storage.googleapis.com/thecockneysikh-storage/archives/funeral/16.png",
      "https://storage.googleapis.com/thecockneysikh-storage/archives/funeral/17.png",
      "https://storage.googleapis.com/thecockneysikh-storage/archives/funeral/18.png",
      "https://storage.googleapis.com/thecockneysikh-storage/archives/funeral/19.png",
      "https://storage.googleapis.com/thecockneysikh-storage/archives/funeral/20.png",
      "https://storage.googleapis.com/thecockneysikh-storage/archives/funeral/21.png",
      "https://storage.googleapis.com/thecockneysikh-storage/archives/funeral/22.png",
      
     
    ],
    details_page_link: '/archivesdetails',
  },



  

]

export default { archives };