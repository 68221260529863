import React, { useState, useEffect } from 'react';

import { SubpageHeader } from '../../components';
import { images } from '../../constants';
import './Map.css';
import { Link } from 'react-router-dom';
import { fetchMap} from '../../services/map'

 const Map = () => {

  const [radios, setRadios] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        const data = await fetchMap();
        // setArchives(data);
        console.log(data)
        setRadios(data)
      } catch (error) {
        // Handle error
      }
    }

    fetchData();
  }, []); 
  
  return(
  <div className="app__map">
    
    
 {/* <div className='app__map-front'> */}
    {/* <div className='app__map-box'>
          <div className="app__map-img">
          <img src={images.map} alt="map"/>
          </div>
    </div>

    <div className='app__map-box'>
          <div className="app__map-img">
          <img src={images.map} alt="map"/>
          </div>
    </div>
    <div className='app__map-box'>
          <div className="app__map-img">
          <img src={images.map} alt="map"/>
          </div>
    </div> */}
      

      {radios && radios.length > 0 && radios.reverse().map((v, index) => (
            
            <div className='app__map-box'>
            <div className='app__map-img' key={index}>
                {v.type === 'image' ? (
                <img src={v.image} alt="map" />
                ) : v.type === 'video' ? (
                <video src={v.image} controls></video>
                ) : null}
            </div>
            
            <p className='p__cormorant'>{v.heading}</p>
            <p className='p__cormorant-small'>{v.subheading}</p>
            
            
            </div>
            
            ))} 
    


      {/* <div className='app__map-text'>
      <p className="p__cormorant ">
      With Special thanks to: 
    Sardar Jagmohan Singh Bhakar; Gudwara Sikh Sangat, 1a Campbell Rd E3 4DS, Ben Adler, Jagir Kaur, Chhokar Family (64 Arbery Rd E3), Nikki J S Kaur, Ricardo Cinalli – Artist and the Late Eric Elstob Esq. and Stefan Dickers, The Bishopsgate Institute.
    </p>
    <br/>
    <p className="p__cormorant ">
    Front Map drawn by:
    Satwinder Sehmi: Award winning calligrapher and author who has work on permanent display at The British Library and collections worldwide. Instagram @thechicsikh_ 
      </p>
      <br/>
      <p className="p__cormorant ">
    Published and Designed in 2023 by Suresh Singh
    Copyright Suresh Singh.
    </p>
    <br/>
      <p className="p__cormorant ">
      Website: www.thecockneysikh.com
      </p>
      </div> */}
      
    {/* </div> */}

    






  </div>
)};

export default Map;