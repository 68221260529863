// import bg from '../assets/bg.png';
// import captures from '../assets/captures.jpg';
// import G from '../assets/G.png';
// import gallery01 from '../assets/gallary-1.jpg';
// import gallery02 from '../assets/gallary-2.jpg';
// import gallery03 from '../assets/gallary-3.jpg';
// import gallery04 from '../assets/gallary-4.jpg';
// import knife from '../assets/brush.png';
import logo from '../assets/logo.png';
// import menu from '../assets/ingredients.jpg';
// import overlaybg from '../assets/overlaybg.png';
// import spoon from '../assets/spoon.svg';
// import welcome from '../assets/header.jpg';
// import findus from '../assets/find-us.jpg';
// import laurels from '../assets/cosmetics-laurels.jpg';
// import award01 from '../assets/award01.png';
// import award02 from '../assets/award02.png';
// import award03 from '../assets/award03.png';
// import award05 from '../assets/award05.png';
// import sign from '../assets/sign.png';
// import quote from '../assets/quote.png';
// import gericht from '../assets/logo.png';
import captures from '../assets/captures/speaks1.jpg'
import nancy from '../assets/captures/nancy/2.jpg'
import attire from '../assets/attire/attire.jpg'
import attire_banner from '../assets/attire/banner.jpg'
import book from '../assets/book/book.jpg'
import book2 from '../assets/book/book2.jpg'
import map from '../assets/map/map.jpg'
import event from '../assets/happenings/event.jpg'
import archives from '../assets/archives/archives.jpg'
import archiveshead from '../assets/archives/archives-head.jpg'
import archivesbanner from '../assets/archives/banner.jpg'
import bishopsgate from '../assets/archives/bishopsgate.png'
import speakshead from '../assets/speaks/speakshead.jpg'
// import bishopsgate from '../assets/captures/video/bishopsgate.jpg'
import whitelogo from '../assets/logo-white.png'
import mapback from '../assets/map/map-back.jpg'
import captures2 from '../assets/captures3.jpg'
import header from '../assets/homepage.png'
import navsearchicon from '../assets/nav-search-icon.png'
import navback from '../assets/nav-back.png'
import event2 from '../assets/happenings/event2.png'
import islington from '../assets/captures/sounds/islington-radio.jpg'
import instagramlogo from '../assets/instagram-color-icon.svg'
import islin from '../assets/subpages/islin3.jpg'
import bbc1 from '../assets/subpages/bbc1.jpg'
import bbc2 from '../assets/subpages/bbc2.jpg'
import event4 from '../assets/happenings/event4.jpg.png'
// import galleryvideo from '../assets/Gallery/v1.mp4'



export default {
  // bg,
  // captures,
  // G,
  // gallery01,
  // gallery02,
  // gallery03,
  // gallery04,
  // knife,
  logo,
  // menu,
  // overlaybg,
  // spoon,
  // welcome,
  // findus,
  // laurels,
  // award01,
  // award02,
  // award03,
  // award05,
  // sign,
  // quote,
  // gericht,
  captures,
  nancy,
  attire,
  book,
  book2,
  map,
  event,
  archives,
  archiveshead,
  speakshead, 
  bishopsgate,
  whitelogo,
  mapback,
  captures2,
  header,
  navsearchicon,
  navback,
  archivesbanner,
  event2,
  islington,
  instagramlogo,
  islin,
  bbc1,
  bbc2,
  event4,
  attire_banner,
  // galleryvideo
  
};

