import React, { useState, useEffect } from 'react';
import { FaInstagram, RxInstagramLogo } from 'react-icons/fa';
import { images } from '../../constants';
import './DadsRecords.css';
import { Link } from 'react-router-dom';
import { fetchDadsRecords} from '../../services/dadsrecords';

const DadsRecords = ({  }) =>{ 
    
    const [radios, setRadios] = useState([]);

    useEffect(() => {
      async function fetchData() {
        try {
          const data = await fetchDadsRecords();
          // setArchives(data);
          console.log(data)
          setRadios(data)
        } catch (error) {
          // Handle error
        }
      }
  
      fetchData();
    }, []);    
    
    

    return (
  // console.log(contentType)
  <div className="app__gallery">

{radios && radios.length > 0 && radios.reverse().map((v, index) => (
            
            <div className='app__gallery-box'>
            <div className='app__gallery-img' key={index}>
                {v.type === 'image' ? (
                <img src={v.url} height="300px" width="300px" alt="" />
                ) : v.type === 'video' ? (
                <video src={v.url} height="300px" width="300px" controls></video>
                ) : null}
            </div>
            <p className='p__cormorant'>{v.heading}</p>
            <p className='p__cormorant-small'>{v.subheading}</p>
    
            </div>
            
            ))} 

  </div>
    
    



   
)};

export default DadsRecords;