import axios from 'axios';

const API_BASE_URL = 'https://api.thecockneysikh.com/films';


// GET all portrait photographers
export const fetchFilms = async () => {
  try {
    const response = await axios.get('https://api.thecockneysikh.com/films/');
    return response.data;
  } catch (error) {
    throw error;
  }
};


// GET one portrait photographer by id
export const deleteFilmById = async (id) => {
  try {
      const response = await axios.delete(`https://api.thecockneysikh.com/films/${id}/`);
      return response.data;
  } catch (error) {
      throw error;
  }
};


// POST create new radio
export const saveFilm  = async (formData) => {
  try {
    const response = await axios.post(
      'https://api.thecockneysikh.com/films/',
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

  // PUT update existing radio
  export const updateFilm  = async (formData,id ) => {
    console.log(id)
      try {
        const response = await axios.put(
          `https://api.thecockneysikh.com/films/${id}/`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    };




// GET one portrait photographer by id
export const fetchFilmById = async (id) => {
  try {
      const response = await axios.get(`https://api.thecockneysikh.com/films/${id}/`);
      return response.data;
  } catch (error) {
      throw error;
  }
};




// GET all pictures of portrait photographers
export const fetchFilmVideos = async (id) => {
  try {
      const response = await axios.get(`https://api.thecockneysikh.com/films/${id}/videos`);
      return response.data;
  } catch (error) {
      throw error;
  }
};


/// add videos




// GET one portrait photographer by id
export const fetchFilmVideosById = async (id) => {
  try {
      const response = await axios.get(`https://api.thecockneysikh.com/films/videos/${id}`);
      return response.data;
  } catch (error) {
      throw error;
  }
};


export const deleteFilmVideosById = async (id) => {
  try {
      const response = await axios.delete(`https://api.thecockneysikh.com/films/videos/${id}`);
      return response.data;
  } catch (error) {
      throw error;
  }
};


// PUT update existing radio
export const updateFilmVideos  = async (formData,id ) => {
  console.log(id)
    try {
      const response = await axios.put(
        `https://api.thecockneysikh.com/films/videos/${id}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };


// POST create new radio
export const saveFilmVideos  = async (formData,id) => {
  try {
    const response = await axios.post(
      `https://api.thecockneysikh.com/films/${id}/videos`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
