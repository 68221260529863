import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { SubpageHeader, Listbox, Videobox, Searchbar } from '../../components';
import { images,captures } from '../../constants';
import './PortraitsDetails.css';
import { Link } from 'react-router-dom';
import { fetchPortraitById,fetchPortraitImages} from '../../services/portraits';



const PortraitsDetails = () => {
  
  const location = useLocation();
  const {  id } = location.state;

  const [radios, setRadios] = useState([]);
  const [videos, setVideos] = useState([]);




  useEffect(() => {

    async function fetchData() {
      try {
        const data = await fetchPortraitById(id);
        // setArchives(data);
        console.log(data)
        setRadios(data)
      } catch (error) {
        // Handle error
      }
    }

    async function fetchVideoData() {
      try {
        const data = await fetchPortraitImages(id);
        setVideos(data);
        console.log(data)
      } catch (error) {
        // Handle error
      }


    }
    

    fetchData();
    fetchVideoData();
  }, []);



  
  // Define content based on the linkName
  let content = null;

   {
    
    

      content = <div>
         <SubpageHeader
        key={radios.id}
        name={radios.name}
        description={radios.description}
        insta_url={radios.instagram_url}
        website_url={radios.website_url}
        
       
      />
        <div className='app__capturesdetails'>
      {videos && videos.length > 0 && videos.map((img, index) => (
            <div className="app__capturesdetails-img ">
            <img src={img.image} alt="image" />
            <p className='p__cormorant'>{img.title} </p>
            </div>
            ))}
      
      </div>
    </div>



  }


  return (
    <div className="">
      {content}
    </div>
  );
};



export default PortraitsDetails;