import React, {useState} from 'react';
import { FiSearch } from 'react-icons/fi';
import './Searchbar.css';
import { json } from 'react-router-dom';
import { createSearchDataList } from '../../utils/utils'
import { captures, happenings,archives,speaks } from '../../constants';




const Searchbar = ({ setResults }) => {

  // extract names from captures
  const searchData = createSearchDataList();




  const [input, setInput] = useState("")

  // const fetchData = (value) => {
  //   fetch("https://jsonplaceholder.typicode.com/users").then((response) => response.json()).then(json =>{
  //     const results = json.filter((user) =>{
  //       return value && user && user.name && user.name.toLowerCase().includes(value)
  //     });
  //     setResults(results);

  // });
    
  // }

  const fetchData = (value) => {
    const filteredResults = searchData.filter((e) => {
      return (
        e.name &&
        e.name.toLowerCase().includes(value.toLowerCase())
      );
    });
    
    setResults(filteredResults);
  };

  const handleChange = (value) => {
    setInput(value)
    fetchData(value)
  }

  return(
    <div className="app__searchbar">
      {/* <FiSearch className="search-icon" /> */}
      <input
        type="text"
        placeholder="Search..."
        value = {input}
        onChange={(e)=>handleChange(e.target.value)}
      />
      
    </div>
)
    
  
  };

export default Searchbar;